import React, { useEffect, useState ,useRef } from "react";
import "./ShopProduct.scss";
import BasketBtn from "../../components/BasketBtn";
import Loading from "../../components/Loading/Loading";
import i18next from "i18next";
import axios from "axios";
import { useParams, useHistory, Link } from "react-router-dom";
import QtyBtn from "../../components/QtyBtn";
import { useRecoilState } from "recoil";
import { basketState ,visitedstate ,qtyState } from "../../atom";
// import ProductSlider from "../../components/ProductSlider";
// import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
// import Rive from "@rive-app/react-canvas";
// import rive2 from "../../assets/images/2.riv";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";


import CardShop from "../../components/CardShop/CardShop";


const idUser = localStorage.id;

const ShopProduct = () => {
  let idimage = location?.pathname.split("/")[2]

  const [item, setItem] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [selectedImage, setSelectedImage] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [basket, setBasket] = useRecoilState(basketState);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const mainImageRef = useRef(null);
  const [images , setimages]=useState([])
  const { id } = useParams();
  const history = useHistory("");
  const [qty, setQty] = useRecoilState(qtyState(id)); // Unique qty state for each item


  useEffect(() => {
    setLoading(true);
    axios.get(`api/StoreProduct/GetAllProducts`).then((res) => {
      setItems(res.data);
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    axios.get(`https://ahmeddawood88-003-site1.etempurl.com/api/StoreProductImage/${idimage}`).then((res) => {
      setimages(res.data);
      setMainImage(res.data[0].image)
      setSelectedImage(res.data[0].image)
      setimages(res.data)
      
    });
  }, [idimage]);
  

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/StoreProduct/${id}`).then((res) => {
      setItem(res.data);
      setLoading(false);
      
    });
  }, [id]);

  const handleBasket = (_qty) => {
    let _item = {
      id: Number(id),
      name: i18next?.language === "ar" ? item?.name : item?.nameEN,
      price: item?.unitPriceIQD,
      endprice: item?.unitPriceIQD,
      image: item?.image1,
    };
    let basketItem = basket.find((el) => el.id === Number(id));
    let basketItemIndex = basket.findIndex((el) => el.id === Number(id));

    let newBasket = [...basket];
    if (basketItem) {
      if (_qty === 0) {
        newBasket = basket.filter((el) => el.id !== Number(id));

      } else {
        newBasket[basketItemIndex] = { ..._item, qty: _qty };
        
      }
    } else {
      _item = { ..._item, qty: _qty };
      newBasket.push(_item);
    }

    setQty(_qty);
    setBasket(newBasket);
    localStorage.setItem("iot_basket", JSON.stringify(newBasket));
   
  };

  useEffect(() => {
    if (basket.length > 0) {
      let basketItem = basket.find((el) => el.id === Number(id));
      basketItem && setQty(basketItem.qty);
    }
  }, [basket]);
  const swapImage = (imageSrc) => {
    setMainImage(imageSrc);
    setSelectedImage(imageSrc)
    setMagnifierPosition({ x: 0, y: 0 });
  };
  const handleMouseMove = (event) => {
    const { left, top, width, height } = mainImageRef.current.getBoundingClientRect();
    const mouseX = event.clientX - left;
    const mouseY = event.clientY - top;
    const magnifierSize = 700; // Adjust the size of the magnifier here
  
    const magnifierX = mouseX - magnifierSize / 2;
    const magnifierY = mouseY - magnifierSize / 2;
  
    // Make sure the magnifier stays within the main image bounds
    const maxMagnifierX = width - magnifierSize;
    const maxMagnifierY = height - magnifierSize;
    const clampedMagnifierX = Math.max(0, Math.min(maxMagnifierX, magnifierX));
    const clampedMagnifierY = Math.max(0, Math.min(maxMagnifierY, magnifierY));
  
    setMagnifierPosition({ x: clampedMagnifierX, y: clampedMagnifierY });
  };
  
  
  return (
    <div className="bg" >
      <Link to={"/store"}>
        <div className="back-btn-1">
          <button className="btn-back">{i18next.t("back-to-store")}</button>
        </div>
      </Link>
       <div className="img-width">
        <img src={item.image2}></img>
      </div> 

      <div className="container mt-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6 col-lg-6 img-product">
          <div className=" ">
      <img
        src={mainImage}
        className={`main-image`}
        alt="Main Image "
        onClick={() => swapImage(mainImage)}
        onMouseMove={handleMouseMove}
        ref={mainImageRef}
      />
     
  {/*  <div
    className="magnifier"
    style={{
      backgroundImage: `url(${mainImage})`,
      backgroundPosition: `-${magnifierPosition.x}px -${magnifierPosition.y}px`,
    }}
  />  */}


      <div className="small-images  row px-3 justify-content-center ">
      {images.map((imageUrl, index) => (
              <div className="col-4" key={index}>
                <img
                  src={imageUrl.image}
                  className={`small-image   ${selectedImage === imageUrl.image ? "selected" : ""}`}
                  alt={`Small Image ${index + 2}`}
                  onClick={() => swapImage(imageUrl.image)}
                />
              </div>
            ))}
       
       
      </div>
    </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 text-name-desc">
            <h1
              className={`text-center  ${
                i18next.language === "ar" ? "text-md-end" : "text-md-start"
              }`}
            >
              {" "}
              {i18next.language === "ar" ? item?.name : item?.nameEN}
            </h1>
            <p className=" pt-3">
              {i18next.language === "ar"
                ? item?.description
                : item?.descriptionEN}
                {item.unitPriceIQD==null?"":
                <p className={` fw-bold fs-3 ${i18next.language=="ar"?"text-end":"text-start"}`}>{Number(item.unitPriceIQD).toLocaleString()} {i18next.t("card-currency")}</p>
                }
                <hr />
            </p>
            <div className="">
              <div className=" d-flex justify-content-start  gap-2">
            <a
                  className=  { qty==0
                  ? "card-price-btn-1 "
                  : "card-price-btn-1 bg-white"}
                  onClick={ (e) => {  
                    e.stopPropagation();
                   qty==0? handleBasket(qty+1):handleBasket(0)
                  }}
                >
                  <p className="card-price-1  ">
                   {qty == 0 ?i18next.t("btn-add-basket"):i18next.t("btn-remove-basket")}
                  </p>
                </a>
                <div className="qty-btn">
                <QtyBtn qty={qty} color={'black'} onChange={(val) => handleBasket(val)} />
    </div>
                </div>
                
            </div>
          </div>
          {item.videoURL==""?"":
          <div className="youtub-comp mt-5 pt-5 ">
          <iframe
            src={`https://www.youtube.com/embed/${item?.videoURL}`}
            width="500"
            height="500"
          ></iframe>
        </div>
}
        {item.courses?.length==0?"":
        <div>
        <h2 className="my-5 pb-2 text-center fs-3">{i18next.t("courses-offers-product")}</h2>
          <div className="col-12">
            
            <div className="row  gap-4 justify-content-center">
          
                {item?.courses?.map((el) => (
                  <div className="col-12 col-md-6 col-lg-3">
                    <Card
                      user={idUser}
                      name={el?.title}
                      iqCourse='true'
                      image={el?.ref03}
                      description={el?.description}
                      //  onClick={this.update(el?.courses?.id)}
                      path={{
                        // pathname: /course/${el?.courses?.id},
                        pathname: `/course/${el?.id}`,
                        state: {
                          id: el?.id,
                        },
                      }}
                      pathId={el?.id}
                    />
                  </div>
                ))
                }
                
              
              </div>
              </div>
              
              </div>
}
              
       
      </div>
      
      <BasketBtn />
                
      <div className="footer-mobile-desktop">
        <div className="nav-chat">
          <a
            href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"
            className="btn-whatsapp-19"
          >
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
      </div>
    </div>
    
          
        </div>
       
      
     
  );
};

export default ShopProduct;
