import service from "../../../services";

export const apiPostLogin = (data, error) => {
    return new Promise((resolve) => {
        service.postData(`/api/users/AuthenticateUser`, data, error).then((data) => {
            resolve(data);
        }).catch((error) => {
            resolve(error);
        });
    });
};
