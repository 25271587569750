import React from "react";
import Button from "../../components/Button/Button";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

const PreviousYearQuestion = () => {
  return (
    <div className="bg bg-login">
      <h1 className="text-center mt-5 ">
        الاسئلة الوزارية للسنوات السابقة الخاصة باختبار الذكاء
      </h1>
      <h2 className="text-center mt-5  fs-5">
        اليك مجموعة من الاسئلة الوزارية الخاصة باختبارات الذكاء للسنوات السابقة
      </h2>
      <div className=" d-flex justify-content-center container  mt-5">
        <div className=" row justify-content-center">
          <div className="col-12 col-md-6 col-lg-6 ">
            <a
              href="https://iotkidsiq.com/images/iq%20test%20course/previous%20years%20tests/previous%20iq%20tests%202018.pdf"
              target="_blank"
              download
            >
              <Button name="اسئلة سنة 2018" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-0 ">
            <a
              href="https://iotkidsiq.com/images/iq%20test%20course/previous%20years%20tests/previous%20iq%20tests%202019.pdf"
              target="_blank"
              download
            >
              <Button name="اسئلة سنة 2019" />
            </a>
          </div>
          <div className="col-12 mt-3 col-md-6 col-lg-6 ">
            <a
              href="https://iotkidsiq.com/images/iq%20test%20course/previous%20years%20tests/previous%20iq%20tests%202020.pdf"
              target="_blank"
              download
            >
              <Button name="اسئلة سنة 2020" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <a
              href="https://iotkidsiq.com/images/iq%20test%20course/previous%20years%20tests/previous%20iq%20tests%202021.pdf"
              target="_blank"
              download
            >
              <Button name="اسئلة سنة 2021" />
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <a
              href="https://iotkidsiq.com/images/iq%20test%20course/previous%20years%20tests/previous%20iq%20tests%202022.pdf"
              target="_blank"
              download
            >
              <Button name="اسئلة سنة 2022" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
  );
};

export default PreviousYearQuestion;
