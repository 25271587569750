import React from 'react';
import {Link} from 'react-router-dom';
import i18next from 'i18next';
import './CardOffers.scss';

import ai from '../../assets/images/NewMassarsPics/ai.svg';
import coding from '../../assets/images/NewMassarsPics/coding.svg';
import electronic from '../../assets/images/NewMassarsPics/electronicAndDesign.svg';
import gameDevelopment from '../../assets/images/NewMassarsPics/gameDevelopment.svg';
import graphicDesign from '../../assets/images/NewMassarsPics/GraphicDesign.svg';
import iqTests from '../../assets/images/NewMassarsPics/iqTests.svg';
import schoolsContest from '../../assets/images/NewMassarsPics/schoolsContest.svg';
import webPath from '../../assets/images/NewMassarsPics/webPath.svg';
import miProgram from '../../assets/images/NewMassarsPics/MassarMay2.svg'; //


const getImageByRoadmapId = (id) => {
    switch (id) {
        case 1:
            return webPath;
        case 2:
            return electronic;
        case 8:
            return gameDevelopment;
        case 3:
            return iqTests;
        case 12:
            return graphicDesign;
        case 11:
            return coding;
        case 7:
            return schoolsContest;
        case 14:
            return miProgram;
        default:
            return ai;
    }
};

const CardOffers = ({data, name, NOofcourses, price, delprice, sub, RoadmapID, idpay}) => {
    const lang = localStorage.getItem('lang');
    const id = localStorage.getItem('id');

    const cardImage = getImageByRoadmapId(RoadmapID);

    return (
        <div className="card-offers">
            <div className="card-image">
                <img src={cardImage} alt={name}/>
                {sub && <div className="subscribed-badge">تم الاشتراك</div>}
            </div>
            <div className="card-content">
                <h3>{name}</h3>
                <div className="info-row">
                    <p className="course-count">{NOofcourses} {i18next.t('offers-no-title')}</p>
                    {!sub && (
                        <div className="price-offer">
                            <ins>{price} {i18next.t('card-currency')}</ins>
                        </div>
                    )}
                </div>
                <div className="card-footer">
                    {sub ? (
                        <Link to={`/packagedetails/${RoadmapID}`} className="card-button">
                            {i18next.t('go-to-roadmap')}
                        </Link>
                    ) : id ? (
                        <Link to={`/pay/${idpay}`} className="card-button">
                            {i18next.t('home-s1-btn')}
                        </Link>
                    ) : (
                        <Link to={`/packagedetails/${RoadmapID}`} className="card-button">
                            {i18next.t('go-to-roadmap')}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardOffers;