import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import img_password from "../../../assets/images/login/password.png";
import img_fail from "../../../assets/images/login/info.png";
import img_404 from "../../../assets/images/charecter/boycry.png";
import img_done from "../../../assets/images/login/success.png";
import img_hello from "../../../assets/images/hello.png";
import eye_1 from "/src/assets/images/login/eye.png";
import eye_2 from "/src/assets/images/login/eye-2.png";
import "./ForgetPasswordNew.scss";
import axios from "axios";
import i18next from "i18next";

const ForgetPasswordNew = () => {
  const [newPassword, setNewPassword] = useState(null);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [typePw, setTypePwd] = useState("password");
  const lang = localStorage.getItem("Lang");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idParam = urlParams.get("id");

  useEffect(() => {
    console.log('queryString', queryString);
    console.log('urlParams',urlParams)
    console.log(idParam);
  }, [])

  function changePW() {
     axios
       .patch(
         `/api/users/ResetPassword/${idParam}/${newPassword}`
       )
       .then((res) => {
         setDone(true);
       }).catch((err) => {
         setError(true)
       })

  }
    // eye password
    const eyePassword = () => {
      setTypePwd(typePw === "password" ? "text" : "password");
    }
  return (
    <div className="container">
      <div className="newpassword">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-4">
            {done ? (
              <div className="newpassword-card">
                <div className="newpassword-card-header">
                  <img className="newpassword-h-img" src={img_done} alt="newpassword" />
                  <h3>{i18next.t("newpassword-h-sucsess")}</h3>
                </div>
                <div className="newpassword-card-body">
                  <img className="img_sucsess" src={img_hello} alt="error" />
                  <h3 className="text-sucsess">{i18next.t("newpassword-b-sucsess")}</h3>
                </div>
              </div>) : error ? (
                <div className="newpassword-card">
                  <div className="newpassword-card-header">
                    <img className="newpassword-h-img" src={img_fail} alt="newpassword" />
                    <h3>{i18next.t("newpassword-h-error")}</h3>
                  </div>
                  <div className="newpassword-card-body">
                    <img className="img_sucsess" src={img_404} alt="error" />
                    <h3 className="text-sucsess">{i18next.t("newpassword-b-error")}</h3>
                    <a className="pw-btn-error" href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" >
                      <Button name={i18next.t("password-b-btn")} />
                    </a>
                  </div>
                </div>) : (
              <div className="newpassword-card">
                <div className="newpassword-card-header">
                  <img src={img_password} alt="newpassword" />
                  <h3>{i18next.t("password-h")}</h3>
                </div>
                <div className="newpassword-card-body">
                  <input
                    onChange={(e) => setNewPassword(e.target.value)}
                    type={typePw}
                    placeholder={i18next.t("newpassword-input")}
                  />
                  <a className="newpassword-eye__password" onClick={()=> eyePassword()}>
                    <img src={`${typePw == "password" ? eye_1 : eye_2}`} alt="eye" />
                  </a>
                  <Button onClick={() => changePW()} name={i18next.t("newpassword-btn")} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgetPasswordNew;
