import React, { useEffect, useState } from "react";
import "./QuestionModal.scss";
import Button from "../Button/Button";

const QuestionModal = () => {
  return (
    <div
      className="modal overview-iq-course-modal fade"
      id="overview-iq-courseModal"
      aria-labelledby="overview-iq-courseModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body  ">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="header-model text-center fs-4 fw-demi-bold">
              الاسئلة الوزارية للسنوات السابقة لاختبار الذكاء
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="body-model ">
              <div className="row justify-content-center ">
              <div className="col-12 col-md-6 col-lg-6 mt-3">
                  <abbr className="cros" data-title="يجب عليك الاشتراك في دورات اختبارات الذكاء اولا">
                    <Button name="اسئلة سنة 2018" />
                  </abbr>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-3 ">
                  <abbr className="cros" data-title="يجب عليك الاشتراك في دورات اختبارات الذكاء اولا">
                    <Button name="اسئلة سنة 2019" />
                  </abbr>
                </div>
                <div className="col-12 col-md-6 mt-3 col-lg-6">
                  <abbr className="cros" data-title="يجب عليك الاشتراك في دورات اختبارات الذكاء اولا">
                    <Button name="اسئلة سنة 2020" />
                  </abbr>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-3">
                  <abbr className="cros" data-title="يجب عليك الاشتراك في دورات اختبارات الذكاء اولا">
                    <Button name="اسئلة سنة 2021" />
                  </abbr>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mt-3">
                  <abbr className="cros" data-title="يجب عليك الاشتراك في دورات اختبارات الذكاء اولا">
                    <Button name="اسئلة سنة 2022" />
                  </abbr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionModal;
