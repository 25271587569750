import { atom, atomFamily, selector } from "recoil";

export const copyCopounState = atom({
  key: "copyCopounState",
  default: true,
});

export const basketState = atom({
  key: "basketState",
  default: [],
});

export const shopTotalPriceState = atom({
  key: "shopTotalPriceState",
  default: 0,
});

export const shopTotalDiscountState = atom({
  key: "shopTotalDiscountState",
  default: 0,
});

export const qtyState = atomFamily({
  key: 'qtyState',
  default: 0,
});

export const projectState = atom({
  key: "projectState",
  default: null,
});

export const coursesIdSwithState = atom({
  key: "coursesIdSwithState",
  default: [],
});

export const selectedVideoState = atom({
  key: "selectedVideoState",
  default: null,
});

export const selectedImageState = atom({
  key: "selectedImageState",
  default: null,
});

export const closeVideoState = atom({
  key: "closeVideoState",
  default: true,
});

export const userState = atom({
  key: "userState",
  default: null,
});

export const isAuthenticatedState = selector({
  key: "isAuthenticatedState",
  get: ({ get }) => {
    const user = get(userState);
    return user !== null;
  },
});

export const userDataState = atom({
  key: "userDataState",
  default: null,
});

export const itemAllCourseState = atom({
  key: "itemAllCourseState",
  default: [],
});

export const visitedstate = atom({
  key: "visitedstate",
  default: true
});

export const LoadingState = atom({
  key: "LoadingState",
  default: true
});

export const notificationState = atom({
  key: "notificationState",
  default: true
});

export const cvImageState = atom({
  key: "cvImageState",
  default: null
});

export const MessaratDetails = atom({
  key: "MessartDetails",
  default: []
});

export const MessaratCourses = atom({
  key: "MessaratCourses",
  default: []
});

export const RoadmapDetails = atom({
  key: "RoadmapDetails",
  default: []
});

export const SlpCourses = atom({
  key: "SlpCourses",
  default: []
});

export const ProfilSummary = atom({
  key: "ProfilSummary",
  default: []
});

export const ProjectSItems = atom({
  key: "ProjectSItems",
  default: []
});

export const UserProject = atom({
  key: "UserProject",
  default: []
});

export const IdLessonState = atom({
  key: "IdLessonState",
  default: []
});