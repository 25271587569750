// import { axios } from "../lib";
import axios from '../lib/axiosConfig';

const getData = async (path) => {
  try {
    const response = await axios.get(path);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getResponse = async (path) => {
  try {
    const response = await axios.get(path);
    return response;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

const postData = async (path, body) => {
  try {
    const response = await axios.post(path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const patchData = async (path, body) => {
  try {
    const response = await axios.patch(path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const patchDataWithQuery = async (path) => {
  try {
    const response = await axios.patch(path);
    
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getFiles = async (path) => {
  try {
    const response = await axios.get(path, {
      headers: {
        CacheControl: "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getFile = async (path) => {
  try {
    const response = await axios.get(path, {
      headers: {
        CacheControl: "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
      responseType: "blob",
    });
    if (response.status != 200) {
      throw new Error(response);
    }
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const formDataService = async (path, body, method) => {
  try {
    const response = await axios[method.toLowerCase()](path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const patchOrPost = async (path, body, method) => {
  try {
    const response = await axios[method.toLowerCase()](path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const destroyData = async (path) => {
  try {
    const response = await axios.delete(path);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const service = {
  getData,
  getResponse,
  getFiles,
  getFile,
  postData,
  patchData,
  patchDataWithQuery,
  formDataService,
  patchOrPost,
  destroyData,
};

export default service;