import React from 'react';
import './WhyPoint.scss';

//import ReactDOM from 'react-dom';
const WhyPoint = (props) => {
  return (
    <div className="point">
      <div className="point-num">
        <span>{props.num}</span>
      </div>
      <p>{props.text}</p>
    </div>
  );
}
export default WhyPoint;