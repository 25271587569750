import React, { Component, useEffect, useState } from "react";
import "./FinalTest.scss";
import axios from "axios";
import { BrowserRouter as BrowserRouter, Link } from "react-router-dom";
import img_fail from "../../assets/images/charecter/gairlcry.png";
import img_succeeded from "../../assets/images/charecter/boywin.png";
import i18next from "i18next";
// import Rive from "@rive-app/react-canvas";
import { apiGetCourseLessonsById } from "../../services/api/courseDetails";
import GiftModel from "../GiftModel/GiftModel";
import { IdLessonState } from "../../atom";
import { useRecoilState } from "recoil";

const FinalTest = (props) => {
  
  const [data, setData] = useState([]);
  const [active, setActive] = useState([0]);
  const [activenav, setActivenav] = useState([{ id: 0, question: "", title: "" }]);
  const [index, setIndex] = useState(1);
  const [indexid, setIndexid] = useState(1);
  const [activequestion, setActivequestion] = useState({});
  const [choices, setChoices] = useState([]);
  const [sumOfCorrectAnswers, setSumOfCorrectAnswers] = useState(0);
  const [answersList, setAnswersList] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [dataTest, setDataTest] = useState([]);
  const [idLesson, setIdLesson] = useRecoilState(IdLessonState);

  const GetTestQuest = async (id)=>{
    const data =await apiGetCourseLessonsById(id)
    console.log(",",data)
    const QuestionsOfTest =data.find((ques)=>ques.finalTestQuestion!=null)
    setData(QuestionsOfTest.finalTestQuestion)
    setActivenav(QuestionsOfTest.finalTestQuestion)
    setActivequestion(QuestionsOfTest.finalTestQuestion[0])
    setChoices(QuestionsOfTest.finalTestQuestion[0].questionChoices)

   
  }

  const pathname = window.location.pathname;
  var idCourse = pathname.match(/\d+$/)[0];
  useEffect(() => {
    console.log("ffddffdd", choices);
  }, [choices]);
  useEffect(() => {
    
    GetTestQuest(idCourse)
  }, []);
  

  const checkComplete = () => {
    if (answersList.filter((x) => x !== undefined).length == activenav.length) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }

  // button get question in state and input in page on click
  const clicknav = (postnav) => {
    setActivequestion(postnav);
    setIndex(postnav.index);
    setChoices(data[postnav.index - 1].questionChoices);
    checkComplete();
  }

  // button get choices on click
  const clickchoices = (choice) => {
    let temp = [];
    if (index == 1) {
      temp = answersList;
      if (answersList.length == 0) {
        // Convert boolean to 1 and 0
        temp.push(+choice.correct);
        setAnswersList(temp);
      } else {
        // update the list index
        temp[index - 1] = +choice.correct;
        setAnswersList(temp);
      }
    } else {
      temp = answersList;
      temp[index - 1] = +choice.correct;
      setAnswersList(temp);
    }
    checkComplete();
    
  }

  // button save choices on click
  const saveChoice = (choice) => {
    
    let temp = [];
    if (index === 1) {
      temp = active;
      if (active.length === 0) {
        // Convert boolean to 1 and 0
        temp.push(choice);
        setActive(temp);
      } else {
        // update the list index
        temp[index - 1] = choice;
        setActive(temp);
      }
    } else {
      temp = active;
      temp[index - 1] = choice;
      setActive(temp);
    }
    if (isComplete === true && index === activenav.length) {
      setTimeout(() => {
        setChoices(data[index - 2].questionChoices);
      }, 0);
      setTimeout(() => {
        setChoices(data[index -1].questionChoices);
      }, 1);
    } else {
      setTimeout(() => {
        setChoices(data[index].questionChoices);
      }, 0);
      setTimeout(() => {
        setChoices(data[index - 1].questionChoices);
      }, 1);
    }
    
  }

  // button next question on click
  const nextHandler = (e) => {
    e?.preventDefault();
    if (index < activenav.length) {
      let questionId = activenav[index].id;
      setChoices(data[index].questionChoices);
      setIndex(index + 1);
      setIndexid(questionId);
    }
    checkComplete();
  }

  const sumbitHandler = (e) => {
    e?.preventDefault();
    checkComplete();
    if (isComplete) {
      const pathId = props.location.state.id;
      // Sumbit Answers
      const UserId = localStorage.getItem("id");
      const lessonId = props.location.state.idLisson;
      const NoOfCorrectAnswers = answersList.reduce(
        (a, b) => a + b,
        0
      );
      event?.preventDefault();
      
      axios
        .post(
          `api/SLPTest/${UserId}/${pathId}/${NoOfCorrectAnswers}`
        )
        .then((res) => {
          setDataTest(res.data);
          if (res.data.passingPercent >= 50) {
            // post data lesson include finaltest 
            axios.post(`/api/SLPLesson/${UserId}/${idLesson}`).then((res) => {
            });
          }
        });
    
      document.getElementById("exampleModal").hide();
    } else {
      alert("You should answer all qustions!!");
    }
  }

  // button back question on click
  const prevHandler = (e) => {
    e?.preventDefault();
    if (index !== 1) {
      let questionId = activenav[index - 2].id;
      setChoices(data[index - 2].questionChoices);
      setIndex(index - 1);
      setIndexid(questionId);
    }
  }

  const pathcourse = props.location.state.pathcourse;

  let Degree;
  if (dataTest.passingPercent >= 50) {
    Degree = (
      <div className="degree-exam">
        <h6 className="degree-fail">{dataTest.passingPercent}%</h6>
        <img src={img_succeeded}></img>
        <h4>{i18next.t('final-test-pass-title')}</h4>
        <Link className="btn-succeeded" to={`/courselessons/${idCourse}`}>
            {i18next.t('final-test-pass-btn')}
        </Link>
      </div>
    );
  } if (dataTest.passingPercent <= 49) {
    Degree = (
      <div className="degree-exam">
        <h6 className="degree-fail">{dataTest.passingPercent}%</h6>
        <img src={img_fail} alt="Not Passed" />
        <h4>{i18next.t('final-test-faild-title')}</h4>
        <a 
          className="btn-fail"
          onClick={() => { window.location.reload();}}
        >
          {i18next.t('final-test-faild-btn')}
        </a>
      </div>
    );
  }
  return (
    <div className="container-flued">
      <div className="mid-final">
        <div className="nav-item">
          <Link to={`/courselessons/${idCourse}`}>
            <button className="navbar-btn">
              X
            </button>
          </Link>
        </div>
        <div className="row">
          <div className="iqtest-item-title">
            <h2 className="my-4">{i18next.t('final-test-title')}</h2>
          </div>
          <div className="button-toolbar">
            <div
              className="btn-toolbar mt-3 mb-5"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                className="btn-group me-2"
                role="group"
                aria-label="First group"
              >
                {activenav.map((postnav, indexItem) => {
                  return (
                    <div
                      className="col m-1"
                      key={indexItem}
                    >
                      <button
                        onClick={() => clicknav(postnav)}
                        type="button"
                        className={`btn btn-outline-secondary ${indexItem == index - 1
                          ? "activeLink"
                          : ""
                          }`}
                      >
                        {postnav.index}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="iqtest-item-q">
            <hr className="mt-4 mb-3" />
            {activenav[index - 1]?.question ? (
              <h5 className="my-3 mx-2">
                {activenav[index - 1]?.question}
              </h5>
            ) : (
              <img className="w-100" src={activenav[index - 1]?.image} alt="question image" />
            )}
            {choices?.map((postchoices, indexItem) => {
              return (
                <div className="form-check" key={Math.random()}>
                  <input
                    id={`flexRadio-${postchoices.id}`}
                    className={`form-check-input ${active[index - 1]?.choice == postchoices?.choice ? "active-choice" : ""}`}
                    type="radio"
                    name="flexRadio"
                    onChange={() => {
                      clickchoices(postchoices)
                      saveChoice(postchoices)
                    }}
                  />
                  <label className="form-check-label" for={`flexRadio-${postchoices.id}`}>
                    {postchoices.choice}
                  </label>
                </div>
              );
            })}
            <div className="btn-iqtestitem mt-5 mb-4">
              <button
                className="btn-prev"
                onClick={() => prevHandler()}
              >
                {i18next.t('final-test-prev')}
              </button>
              {/* Next Or Submit */}
              {index === activenav.length ? (
                <div>
                  {isComplete ? (
                    <button
                      onClick={() => sumbitHandler()}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="btn-submit"
                    >
                      {i18next.t('final-test-complete')}
                    </button>
                  ) : (
                    <button className="btn-dis" type="button" disabled>
                      {i18next.t('final-test-complete')}
                    </button>
                  )}
                </div>
              ) : (
                <button
                  className="btn-next"
                  onClick={() => nextHandler()}
                >
                  {i18next.t('final-test-next')}
                </button>
              )}
            </div>
            {/* <!-- Modal --> */}
            <div>
              {dataTest.passingPercent>50? <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title w-100 text-center"
                      id="staticBackdropLabel"
                    >
                      {i18next.t('iqtest-degree')}
                    </h5>
                  </div>
                  <div className="modal-body">{Degree}</div>
                  
                </div>
              </div>
            </div>:""}
             
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title w-100 text-center"
                      id="staticBackdropLabel"
                    >
                      {i18next.t('iqtest-degree')}
                    </h5>
                  </div>
                  <div className="modal-body">{Degree}</div>
                  <div>
                    {props.location.state.id == 62 & dataTest.passingPercent >= 50 ? (
                      <div className="d-flex justify-content-center container pb-3">
                        <a href="https://messarat.com/images/iq%20test%20course/geometric%20shapes%20tests/geometric%20shapes%20tests%20answer-01.pdf" download className="btn-submit" target="_blank">
                          {i18next.t("final-test-dwonload")}
                        </a>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {props.location.state.id == 64 & dataTest.passingPercent >= 50 ? (
                      <div className="d-flex justify-content-center container pb-3">
                        <a href="https://messarat.com/images/iq%20test%20course/verbal%20test/verbal%20test%20anwers-01.pdf" download className="btn-submit" target="_blank">
                          {i18next.t("final-test-dwonload")}
                        </a>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {props.location.state.id == 66 & dataTest.passingPercent >= 50 ? (
                      <div className="d-flex justify-content-center container pb-3">
                        <a href="https://messarat.com/images/iq%20test%20course/numerical%20arithmetic%20tests/numerical%20arithmetic%20tests%20answers-01.pdf" download className="btn-submit" target="_blank">
                          {i18next.t("final-test-dwonload")}
                        </a>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinalTest;
