import React, { Component } from "react";
import "./CardOverview.scss";
import Button from "../Button/Button";
import Dialoge from "../Dialoge/Dialoge";
import $ from "jquery";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
import img_test from '../../assets/images/overview/course.png';

//import ReactDOM from 'react-dom';
const CardOverview = (props) => {
  return (
        <div className="card-overview">
          <Link to={props.path}>
            <img src={props.image} className="card-img-icon" alt="Image Corse" />
            <div className="card-body m-0">
              <h3 className="card-overview-text">{props.name}</h3>
              <p className="card-overview-num mx-2">
                <span className="mx-2">{props.num}</span>
              </p>
              <div className="m-2">
                {/* <Link to="/course-details">
                    <Button name={"Free"} />
                  </Link> */}
              </div>
            </div>
          </Link>
    </div>
  );
};

export default CardOverview;
