import Button from "../../../components/Button/Button";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./IQTest.scss";
import axios from "axios";
import i18next from "i18next";
import IqTestLevel from "../../../components/IqTestLevel/IqTestLevel";
import img_sorry from '../../../assets/images/charecter/boycry.png';
import MenuProfile from "../../../components/MenuProfile/MenuProfile";

const idUser = localStorage.id;
class IQTest extends Component {
  // state to save data form api
  state = {
    item: [],
    profileSummary: []
  };
  // get data form api
  componentDidMount() {
    axios
      .get(
        `/api/SLPIQTest/GetUserLeaderboard/${idUser}`
      )
      .then((res) => {
        const item = res.data;
        this.setState({ item });
        
      });
    axios.get(`/api/SLPCourse/Profile/${idUser}`).then((res) => {
      const profileSummary = res.data;
      this.setState({ profileSummary });
    });
  }
  render() {
    let data;
    if (this.state.profileSummary.completedLevels === 0) {
      data = (
        <div className="row d-flex justify-content-center">
          <div className="my-iqtest-no-data">
            <img src={img_sorry} alt="no data" />
            <h4>{i18next.t("my-iqtest-no-data")}</h4>
          </div>
          <div className="btn-nodata">
            <Link to="/iqtestlevels" className="my-iqtest-btn">
              <Button name={i18next.t("my-iqtest-btn")} />
            </Link>
          </div>
        </div>
      );
    }
    else if (this.state.profileSummary.completedLevels > 0) {
      data = (
        <>
        <div className="row d-flex justify-content-center">
          {this.state.item.map((iqlevel, index) => {
            return (
              <div className="col-12 col-md-6 col-lg-4" key={index}>
                <IqTestLevel
                  text={iqlevel.iqTest.title}
                  resultPercent={iqlevel.resultPercent}
                  path={{
                    pathname: `/GetIQTestQuestions/${iqlevel.iqTest.id}`,
                    state: {
                      id: `${iqlevel.iqTest.id}`,
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="btn-nodata">
            <Link to="/iqtestlevels" className="my-iqtest-btn">
              <Button name={i18next.t("my-iqtest-btn")} />
            </Link>
          </div>
          </>
      );
    }
    return (
      <div className="container">
        <MenuProfile/>
        <div className="iq-test">
          {data}
        </div>
      </div>
    );
  }
}

export default IQTest;