import React, { Component } from "react";
import "./VideoModal.scss";
import { Link, useHistory, Redirect } from "react-router-dom";
import i18next from "i18next";
import Register from "../../components/Register/Register";
import Login from "../../components/Login/Login";
import { queue } from "jquery";
import { useRecoilState } from "recoil";
import { closeVideoState, selectedVideoState } from "../../atom";
import { useEffect } from "react";
import { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";



const VideoModal = (props) => {
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
  const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);
  const element = document?.getElementById("videoModal");
  const showVideo = element?.classList.contains('some-class');

  useEffect(() => {

    if (closeVideo) {
      element?.classList.remove("show");
      element?.classList.add("hide");
    } else {
      element?.classList.remove("hide");
      element?.classList.add("show");
    }

    
  }, [closeVideo, showVideo]);

  return (
    <div
      className="modal fade"
      id="videoModal"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
      onClick={() => setCloseVideo(true)}
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            
          </div>
          <div className="tab-content tab-content-video" id="pills-tabContent">
            {closeVideo !== true ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) :
              ""}
            {closeVideo !== true ? (
              <Vimeo paused={!closeVideo} onPlay={!closeVideo} responsive={true} video={`https://vimeo.com/${selectedVideo}`} />
            ) : (
              <div></div>
            )}
            {/* <iframe className="video-youtube" width="100%" src={`https://www.youtube.com/embed/${selectedVideo}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
