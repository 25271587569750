import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./Offers.scss";
import Why from "../../components/Why/Why";
import i18next from "i18next";
import axios from "axios";
import CardIQTest from "../../components/CardIQTest/CardIQTest";
import CardSub from "../../components/CardSub/CardSub";
import Card from "../../components/Card/Card";
import LoginModel from "../../components/LoginModel/LoginModel";
import OpenCompetitionsModal from "../../components/OpenCompetitionsModal/OpenCompetitionsModal";
import GiftModel from "../../components/GiftModel/GiftModel";
import img_h_1 from "../../assets/images/home/1.jpg";
import img_h_2 from "../../assets/images/home/2.jpg";
import img_h_3 from "../../assets/images/home/3.png";
import img_h_4 from "../../assets/images/home/4.jpg";
import img_h_5 from "../../assets/images/home/5.jpg";
import img_h_6 from "../../assets/images/home/6.jpg";
import CardVideo from "../../components/CardVideo/CardVideo";
import Lottie from "lottie-web";
import anim_play from '../../assets/images/anim-play.json';
import { selectedVideoState, userState } from "../../atom";
import { useRecoilState, useRecoilValue } from "recoil";
import ShareProject from "../../components/ShareProject/ShareProject";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import StartCreatingModal from "../../components/StartCreatingModal/StartCreatingModal";
import { visitedstate } from "../../atom";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";


const Offers = (props) => {
  // hidden and show btn register for free in home
  // console.clear();
  // state to save data form api
  const [item, setItem] = useState([]);
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
  const user = useRecoilValue(userState)
  const [visited, setVisited] = useRecoilState(visitedstate);
  const [itemProjects, setItemProjects] = useState([]);
  const [card_active_1, setCardActive_1] = useState(false);
  const [card_active_2, setCardActive_2] = useState(false);
  const [card_active_3, setCardActive_3] = useState(false);
  const [card_active_4, setCardActive_4] = useState(false);
  const [card_active_5, setCardActive_5] = useState(false);
  const [card_active_6, setCardActive_6] = useState(false);
  const [card_active_7, setCardActive_7] = useState(false);
  const [card_active_8, setCardActive_8] = useState(false);
  const [card_active_9, setCardActive_9] = useState(false);
  const [card_active_10, setCardActive_10] = useState(false);
  const [card_active_11, setCardActive_11] = useState(false);
  const [card_active_12, setCardActive_12] = useState(false);
  const [card_active_13, setCardActive_13] = useState(false);
  const [card_active_14, setCardActive_14] = useState(false);
  const [loopCard, setLoopCard] = useState(0);
  const [loopCard_2, setLoopCard_2] = useState(0);

  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    setTimeout(() => {
      setCardActive_1(true);
    }, 0);
    setTimeout(() => {
      setCardActive_1(false);
      setCardActive_2(true);
    }, 4000);
    setTimeout(() => {
      setCardActive_2(false);
      setCardActive_3(true);
    }, 7000);
    setTimeout(() => {
      setCardActive_3(false);
      setCardActive_4(true);
    }, 10000);
    setTimeout(() => {
      setCardActive_4(false);
      setCardActive_5(true);
    }, 13000);
    setTimeout(() => {
      setCardActive_5(false);
      setCardActive_6(true);
    }, 16000);
    setTimeout(() => {
      setCardActive_6(false);
    }, 18000);
  }, [loopCard])

  useEffect(() => {
    setTimeout(() => {
      setCardActive_7(true);
    }, 0);
    setTimeout(() => {
      setCardActive_7(false);
      setCardActive_8(true);
    }, 4000);
    setTimeout(() => {
      setCardActive_8(false);
      setCardActive_9(true);
    }, 7000);
    setTimeout(() => {
      setCardActive_9(false);
      setCardActive_10(true);
    }, 10000);
    setTimeout(() => {
      setCardActive_10(false);
      setCardActive_11(true);
    }, 13000);
    setTimeout(() => {
      setCardActive_11(false);
      setCardActive_12(true);
    }, 16000);
    setTimeout(() => {
      setCardActive_12(false);
      setCardActive_13(true);
    }, 19000);
    setTimeout(() => {
      setCardActive_13(false);
      setCardActive_14(true);
    }, 22000);
    setTimeout(() => {
      setCardActive_14(false);
    }, 25000);
  }, [loopCard_2])

  useEffect(() => {
    setTimeout(() => {
      setLoopCard(loopCard + 1);
    }, 18000);
  }, [loopCard])
  useEffect(() => {
    setTimeout(() => {
      setLoopCard_2(loopCard + 1);
    }, 25000);
  }, [loopCard_2])
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);
  const Lang = localStorage.i18nextLng.toUpperCase();
  let imgAnimTop;
  imgAnimTop = (
    <div
      className="col-12 col-lg-6 order-ar-0"
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
      onLoad={handleOnLoad}
    >
      <div className="top-video">
        <h2>{i18next.t("text-course-bulk-title")}</h2>
        {/*  */}
        {/* <VideoPlayer src="https://vimeo.com/702801564" /> */}
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/11`,
          state: {
            id: 11,
          },
        }}
        >
          <div className={`card-home ${card_active_1 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_1} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>الذكاء الاصطناعي</h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/12`,
          state: {
            id: 12,
          },
        }}
        >
          <div className={`card-home ${card_active_2 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_2} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>الذكاء الاصطناعي - متقدم</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/1`,
          state: {
            id: 1,
          },
        }}
        >
          <div className={`card-home ${card_active_3 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_4} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم الالعاب </h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/2`,
          state: {
            id: 2,
          },
        }}
        >
          <div className={`card-home ${card_active_4 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_3} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم الالعاب-المرحلة الثانية</h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/6`,
          state: {
            id: 6,
          },
        }}
        >
          <div className={`card-home ${card_active_5 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_5} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>التعليم الالي</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/7`,
          state: {
            id: 7,
          },
        }}
        >
          <div className={`card-home ${card_active_6 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={img_h_6} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>التعليم الالي-متقدم</h2>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
  let btnhome;
  if (user) {
    btnhome = (
      <Link to="/paybulk">
        <Button name={i18next.t("home-s1-btn-p")} />
      </Link>
    );
  } else {
    btnhome = (
      <a
        className="card-price-btn d-flex align-items-center"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#loginModal"
        href="#"
      >
        <Button name={i18next.t("home-s1-btn")} />
      </a>
    );
  }
  let texthome;
  if (props.user) {
    texthome = (
      <div className=" mt-lg-2 pt-lg-2 mt-xl-4 pt-xl-4 mt-xxl-5 pt-xxl-5">
        <h2>{i18next.t("home-s1-text")}</h2>
        <p>{i18next.t("home-s1-text-p")}</p>
      </div>
    );
  } else {
    texthome = (
      <div className="pt-xxl-5 pt-md-0 ">
        <h2>{i18next.t("home-s1-text")}</h2>
        <p>{i18next.t("home-s1-text-p")}</p>
      </div>
    );
  }

  let imgAnimTop_2;
  imgAnimTop_2 = (
    <div
      className="col-12 col-lg-6 order-ar-0"
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="top-video">
        <h2>الدورات التحضيرية</h2>
        {/*  */}
        {/* <VideoPlayer src="https://vimeo.com/702801564" /> */}
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/1`,
          state: {
            id: 1,
          },
        }}
        >
          <div className={`card-home ${card_active_7 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={'https://iotkidsiq.com/images/course%20images/Game%20Design_smaller.jpg'} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم الالعاب</h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/3`,
          state: {
            id: 3,
          },
        }}
        >
          <div className={`card-home ${card_active_8 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={'https://iotkidsiq.com/images/course%20images/Math%20with%20Scratch_smaller.jpg'} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>الرياضيات مع البرمجة</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/4`,
          state: {
            id: 4,
          },
        }}
        >
          <div className={`card-home ${card_active_9 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Video%20Sensing_smaller.jpg"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>استشعار الفيديو</h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/5`,
          state: {
            id: 5,
          },
        }}
        >
          <div className={`card-home ${card_active_10 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Env%20Eng_smaller.jpg"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>حلول البيئة</h2>
            </div>
          </div>
        </Link>

        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/24`,
          state: {
            id: 24,
          },
        }}
        >
          <div className={`card-home ${card_active_11 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Env%20Eng_smaller.jpg"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم ثلاثي الابعاد</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/29`,
          state: {
            id: 29,
          },
        }}
        >
          <div className={`card-home ${card_active_12 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Video%20Sensing_smaller.jpg"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>استشعار الفيديو-الثاني</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/2`,
          state: {
            id: 2,
          },
        }}
        >
          <div className={`card-home ${card_active_13 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Game%20Design%2002_smaller.png"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم الالعاب-الثاني</h2>
            </div>
          </div>
        </Link>
        <Link to={{
          // pathname: `/course/${postDetails.id}`,
          pathname: `/course/23`,
          state: {
            id: 23,
          },
        }}
        >
          <div className={`card-home ${card_active_14 ? 'active-cart-home' : ""}`}>
            <div className="card-home-img">
              <img src={"https://iotkidsiq.com/images/course%20images/Video%20Sensing_smaller.jpg"} alt="video" />
            </div>
            <div className="card-home-text">
              <h2>تصميم الالعاب-الثالث</h2>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
  let btnhome_2;
  if (user) {
    btnhome_2 = (
      <Link to="/paybulkgame">
        <Button name={i18next.t("home-s1-btn-p")} />
      </Link>
    );
  } else {
    btnhome_2 = (
      <a
        className="card-price-btn d-flex align-items-center"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#loginModal"
        href="#"
      >
        <Button name={i18next.t("home-s1-btn")} />
      </a>
    );
  }
  let texthome_2;
  if (props.user) {
    texthome_2 = (
      <div className="mt-lg-2 pt-lg-2 mt-xl-4 pt-xl-4 mt-xxl-5 pt-xxl-5">
        <h2>{i18next.t("home-s1-text_2")}</h2>
        <p>{i18next.t("home-s1-text-p_2")}</p>
      </div>
    );
  } else {
    texthome_2 = (
      <div className="pt-xxl-5 pt-md-0 ">
        <h2>{i18next.t("home-s1-text_2")}</h2>
        <p>{i18next.t("home-s1-text-p_2")}</p>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="offers">
        <div className="img-bg"></div>
        <div className="top pb-xl-3 pb-lg-0 pb-md-3 pb-5">
          <div className="row">
            <div
              className="col-12 col-lg-6 order-ar-1"
              data-aos="fade-right"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="text-carousol top-text">
                {texthome}
                {btnhome}
              </div>
            </div>
            {imgAnimTop}
          </div>
        </div>
        <div className="top pb-xl-3 pb-lg-0 pb-md-3 pb-5">
        <div className="row">
          <div
            className="col-12 col-lg-6 order-ar-1"
            data-aos="fade-right"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            <div className="text-carousol top-text">
              {texthome_2}
              {btnhome_2}
            </div>
          </div>
          {imgAnimTop_2}
        </div>
      </div>
      </div>
      <LoginModel />
      <StartCreatingModal />
      <OpenCompetitionsModal />
      <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
        <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
    </div>
  );
}

export default Offers;
