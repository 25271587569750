import React, { Component, useEffect } from "react";
import "./IqTestLevel.scss";
import img_icon from "/src/assets/images/courses/check-mark.png";
import anim_1 from "/src/assets/images/test/anim-1.gif";
import { BrowserRouter as Link } from "react-router-dom";
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Lottie from "lottie-web";

//import ReactDOM from 'react-dom';
const IqTestLevel = (props) => {
  return (
    <div className="row">
      <div className="list-group-item col-12" data-aos="flip-down" data-aos-delay="200">
        <div className="card">
          <Link to={props.path}>
            {/* <img
              src="/src/assets/images/IQ-Test-382x300.jpg"
              className="card-img-top"
              alt="Image Corse"
            /> */}
            <div className="card-anim-top">
              <CircularProgressbarWithChildren value={props.resultPercent} styles={buildStyles({
                strokeLinecap: 'butt',

                // Text size
                textSize: '26px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `rgba(241, 112, 170, ${100})`,
                textColor: '#f88',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}>
                {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                <img
                  style={{ width: 90,height:50 , marginTop: -5 }}
                  src={anim_1}
                  alt="anumation"
                />
                <div style={{ fontSize: 14, marginTop: 5 }}>
                <strong>{props.resultPercent}%</strong>
                </div>
                <span style={{fontSize: 16, marginTop: 5, fontWeight:700}}>{props.text}</span>
              </CircularProgressbarWithChildren>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default IqTestLevel;
