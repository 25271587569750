// import service from "../../../services";
//
// export const apiPostRegister = (data, error) => {
//     return new Promise((resolve) => {
//         service.postData(`/api/users`, data, error).then((data) => {
//             resolve(data);
//         }).catch((error) => {
//             resolve(error);
//         });
//     });
// };

import service from "../../../services";

export const apiPostRegister = (data) => {
    return new Promise((resolve, reject) => {
        service.postData(`/api/users`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};