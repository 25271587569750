// @ts-ignore
import React, { Component } from 'react';
import i18next from "i18next";
import './CourseDes.scss';

//import ReactDOM from 'react-dom';
const CourseDes = (props) =>{

return (
  <div className="courses-des">
    <h2>{i18next.t("lessons-des")}</h2>
    <p>{props.des}</p>
  </div>
);
}


export default CourseDes;