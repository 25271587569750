import React from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";

const AppFooter = () => {
  const waves = (
      <div className="w-full overflow-hidden relative -mt-16">
        <svg className="w-full h-24" viewBox="0 0 1440 320" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
          <path
              fill="#FFE45F"
              fillOpacity="1"
              d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,128C1120,117,1280,139,1360,149.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
          </path>
        </svg>
      </div>
  );

  return (
      <footer className="w-full mt-24">
        {waves}
        <div className="bg-[#FFE45F] -mt-1">
          <div className="container mx-auto px-6 lg:px-8 pb-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-12 text-right">
              {/* Company Section */}
              <div>
                <h3 className="text-lg font-bold mb-6">الشركة</h3>
                <ul className="space-y-4">
                  <li>
                    <Link to="/about" className="text-gray-800 hover:text-gray-600">
                      IoT KIDS عن
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#ourteam" className="text-gray-800 hover:text-gray-600">
                      تعرف على فريقنا
                    </Link>
                  </li>
                  {/*<li>*/}
                    {/*<Link to="/about#contactus" className="text-gray-800 hover:text-gray-600">*/}
                    {/*  تواصل معنا*/}
                    {/*</Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/joinourteam" className="text-gray-800 hover:text-gray-600">
                      انضم لفريقنا
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Courses Section */}
              <div>
                <h3 className="text-lg font-bold mb-6">دوراتنا</h3>
                <ul className="space-y-4">
                  <li>
                    <Link to="/Messarat/4" className="text-gray-800 hover:text-gray-600">
                      مسار الذكاء الاصطناعي
                    </Link>
                  </li>
                  <li>
                    <Link to="/Messarat/2" className="text-gray-800 hover:text-gray-600">
                      مسار الإلكترونيك والتصميم
                    </Link>
                  </li>
                  <li>
                    <Link to="/Messarat/1" className="text-gray-800 hover:text-gray-600">
                      مسار البرمجيات
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Achievements Section */}
              <div>
                <h3 className="text-lg font-bold mb-6">الانجازات</h3>
                <ul className="space-y-4">
                  <li>
                    <Link to="/projects" className="text-gray-800 hover:text-gray-600">
                      المشاريع
                    </Link>
                  </li>
                  <li>
                    <Link to="/competitions" className="text-gray-800 hover:text-gray-600">
                      المسابقات
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Download App Section */}
              <div>
                <h3 className="text-lg font-bold mb-6">حمل التطبيق الان عبر</h3>
                <div className="flex flex-col gap-4">
                  <a
                      href="https://play.google.com/store/apps/details?id=com.iotmaker.iotkids"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center bg-white rounded-xl px-6 py-3 hover:shadow-md transition-shadow duration-200"
                  >
                    <span className="text-gray-800">Google Play</span>
                  </a>
                  <a
                      href="https://apps.apple.com/us/app/iot-kids/id1637665030"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center bg-white rounded-xl px-6 py-3 hover:shadow-md transition-shadow duration-200"
                  >
                    <span className="text-gray-800">App Store</span>
                  </a>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="mt-16 text-center">
              <p className="text-sm text-gray-700">
                حقوق النشر © 2022 | صمم بواسطة IoT KIDS
              </p>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default AppFooter;