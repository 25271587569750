import React, { useState, useEffect } from "react";
import "./style.scss";
import CartItem from "../../components/Cart/CartItem";
import Button from "../../components/Button/Button";

import {
  basketState,
  shopTotalPriceState,
  shopTotalDiscountState,
  visitedstate
} from "../../atom";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import LoginModel from "../../components/LoginModel/LoginModel";
const id =localStorage.getItem("id")
const lang =localStorage.getItem("lang")
const Cart = (props) => {
  const [basket] = useRecoilState(basketState);
  let history = useHistory();
  const [total, setTotal] = useRecoilState(shopTotalPriceState);
  const [discount, setDiscount] = useRecoilState(shopTotalDiscountState);
  const [delivery, setDelivery] = useState(0);

  useEffect(() => {
    if (basket.length === 0) {
      history.goBack();
    } else {
      let _totalPrice = 0;
      basket.map((el) => {
        _totalPrice += Number(el.endprice) * el.qty;
      });
      setTotal(_totalPrice);
    }
  }, [basket]);
  const [visited, setVisited] = useRecoilState(visitedstate);
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);

  return (
    <div className="cart" onLoad={handleOnLoad} style={{ paddingTop: 40 }}>
      <div className="cart-box">
        <section className="cart-container head app-flex">
          <div>
            <h5>{i18next.t("cart-title")}</h5>
            <small>{i18next.t("cart-desc")}</small>
          </div>
          <div className="back-btn-1">
          <button onClick={() => history.push("/store")} className="btn-back">
          {i18next.t("back-to-store")}
          </button>
          </div>
        </section>
        <section className="cart-list">
          <ul>
            {basket.map((el, index) => (
              <li className="cart-container" key={index}>
                <CartItem
                  id={el.id}
                  image={el.image}
                  name={lang=="ar"?el.name:el.nameEN}
                  price={el.price}
                  endprice={el.endprice}
                  qty={el.qty}
                />
              </li>
            ))}
          </ul>
        </section>
        <section className="order-detials">
          <div className="cart-container order-detials-item">
            <span>{i18next.t("cart-total")} : </span>
            <p>
              {Number(total).toLocaleString()}{" "}
              <span>{i18next.t("card-currency")}</span>
            </p>
          </div>
          {/* <div className="cart-container order-detials-item">
            <span>{i18next.t("cart-discount")} : </span>
            <p>
              {Number(discount).toLocaleString()}{" "}
              <span>{i18next.t("card-currency")}</span>
            </p>
          </div> */}
          <div className="cart-container order-detials-item">
            <span>{i18next.t("cart-delivery")} : </span>
            <p>
              {Number(delivery).toLocaleString()}{" "}
              <span>{i18next.t("card-currency")}</span>
            </p>
          </div>
          <div className="cart-container order-detials-item">
            <div className="back-btn-1"> 
              {!id ? <button
              className="btn-back"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#loginModal"
              href="#"
            >{i18next.t("cart-checkout")}</button>:
            <button
              className="btn-back"
              onClick={() => history.push("/shoppay")}
            >{i18next.t("cart-checkout")}</button>
              }
            </div>
            <div className="total">
              <small>{i18next.t("cart-final-price")}</small>
              <b style={{ fontSize: 26 }}>
                {Number(total - discount).toLocaleString()}{" "}
                <span>{i18next.t("card-currency")}</span>
              </b>
            </div>
          </div>
        </section>
      </div>
      <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
        <LoginModel/>
    </div>
  );
};

export default Cart;
