import React from "react";
import "./RewardsPoint.scss";
import img_rewards from "/src/assets/images/cup.png";

//import ReactDOM from 'react-dom';
const RewardsPoint = (props) => {
  return (
    <div data-aos="flip-down" data-aos-delay="200">
      <div className="card-rewards">
        <a >
          <img
            src={img_rewards}
            className="card-img-top"
            alt="Image Corse"
          />
          <div className="card-body">
            <p className="card-text">{props.title}</p>
            <p className="card-num">{props.num}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default RewardsPoint;
