import React, { useEffect, useState } from "react";
import "./OverviewCompletedCourseModel.scss";
import i18next from "i18next";
import CardProgress from "../CardProgress/CardProgress";
import axios from "axios";
import Button from "../Button/Button";
import img_sorry from "../../assets/images/my-courses/sorry.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const OverviewCompletedCourseModel = (props) => {
  const idUser = localStorage.id;
  const [item, setItem] = useState([]);
  const [profileSummary, setProfileSummary] = useState({});
  useEffect(() => {
    axios.get(`/api/SLPCourse/${idUser}`).then((res) => {
      const item = res.data;
      setItem(item);

      axios
        .get(`/api/SLPCourse/Profile/${idUser}`)
        .then((res) => {
          const profileSummary = res.data;
          setProfileSummary(profileSummary);
        });
    });
  }, []);

  let data;
  if (profileSummary.noOfCourses === 0) {
    data = (
      <div className="row">
        <div className="my-course-no-data mt-4">
          <img src={img_sorry} alt="no data" />
          <h4>{i18next.t("my-course-no-data")}</h4>
        </div>

        <div className="col-12">
          <div className="my-courses-btn mt-5 pt-4">
            <Link to="/courses">
              <Button name={i18next.t("my-course-no-data-btn")} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
  if (profileSummary.noOfCourses > 0) {
    data = (
      <div className="row">
        {item.map((mycourses, index) => {
          if (mycourses.completedPercent >= 100) {
            return (
              <div className="col-12 col-md-6 col-lg-4" key={index}>
                <CardProgress
                  name={mycourses.course.title}
                  percentage={mycourses.completedPercent}
                  image={mycourses.course.ref03}
                  path={{
                    // pathname: `/course/${postDetails.id}`,
                    pathname: `courselessons/${mycourses.course.id}`,
                    state: {
                      courseId: mycourses.course.id,
                    },
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }
  return (
    <div
      className="modal overview-completed-course-modal fade"
      id="overview-completed-courseModal"
      aria-labelledby="overview-completed-courseModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="header-model">
            <h2 className="header-model-title">
                {i18next.t("overview-completed-course-modal-title")}
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="body-model">{data}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewCompletedCourseModel;
