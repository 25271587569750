import React, { Component } from "react";
import "./LockModel.scss";
import { Link, useHistory, Redirect } from "react-router-dom";
import i18next from "i18next";
import img_wait from "../../assets/images/test/24-hours.png";

const LockModel = () => {
  return (
    <div
      className="modal modal-lock fade"
      id="lockModal"
      aria-labelledby="lockModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="lockModalLabel">
              {i18next.t("lock-title")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img className="lock-img" src={img_wait} alt="image wait" />
            <h6> {i18next.t("lock-des")}</h6>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockModel;
