import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MessaratDetails, visitedstate } from "../../../atom";
import { apigetMessartDetails } from "../../../services/api/generalApi";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import MymessartCard from "../../../components/MyMessaratCard/MyMessaratCard";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

// Import course images
import coding from '../../../assets/images/NewMassarsPics/ai.svg';
import iqTests from '../../../assets/images/NewMassarsPics/coding.svg';
import electronic from '../../../assets/images/NewMassarsPics/electronicAndDesign.svg';
import gameDevelopment from '../../../assets/images/NewMassarsPics/gameDevelopment.svg';
import graphicDesign from '../../../assets/images/NewMassarsPics/GraphicDesign.svg';
import ai from '../../../assets/images/NewMassarsPics/iqTests.svg';
import schoolsContest from '../../../assets/images/NewMassarsPics/schoolsContest.svg';
import webPath from '../../../assets/images/NewMassarsPics/webPath.svg';

const getImageByRoadmapId = (id) => {    switch (id) {
    case 1:
        return webPath;
    case 2:
        return electronic;
    case 8:
        return gameDevelopment;
    case 3:
        return iqTests;
    case 12:
        return graphicDesign;
    case 11:
        return coding;
    case 7:
        return schoolsContest;
    default:
        return ai;
}

};

const idUser = localStorage.id;

const MyMessart = () => {
    const [messaratSLP, setMEssaratSlp] = useRecoilState(MessaratDetails);
    const [visited, setVisited] = useRecoilState(visitedstate);

    const GetMessaratDetails = async (id) => {
        const data = await apigetMessartDetails(id);
        setMEssaratSlp(data);
    };

    useEffect(() => {
        GetMessaratDetails(idUser);
    }, []);

    const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);

    return (
        <div className="min-h-screen" onLoad={handleOnLoad}>
            <div className="container mx-auto px-4 py-8">
                <MenuProfile />
                <div className="my-courses mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
                        {messaratSLP?.filter((el) => el.isEnrolled === true).map((item) => (
                            <Link to={`/packagedetails/${item.id}`} key={item.id} className="block">
                                <MymessartCard
                                    name={item.title}
                                    RoadmapID={item.id}
                                    delprice="200,000"
                                    price="150,000"
                                    NOofcourses={item.messaratCourse.length}
                                    idpay={item.id}
                                    image={getImageByRoadmapId(item.id)}
                                    sub={item.isEnrolled}
                                />
                            </Link>
                        ))}
                    </div>

                    {/*<div className="fixed bottom-4 right-4">*/}
                    {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"*/}
                    {/*       className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition duration-300">*/}
                    {/*        <img src={img_whatsapp} alt="WhatsApp" className="w-6 h-6" />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default MyMessart;