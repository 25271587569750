import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import i18next from "i18next";
import Register from "../Register/Register";
import Login from "../Login/Login";
import "./LoginModel.scss";

const LoginModel = () => {
    const [activeTab, setActiveTab] = useState('login');
    const history = useHistory();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleNavigation = (path) => {
        history.push(path);
    };

    const renderButtonGroup = (isLoginTab) => (
        <div className="nav-pills-wrapper">
            <div className="nav nav-pills btn-group login__switcher" role="group">
                <button
                    className={`btn login__switcher-btn login-btn ${activeTab === 'login' ? 'active' : ''}`}
                    onClick={() => handleTabChange('login')}
                    type="button">
                    {i18next.t("login-btn-login")}
                </button>
                <button
                    className={`btn login__switcher-btn register-btn ${activeTab === 'register' ? 'active' : ''}`}
                    onClick={() => handleTabChange('register')}
                    type="button">
                    {i18next.t("login-btn-regester")}
                </button>
            </div>
        </div>
    );

    return (
        <div
            className="modal z-index-full fade"
            id="loginModal"
            aria-labelledby="loginModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-body">
                <div className="modal-content">
                    <div className="modal-body">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="tab-content">
                        <div
                            className={`tab-pane fade ${activeTab === 'login' ? 'show active' : ''}`}
                            id="pills-login"
                        >
                            <Login btnGroup={renderButtonGroup(true)} />
                        </div>
                        <div
                            className={`tab-pane fade ${activeTab === 'register' ? 'show active' : ''}`}
                            id="pills-register"
                        >
                            <Register btnGroup={renderButtonGroup(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginModel;