import React, { useEffect } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import "./MenuProfile.scss";
import LogoutModel from "../LogoutModel/LogoutModel"
import i18next from "i18next";

const MenuProfile = () => {
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    // Set the "مساراتي" link as active when the component mounts
    const element = document.querySelector('.nav-link[to="/mymessarat"]');
    if (element) {
      element.classList.add("active");
    }
  }, []);

  return (
      <div className="menu-profile d-flex justify-content-center">
        <div className="menu-scroll">
          <ul className="nav nav-tabs">
            <li className="nav-item order-item-1">
              <NavLink id="mymessarat"
                  className="nav-link"
                  activeClassName="active"
                  to="/mymessarat"
              >
                {i18next.t("مساراتي")}
              </NavLink>
            </li>
            {/* Commented out links */}
            {/*
          <li className="nav-item order-item-1">
            <NavLink
              activeClassName="active"
              aria-current="page"
              exact
              to="/overview"
              className="nav-link"
            >
              {i18next.t("profile-nav-1")}
            </NavLink>
          </li>
          <li className="nav-item order-item-2">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/mycourses"
            >
              {i18next.t("profile-nav-2")}
            </NavLink>
          </li>
          */}
            <li className="nav-item order-item-3">
              <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myprojects"
              >
                {i18next.t("profile-nav-7")}
              </NavLink>
            </li>
            <li className="nav-item order-item-4">
              <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/rewards"
              >
                {i18next.t("profile-nav-4")}
              </NavLink>
            </li>
            <li className="nav-item order-item-5">
              <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myaccount"
              >
                {i18next.t("profile-nav-5")}
              </NavLink>
            </li>
            <li className="nav-item order-item-6">
              <a
                  className="nav-link"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#logoutModal"
                  href="#"
              >
                {i18next.t("profile-nav-6")}
              </a>
              <LogoutModel />
            </li>
          </ul>
        </div>
      </div>
  );
};

export default MenuProfile;