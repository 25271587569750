import React, { useEffect, useState } from "react";
import CardShop from "../../components/CardShop/CardShop";
import BasketBtn from "../../components/BasketBtn";
import Loading from "../../components/Loading/Loading";
import i18next from "i18next";
import axios from "axios";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import { useRecoilState } from "recoil";
import { visitedstate } from "../../atom";

const Shop = () => {
    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visited, setVisited] = useRecoilState(visitedstate);

    useEffect(() => {
        setLoading(true);
        axios.get(`api/StoreProduct/GetAllProducts`).then((res) => {
            setItem(res.data);
            setLoading(false);
        });
    }, []);

    const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);

    let data;
    if (loading) {
        data = (
            <div className="text-center" onLoad={handleOnLoad}>
                <Loading width="160px" />
            </div>
        );
    } else {
        data = (
            <div onLoad={handleOnLoad} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {item.map((el, index) => (
                    <CardShop
                        key={index}
                        id={el.id}
                        name={i18next.language === "ar" ? el.name : el.nameEN}
                        price={el.unitPriceIQD}
                        endprice={el.unitPriceIQD}
                        image={el.image1}
                        description={i18next.language === "ar" ? el.description.slice(0, 50) : el.descriptionEN.slice(0,50)}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className=" min-h-screen pt-8">
            <div className="container mx-auto px-4 mb-8">
                {data}
            </div>

            <BasketBtn />

            {/*<div className="fixed bottom-4 right-4 z-50">*/}
            {/*    <a*/}
            {/*        href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"*/}
            {/*        className="block w-12 h-12 rounded-full bg-green-500 shadow-lg hover:bg-green-600 transition-colors duration-300"*/}
            {/*    >*/}
            {/*        <img src={img_whatsapp} alt="WhatsApp" className="w-full h-full p-2" />*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    );
};

export default Shop;