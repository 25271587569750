import React, { Component } from "react";
import "./CvShowModal.scss";
import { Link, useHistory, Redirect } from "react-router-dom";
import i18next from "i18next";
import img_logout from "../../assets/images/charecter/boycry.png";
import { useRecoilState } from "recoil";
import { cvImageState } from "../../atom";

const CvShowModal = () => {
  const [imgCv, setImgCv] = useRecoilState(cvImageState);
  return (
    <div
      className="modal modal-cvShow fade"
      id="cvShowModal"
      aria-labelledby="cvShowModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <h5 className="modal-title" id="cvShowModalLabel">
              {i18next.t("cvShow-title")}
            </h5>
          </div>
          <div className="modal-body">
            <img className="cvShow-img" src={imgCv} alt="image cvShow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CvShowModal;
