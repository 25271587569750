import React, { useEffect, useState } from "react";
import "./OverviewIQTestModel.scss";
import i18next from "i18next";
import CardProgress from "../CardProgress/CardProgress";
import axios from "axios";
import Button from "../Button/Button";
import img_sorry from "../../assets/images/my-courses/sorry.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import IqTestLevel from "../IqTestLevel/IqTestLevel";

const OverviewIQTestModel = (props) => {
  const idUser = localStorage.id;
  const [item, setItem] = useState([]);
  const [profileSummary, setProfileSummary] = useState({});
  useEffect(() => {
    axios.get(`/api/SLPIQTest/GetUserLeaderboard/${idUser}`).then((res) => {
      const item = res.data;
      setItem(item);

      axios
        .get(`/api/SLPCourse/Profile/${idUser}`)
        .then((res) => {
          const profileSummary = res.data;
          setProfileSummary(profileSummary);
        });
    });
  }, []);

  let data;
  if (profileSummary.completedLevels === 0) {
    data = (
      <div className="row">
        <div className="my-course-no-data mt-4">
          <img src={img_sorry} alt="no data" />
          <h4>{i18next.t("no-data-iq-test")}</h4>
        </div>

        <div className="col-12">
          <div className="my-courses-btn mt-5 pt-4">
            <Link to="/iqtestlevels" className="my-iqtest-btn">
              <Button name={i18next.t("my-iqtest-btn")} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
  if (profileSummary.completedLevels > 0) {
    data = (
      <div className="row">
        {item.map((iqlevel, index) => {
          return (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <IqTestLevel
                text={iqlevel.iqTest.title}
                path={{
                  pathname: `/GetIQTestQuestions/${iqlevel.iqTest.id}`,
                  state: {
                    id: `${iqlevel.iqTest.id}`,
                  },
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div
      className="modal overview-iqtest-modal fade"
      id="overview-iqtestModal"
      aria-labelledby="overview-iqtestModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="header-model">
            <h2 className="header-model-title">
                {i18next.t("overview-iqtest-modal-title")}
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="body-model">{data}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewIQTestModel;
