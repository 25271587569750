import React from "react";
import "./LogoutModel.scss";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userState } from "../../atom";
import i18next from "i18next";
import img_logout from "../../assets/images/charecter/boycry.png";

const LogoutModel = () => {
  const setUser = useSetRecoilState(userState);
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("iot_user");
    setUser(null);
    history.push("/");
  };

  return (
      <div
          className="modal modal-logout fade"
          id="logoutModal"
          aria-labelledby="logoutModalLabel"
          aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
              <h5 className="modal-title" id="logoutModalLabel">
                {i18next.t("logout-title")}
              </h5>
            </div>
            <div className="modal-body">
              <img className="logout-img" src={img_logout} alt="image logout" />
              <h6> {i18next.t("logout-des")}</h6>
            </div>
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn-close2"
                  data-bs-dismiss="modal"
              >
                {i18next.t("logout-btn-1")}
              </button>
              <button onClick={logout} type="button" className="btn-logout">
                {i18next.t("logout-btn-2")}
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default LogoutModel;