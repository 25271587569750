import React, { useEffect, useState } from "react";
import "./OpenCompetitionsModal.scss";
import i18next from "i18next";
import axios from "axios";
import Card from "../Card/Card";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const OpenCompetitionsModal = (props) => {
  const UserId = localStorage.id;
  const lang = localStorage.i18nextLng.toUpperCase();
  const [item, setItem] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    axios.get(`/api/UserReward/AllRewards/${UserId}`).then((res) => {
      const item = res.data;
      setItem(item);
    });
    axios
      .get(`/api/Course?Lang=${lang}&UserId=${UserId}`)

      .then((res) => {
        const item = res.data;
        setCourses(item);
      });
  }, []);

  return (
    <div
      className="modal open-competitions-modal fade"
      id="open-competitions-Modal"
      aria-labelledby="open-competitions-ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="header-model">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <h2 className="header-model-title">{i18next.t("c-title")}</h2>
            </div>
            <div className="body-model row">
              <div className="body-top">
                <h3>{i18next.t("c-title")}</h3>
                <p>{i18next.t("c-des")}</p>
                <div className="btn">
                  <div className="btn-all">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfLkfrHmJws0LUVz1eP580Owr1uvto32fhrT48KtTO5kAoj8A/viewform" target="_blank" className={`btn-c`}>
                      {i18next.t("open-competitions-modal-btn")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="body-bottom">
                <h3>{i18next.t("r-title")}</h3>
                <div className="row justify-content-center">
                  {courses.map((item, index) =>
                    item.id === 1 ||
                      item.id === 15 ||
                      item.id === 5 ||
                      item.id === 18 ? (
                      <div key={index} className="col-12 col-md-6 col-lg-5 ">
                        <Card
                          name={item.title}
                          price={item.priceIQD}
                          image={item.ref03}
                          description={item.description}
                          //  onClick={this.update(item.id)}
                          path={{
                            // pathname: `/course/${item.id}`,
                            pathname: `/course/${item.id}`,
                            state: {
                              id: item.id,
                            },
                          }}
                          pathId={item.id}
                        />
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenCompetitionsModal;
