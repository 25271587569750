import React from "react";
import i18next from "i18next";
import JobCard from "../../components/JobCard/JobCard";
import jobimg from "../../assets/images/job1.avif"
import { useRecoilState } from "recoil";
import { visitedstate } from "../../atom";
import "./JoinOurTeam.scss";
const JoinOurTeam = () => {
  const [visited, setVisited] = useRecoilState(visitedstate);
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);
  return (
    <div className="container text-center"onLoad={handleOnLoad}>
      <h1 className="color-title text-center mt-5 fw-bold ">{i18next.t("join-our-team-title")}</h1>
      <div className="row justify-content-center">
        <div className="col-5">
       <JobCard jobname="مطور وجهات امامية"
       img={jobimg}
       jobtype="دوام جزئي"
       />
       </div>
      </div>
      </div>

  );
};
export default JoinOurTeam;
