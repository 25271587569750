import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import contestsData from '../../assets/json/contests.json';

const CompetitionDetails = () => {
    const [contest, setContest] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const selectedContest = contestsData.contests.find(c => c.id === parseInt(id));
        setContest(selectedContest);
    }, [id]);

    if (!contest) {
        return <div className="text-center p-8">Loading...</div>;
    }

    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold text-center mb-8">{contest.title}</h1>
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="md:w-1/2">
                        <img src={contest.image} className="w-full h-auto rounded-lg shadow-md" alt={contest.title} />
                    </div>
                    <div className="md:w-1/2">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <p className="text-gray-700 mb-6">{contest.description}</p>
                            <h2 className="text-2xl font-semibold mb-4">تفاصيل</h2>
                            <ul className="space-y-2 mb-6">
                                <li><strong className="font-medium">حالة:</strong> <span className="text-gray-700">{contest.status}</span></li>
                                <li><strong className="font-medium">تاريخ البدا:</strong> <span className="text-gray-700">{contest.startDate}</span></li>
                                <li><strong className="font-medium">تاريخ النهاية:</strong> <span className="text-gray-700">{contest.endDate}</span></li>
                                <li><strong className="font-medium">الجائزة:</strong> <span className="text-gray-700">{contest.prize}</span></li>
                            </ul>
                            {contest.registrationLink && (
                                <a
                                    href={contest.registrationLink}
                                    className="inline-block bg-blue-600 text-white font-semibold py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
                                >
                                    Register Now
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompetitionDetails;