import React, { Component } from 'react';
import './CourseSub.scss';
import Button from '../Button/Button';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import i18next from 'i18next';
//import ReactDOM from 'react-dom';
const CourseBuy = (props) => {

  return (
    <div className="courses-buy">
      <div className="price">
        <span className="amount">{i18next.t("card-is-sub")}</span>
      </div>

      <div className="btn-buy">
        <Link to={props.path}>
          <Button name={i18next.t("btn-go-course")} />
        </Link>
      </div>

      <ul className="courses-buy-">
        <li>
          <h4>{i18next.t("text-content")}</h4>
        </li>
        <li>
          <div className="courses-buy-title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </li>
      </ul>
    </div>
  );
}


export default CourseBuy;