import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import "./StartCreatingModal.scss";
import Button from "../Button/Button";
import CardOffers from "../CardOffers/CardOffers";
import { useRecoilState } from "recoil";
import { MessaratDetails } from "../../atom";
import dataStartCreating from "../../assets/json/startCreating.json";

const StartCreatingModal = ({ name }) => {
    const [activeNum, setActiveNum] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const [oldNum, setOldNum] = useState(null);
    const [messaratpackage] = useRecoilState(MessaratDetails);
    const [step, setStep] = useState(1);
    const [data, setData] = useState(null);
    const lang = i18next.language;

    useEffect(() => {
        if (activeData) {
            const filteredData = messaratpackage.find((el) => el.id === activeData.courseId_1);
            setData(filteredData);
        }
    }, [activeData, messaratpackage]);

    const handleStepChange = (newStep) => {
        if ((newStep === 2 && oldNum !== null) || (newStep === 3 && activeData !== null) || newStep === 1) {
            setStep(newStep);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <h5 className="start-creating-title-2 col-12 m-auto">
                            {i18next.t("start-creating-title-1")}
                        </h5>
                        <div className="row">
                            {[0, 1, 2].map((num) => (
                                <div key={num} className="col-6 col-md-4">
                                    <button
                                        onClick={() => setOldNum(num)}
                                        className={`start-creating-btn-check ${oldNum === num ? "start-creating-btn-check-active" : ""}`}
                                    >
                                        <i className={`fa-solid ${num === 0 ? "fa-child-dress" : num === 1 ? "fa-child-reaching" : "fa-person"}`}></i>
                                        <span>{num === 0 ? "7-9" : num === 1 ? "10-12" : "12-15"}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="row mt-5 justify-content-between">
                            <span className="col-2"></span>
                            <div className="col-2">
                                <button onClick={() => handleStepChange(2)} className="btn btn-arrow">
                                    <i className={`fa-solid ${lang === "ar" ? "fa-arrow-left" : "fa-arrow-right"}`}></i>
                                </button>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <h5 className="start-creating-title-2 col-12 m-auto">
                            {i18next.t("start-creating-title-2")}
                        </h5>
                        <div className="row justify-content-center">
                            {dataStartCreating[oldNum].courseType.map((item, index) => (
                                <div key={index} className="col-6 col-md-3">
                                    <button
                                        onClick={() => {
                                            setActiveNum(index);
                                            setActiveData(item.course);
                                        }}
                                        className={`start-creating-btn-check ${activeNum === index ? "start-creating-btn-check-active" : ""}`}
                                    >
                                        <i className={`fa-solid ${
                                            item.title === "Game Design" ? "fa-gamepad" :
                                                item.title === "Robotics" ? "fa-robot" :
                                                    item.title === "Electronics" ? "fa-lightbulb" : "fa-brain"
                                        }`}></i>
                                        <span>{lang === "ar" ? item.title_ar : item.title}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="row mt-5 justify-content-between">
                            <div className="col-2">
                                <button onClick={() => handleStepChange(1)} className="btn btn-arrow">
                                    <i className={`fa-solid ${lang === "ar" ? "fa-arrow-right" : "fa-arrow-left"}`}></i>
                                </button>
                            </div>
                            <div className="col-2">
                                <button onClick={() => handleStepChange(3)} className="btn btn-arrow">
                                    <i className={`fa-solid ${lang === "ar" ? "fa-arrow-left" : "fa-arrow-right"}`}></i>
                                </button>
                            </div>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <h5 className="start-creating-title-2 col-12 m-auto">
                            {i18next.t("start-creating-title-3")}
                        </h5>
                        <div className="row justify-content-center">
                            {data && (
                                <div className="col-12 col-md-6">
                                    <Link to={`/packagedetails/${data.id}`}>
                                        <CardOffers
                                            name={data.title}
                                            RoadmapID={data.id}
                                            delprice="200,000"
                                            price="150,000"
                                            NOofcourses={data.messaratCourse?.length}
                                            idpay={data.id}
                                            image={data.image}
                                            sub={data.isEnrolled}
                                        />
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="row mt-5 justify-content-between">
                            <div className="col-2">
                                <button onClick={() => handleStepChange(2)} className="btn btn-arrow">
                                    <i className={`fa-solid ${lang === "ar" ? "fa-arrow-right" : "fa-arrow-left"}`}></i>
                                </button>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div
            className="modal modal-start-creating fade"
            id="start-creating-Modal"
            aria-labelledby="start-creating-ModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="start-creating-ModalLabel">
                            {name}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        {renderStep()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartCreatingModal;