import service from "../..";

export const apiPostGoogleAuth = (data, error) => {
    return new Promise((resolve) => {
        service.postData(`/api/users/GoogleAuth`, data, error).then((data) => {
            resolve(data);
        }).catch((error) => {
            resolve(error);
        });
    });
};
