import React, { Component } from "react";
import "./CardHorizebtalOffers.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";

//import ReactDOM from 'react-dom';
const CardHorizantal = (props) => {
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
          <Link to={props.path}>
          <div className="card-horizantal">
         
            <div className="card-body">
              <p className="card-text">{props.name}</p>
             
            </div>
        </div>
        </Link>
      </div>
    </div>
  );
};
export default CardHorizantal;
