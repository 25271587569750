import React, {useEffect, useState} from "react";
import {useLocation, Link} from "react-router-dom";
import "./CoursePlayer.scss";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import img_video from "../../assets/images/test/play-icon.png";
import img_mid from "../../assets/images/test/mid.png";
import img_final from "../../assets/images/test/final.png";
import img_cirtificate from "../../assets/images/test/medal.png";
import axios from "axios";
import i18next from "i18next";
import {IdLessonState, itemAllCourseState, userDataState} from "../../atom";
import {useRecoilState} from "recoil";
import {apiGetCourse} from "../../services/api/generalApi";
import {apiGetUserData} from "../../services/api/generalApi";
import {visitedstate} from "../../atom";
import chat from "../../assets/images/chat.png";
import chaten from "../../assets/images/chaten.png";
import AppFooter from "@/components/AppFooter/AppFooter";

const lang = localStorage.getItem("lang");

const CoursePlayer = () => {
    const location = useLocation();
    const pathname = window.location.pathname;
    const idCourse = pathname.match(/\d+$/)?.[0];
    const idUser = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const [userData, setUserData] = useRecoilState(userDataState);
    const [visited, setVisited] = useRecoilState(visitedstate);
    const [corses, setCourses] = useRecoilState(itemAllCourseState);
    const [coursedetails, setCoursedetails] = useState([]);
    const [allLessons, setAllLessons] = useState([]);
    const [LessonActive, setLessonActive] = useState(0);
    const [lengthCompleted, setLengthCompleted] = useState(0);
    const [allTests, setAllTests] = useState([]);
    const [degreeTestOne, setDegreeTestOne] = useState(0);
    const [degreeTestTwo, setDegreeTestTwo] = useState(0);
    const [changeLesson, setChangeLesson] = useState(0);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [msgError, setMsgError] = useState(null);
    const [item, setItem] = useState([]);
    const [idLesson, setIdLesson] = useRecoilState(IdLessonState);
    const [error, setError] = useState(null);

    // Configure axios defaults
    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });

    const handleRetry = () => {
        setLoadingBtn(true);
        window.location.reload();
    };

    const validateAuth = () => {
        if (!idUser || !token) {
            window.location.href = '/login';
            return false;
        }
        return true;
    };

    const isValidLesson = (lessonIndex) => {
        // Special case: Certificate view should not trigger validation
        if (degreeTestTwo >= 50 && allLessons.length === lengthCompleted) {
            return true;
        }

        // Basic bounds checking
        return lessonIndex >= 0 &&
            (allLessons ? lessonIndex < allLessons.length : false);
    };

    const setActiveLessonSafely = (index) => {
        // Special case: Certificate view
        if (degreeTestTwo >= 50 && allLessons.length === lengthCompleted) {
            setLessonActive(index);
            setError(null);
            return;
        }

        // If we're returning from a test, don't show validation errors
        if (location.state?.fromTest || location.state?.startingLesson !== undefined) {
            setLessonActive(index);
            setError(null);
            return;
        }

        if (isValidLesson(index)) {
            setLessonActive(index);
            setError(null);
        } else {
            // Only set error if we're not in a loading state
            if (allLessons && allLessons.length > 0) {
                setLessonActive(Math.min(lengthCompleted, allLessons.length - 1));
            }
        }
    };

    // Also modify the useEffect that handles test return state:
    useEffect(() => {
        if (location.state?.fromTest || location.state?.startingLesson !== undefined) {
            const targetIndex = location.state.startingLesson || 0;
            setLessonActive(targetIndex);
            setError(null);  // Clear any existing errors
            setMsgError(null);  // Clear any existing message errors
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state?.forceFirstLesson) {
            setLessonActive(0);
        } else if (location.state?.startingLesson !== undefined && allLessons.length > 0) {
            setActiveLessonSafely(location.state.startingLesson);
        }
    }, [allLessons, location.state]);

    // Handle state when returning from test


    useEffect(() => {
        if (!validateAuth()) return;

        axiosInstance.get(`/api/SLPCourse/${idUser}`).then((res) => {
            const item = res.data;
            const newItems = item.filter(
                (item) =>
                    item.courseId === 30 || item.courseId === 31 || item.courseId === 33
            );
            setItem(newItems);
        }).catch(err => {
            console.error("Error fetching course data:", err);
            if (err.response?.status === 401) {
                window.location.href = '/login';
            }
        });
    }, []);

    const GetCourse = async () => {
        try {
            const langUpperCase = i18next.language.toUpperCase();
            const userId = localStorage.getItem("id");
            const data = await apiGetCourse(langUpperCase, userId);
            setCourses(data);
            const datauser = await apiGetUserData();
            setUserData(datauser);
        } catch (err) {
            console.error("Error fetching course data:", err);
            setError("عذراً! حدث خطأ في تحميل بيانات الدورة");
        }
    };

    const selectCourse = () => {
        if (!Array.isArray(corses)) return;

        for (var i = 0; i < corses.length; i++) {
            if (corses[i]?.id == idCourse) {
                setCoursedetails(corses[i]);
                break;
            }
        }
    };

    useEffect(() => {
        if (corses.length == 0) {
            GetCourse();
        }
        selectCourse();
    }, [corses]);

    useEffect(() => {
        if (!validateAuth() || !idCourse) return;

        setLoadingBtn(true);
        axiosInstance.get(`/api/Lesson/courselessons/${idCourse}`)
            .then((res) => {
                if (res.data && Array.isArray(res.data) && res.data.length > 0) {
                    setAllLessons(res.data);
                    setError(null);
                } else {
                    setError("عذراً! لم نتمكن من العثور على الدروس في هذه الدورة 😕");
                }
            })
            .catch(err => {
                console.error("Error loading lessons:", err);
                if (err.response?.status === 401) {
                    window.location.href = '/login';
                } else {
                    setError("عذراً! هناك مشكلة في تحميل الدروس 😔");
                }
            })
            .finally(() => {
                setLoadingBtn(false);
            });
    }, []);

    useEffect(() => {
        if (!validateAuth() || !idCourse) return;

        setLoadingBtn(true);
        axiosInstance.get(`/api/SLPLesson/${idUser}/${idCourse}`)
            .then((res) => {
                const completedCount = res.data.length;
                setLengthCompleted(completedCount);

                if (!location.state?.startingLesson && !location.state?.forceFirstLesson) {
                    setActiveLessonSafely(Math.min(completedCount, allLessons.length - 1));
                }
            })
            .catch(err => {
                console.error("Error loading completion data:", err);
                setError("عذراً! لم نتمكن من تحميل تقدمك في الدورة 😕");
                if (err.response?.status === 401) {
                    window.location.href = '/login';
                }
            })
            .finally(() => {
                setLoadingBtn(false);
            });
    }, [lengthCompleted, changeLesson]);

    useEffect(() => {
        if (!validateAuth() || !idCourse) return;

        setLoadingBtn(true);
        if (allTests.length === 0) {
            axiosInstance.get(`/api/Test/GetCourseTests/${idCourse}`)
                .then((res) => {
                    setAllTests(res.data);
                })
                .catch(err => {
                    console.error("Error loading tests:", err);
                    setError("عذراً! حدث خطأ في تحميل الاختبارات 😕");
                });
        }

        const TestOne = allTests[0]?.id;
        const TestTwo = allTests[1]?.id;


        const fetchTestResults = async () => {
            try {
                if (TestOne) {
                    const res1 = await axiosInstance.get(`/api/SLPTest/GetPassPercent/${idUser}/${TestOne}`);
                    setDegreeTestOne(res1.data);
                }
                if (TestTwo) {
                    const res2 = await axiosInstance.get(`/api/SLPTest/GetPassPercent/${idUser}/${TestTwo}`);
                    setDegreeTestTwo(res2.data);
                }
            } catch (err) {
                console.error("Error loading test results:", err);
                setError("عذراً! هناك مشكلة في تحميل نتائج الاختبارات 😔");
                if (err.response?.status === 401) {
                    window.location.href = '/login';
                }
            } finally {
                setLoadingBtn(false);
            }
        };

        fetchTestResults();
    }, [allTests]);

    const handleOnLoad = () =>
        visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);

    const handlePreviousLesson = () => {
        if (isValidLesson(LessonActive - 1)) {
            setActiveLessonSafely(LessonActive - 1);
        }
    };

    const markCompleteLisson = (id) => {
        if (!id || !idUser) {
            setMsgError("عذراً! هناك خطأ في تحديد الدرس. يرجى تحديث الصفحة والمحاولة مرة أخرى.");
            return;
        }

        setLoadingBtn(true);
        axiosInstance.post(`/api/SLPLesson/${idUser}/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setChangeLesson(Math.random());
                    handleNextLesson();
                    setMsgError(null);
                } else {
                    throw new Error('Network response was not ok');
                }
            })
            .catch((err) => {
                console.error("Error marking lesson complete:", err);
                setMsgError("عذراً! حدث خطأ أثناء حفظ تقدمك. يرجى المحاولة مرة أخرى 😕");
                if (err.response?.status === 401) {
                    window.location.href = '/login';
                }
            })
            .finally(() => {
                setLoadingBtn(false);
            });
    };

    const handleNextLesson = () => {
        if (isValidLesson(LessonActive + 1)) {
            setActiveLessonSafely(LessonActive + 1);
        }
    };

    const getVideoSource = () => {
        if (allLessons[LessonActive]?.videoURL) {
            return allLessons[LessonActive].videoURL;
        }
        if (degreeTestTwo >= 50) {
            return coursedetails.endingVideoLink;
        }
        return allLessons[0]?.videoURL;
    };

    return (
        <>
            <div className="course-player" onLoad={handleOnLoad}>
                <div className="container-flued">
                    <nav className="navbar navbar-light bg-light">
                        <div className="container-fluid">
                            <div className="d-flex align-items-center">
                                <h4 className="navbar-text ml-5 pl-5 mt-1">
                                    {coursedetails.title}
                                </h4>
                            </div>
                            {degreeTestTwo >= 50 ? (
                                <Link to="/">
                                    <button className="navbar-btn">
                                        {i18next.t("lessons-nav-btn-1")}
                                    </button>
                                </Link>
                            ) : (
                                <a onClick={() => window.history.back()}>
                                    <button className="navbar-btn">
                                        {i18next.t("lessons-nav-btn-1")}
                                    </button>
                                </a>
                            )}
                        </div>
                    </nav>
                    <div className="row">
                        <div className="col-12 col-lg-9 order-video">
                            {/* Error messages */}
                            {(error || msgError) && (
                                <div
                                    className="error-alert bg-red-50 w-full p-4 rounded-lg flex flex-col items-center justify-center text-center mb-6">
                                    <p className="text-red-600 mb-2 text-lg font-semibold">{error || msgError}</p>
                                    <p className="text-gray-600 text-sm mb-3">
                                        لا تقلق! يمكننا حل هذه المشكلة 😊
                                    </p>
                                    <button
                                        onClick={handleRetry}
                                        disabled={loadingBtn}
                                        className="bg-custom-blue hover:bg-custom-blue-dark font-semibold px-6 py-2 rounded-full text-sm transition-colors duration-200 disabled:opacity-50"
                                    >
                                        {loadingBtn ? (
                                            <div className="spinner-border text-white" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            'إعادة المحاولة'
                                        )}
                                    </button>
                                </div>
                            )}

                            <div className="videoplay">
                                <h4 className="course-player-title">
                                    {allLessons[LessonActive]?.title}
                                </h4>
                                <div className={allLessons[LessonActive]?.content ? "d-none" : ""}>
                                    <VideoPlayer
                                        src={getVideoSource()}
                                    />
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: allLessons[LessonActive]?.content,
                                    }}
                                    className="my-3"
                                ></div>

                                <div className="btn-video-player">
                                    {allLessons.length == lengthCompleted && degreeTestTwo >= 50 ? (
                                        <Link
                                            to={{
                                                pathname: `/certificate/${idCourse}`,
                                                state: {
                                                    pathcourse: `${pathname}`,
                                                    title: `${coursedetails.title}`,
                                                },
                                            }}
                                        >
                                            <button className="btn-next">
                                                {i18next.t("lessons-btn-certificate")}
                                            </button>
                                        </Link>
                                    ) : allLessons[LessonActive]?.hasTest && degreeTestOne < 50 ? (
                                        <Link
                                            to={{
                                                pathname: `/midtest/${idCourse}`,
                                                state: {
                                                    idLisson: `${allLessons[LessonActive]?.id}`,
                                                    id: `${allTests[0]?.id}`,
                                                    pathcourse: `${pathname}`,
                                                },
                                            }}
                                        >
                                            <button className="btn-next">
                                                {i18next.t("lessons-btn-mid-test")}
                                            </button>
                                        </Link>
                                    ) : allLessons.length == lengthCompleted && degreeTestTwo < 50 ? (
                                        <Link
                                            to={{
                                                pathname: `/finaltest/${idCourse}`,
                                                state: {
                                                    id: `${allTests[1]?.id}`,
                                                    pathcourse: `${pathname}`,
                                                },
                                            }}
                                        >
                                            <button className="btn-next">
                                                {i18next.t("lessons-btn-final-test")}
                                            </button>
                                        </Link>
                                    ) : degreeTestTwo < 50 && LessonActive == lengthCompleted ? (
                                        <div className="d-flex justify-content-between w-100">
                                            <button
                                                className="btn-next"
                                                onClick={() => {
                                                    if (allLessons[LessonActive]?.id) {
                                                        markCompleteLisson(allLessons[LessonActive].id);
                                                    } else {
                                                        setMsgError("عذراً! هناك خطأ في تحديد الدرس");
                                                    }
                                                }}
                                                disabled={loadingBtn}
                                            >
                                                {loadingBtn ? (
                                                    <div className="spinner-border text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    i18next.t("lessons-btn-next")
                                                )}
                                            </button>
                                            <button
                                                className="btn-next"
                                                onClick={() => handlePreviousLesson()}
                                                disabled={!isValidLesson(LessonActive - 1) || loadingBtn}
                                            >
                                                {loadingBtn ? (
                                                    <div className="spinner-border text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    i18next.t("back-lesson")
                                                )}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-between w-100">
                                            <button
                                                className={`btn-next ${
                                                    lengthCompleted == LessonActive ? "d-none" : ""
                                                }`}
                                                onClick={() => handleNextLesson()}
                                                disabled={!isValidLesson(LessonActive + 1) || loadingBtn}
                                            >
                                                {loadingBtn ? (
                                                    <div className="spinner-border text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    i18next.t("next-lesson")
                                                )}
                                            </button>
                                            <button
                                                className={`btn-next ${LessonActive == 0 ? "disabled" : ""}`}
                                                onClick={() => handlePreviousLesson()}
                                                disabled={!isValidLesson(LessonActive - 1) || loadingBtn}
                                            >
                                                {loadingBtn ? (
                                                    <div className="spinner-border text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    i18next.t("back-lesson")
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="footer-mobile1">
                                <div className="nav-chat">
                                    <div className="tooltip2">
                                        <abbr
                                            className="tooltip2-abbr"
                                            data-title={i18next.t("chat-tooltip")}
                                        >
                                            <a
                                                href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn-whatsapp-19"
                                            >
                                                <img
                                                    src={lang == "en" ? chaten : chat}
                                                    alt="chat icon"
                                                />
                                            </a>
                                        </abbr>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 order-lessons">
                            <div className="courses-list">
                                <div className="courses-list-item accordion">
                                    <div className="card m-0">
                                        <div className="card-header" id="headingOne">
                                            <a className="btn-collapse">
                                                <h2 className="pl-5 mb-0">{i18next.t("lessons")}</h2>
                                            </a>
                                        </div>
                                        <div id="collapseOne" className="collapse show"></div>
                                        <div className="col-12 my-2">
                                            <div className="card-body">
                                                <ul>
                                                    {allLessons?.map((postleson, index) => {
                                                        return (
                                                            <>
                                                                <a
                                                                    onClick={() => {
                                                                        if (index === LessonActive || index <= lengthCompleted) {
                                                                            setActiveLessonSafely(index);
                                                                        }
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <li
                                                                        id={`lesson-list-item-${index}`}
                                                                        className={`lesson my-1 bg-list ${
                                                                            index == LessonActive
                                                                                ? "current active-list-item"
                                                                                : index == lengthCompleted
                                                                                    ? "current"
                                                                                    : index <= lengthCompleted
                                                                                        ? "has-competed"
                                                                                        : "disabled"
                                                                        }`}
                                                                        key={postleson.index}
                                                                    >
                                                                        <img src={img_video} alt="icon"/>
                                                                        {postleson.title}
                                                                        <div className="icon"></div>
                                                                    </li>
                                                                </a>
                                                                {postleson.hasTest ? (
                                                                    <li
                                                                        className={` m-0 bg-list-test ${
                                                                            allLessons[LessonActive]?.hasTest
                                                                                ? "current active-list-item"
                                                                                : index == lengthCompleted
                                                                                    ? "current"
                                                                                    : index <= lengthCompleted
                                                                                        ? "has-competed"
                                                                                        : "disabled"
                                                                        }`}
                                                                        key={`test-${index}`}
                                                                    >
                                                                        <Link
                                                                            to={
                                                                                allLessons[LessonActive]?.hasTest ||
                                                                                index <= lengthCompleted
                                                                                    ? {
                                                                                        pathname: `/midtest/${idCourse}`,
                                                                                        state: {
                                                                                            idLisson: `${allLessons[LessonActive]?.id}`,
                                                                                            id: `${postleson.testId}`,
                                                                                            pathcourse: `${pathname}`,
                                                                                        },
                                                                                    }
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <a className="lesson-test d-flex">
                                                                                <img src={img_mid} alt="icon"/>
                                                                                <h6>{i18next.t("mid-test-title")}</h6>
                                                                                <div className="icon"></div>
                                                                            </a>
                                                                        </Link>
                                                                    </li>
                                                                ) : null}
                                                            </>
                                                        );
                                                    })}
                                                    {allTests.map((postFinalTest, index) => {
                                                        if (postFinalTest.type === 2) {
                                                            return (
                                                                <li
                                                                    className={`m-0 bg-list-test ${
                                                                        degreeTestTwo >= 50
                                                                            ? "has-competed"
                                                                            : allLessons.length == lengthCompleted &&
                                                                            degreeTestTwo < 50
                                                                                ? "current active-list-item"
                                                                                : "disabled"
                                                                    }`}
                                                                    key={`final-test-${index}`}
                                                                >
                                                                    <Link
                                                                        to={
                                                                            degreeTestTwo >= 50 ||
                                                                            lengthCompleted >= allLessons.length
                                                                                ? {
                                                                                    pathname: `/finaltest/${idCourse}`,
                                                                                    state: {
                                                                                        id: `${postFinalTest.id}`,
                                                                                        pathcourse: `${pathname}`,
                                                                                    },
                                                                                }
                                                                                : null
                                                                        }
                                                                    >
                                                                        <a
                                                                            className="lesson-test d-flex"
                                                                            onClick={() => setIdLesson(postFinalTest.id)}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: "18px",
                                                                                    height: "18px",
                                                                                }}
                                                                                src={img_final}
                                                                                alt="icon"
                                                                            />
                                                                            <h6>{i18next.t("final-test-title")}</h6>
                                                                            <div className="icon"></div>
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <a
                                                        onClick={() => {
                                                            if (
                                                                idCourse == 33 ||
                                                                idCourse == 30 ||
                                                                idCourse == 31
                                                            ) {
                                                                if (
                                                                    degreeTestTwo >= 50 &&
                                                                    item[0]?.completedPercent >= 100 &&
                                                                    item[1]?.completedPercent >= 100 &&
                                                                    item[2]?.completedPercent >= 90
                                                                ) {
                                                                    setLessonActive(lengthCompleted);
                                                                }
                                                            } else if (degreeTestTwo >= 50) {
                                                                setLessonActive(lengthCompleted);
                                                            }
                                                        }}
                                                    >
                                                        <li
                                                            className={`my-1 lesson-test bg-list ${
                                                                degreeTestTwo >= 50 && allLessons.length == lengthCompleted
                                                                    ? "has-competed active-list-item"
                                                                    : degreeTestTwo > 50
                                                                        ? "has-competed"
                                                                        : "disabled"
                                                            }`}
                                                        >
                                                            <img src={img_cirtificate} alt="icon"/>
                                                            {i18next.t("lessons-certificate")}
                                                            <div className="icon"></div>
                                                        </li>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default CoursePlayer;