import React from "react";
import './OurTeam.scss'
import i18next from "i18next";
import img_1 from '../../assets/images/OurTeamImages/AliTaher.png'
import img_2 from '../../assets/images/OurTeamImages/MohammedKhalid.png'
import img_3 from '../../assets/images/OurTeamImages/AhmedDawood.png'
import img_4 from '../../assets/images/OurTeamImages/AhmedMajid.png'
import img_5 from '../../assets/images/OurTeamImages/FatimaJasim.png'
import img_6 from '../../assets/images/OurTeamImages/OlaAhmed.png'
import img_7 from '../../assets/images/OurTeamImages/MuntatherJabar.png'
import img_8 from '../../assets/images/OurTeamImages/IslamAhmed.png'
import img_9 from '../../assets/images/OurTeamImages/AyatEmad.png'
import img_10 from '../../assets/images/OurTeamImages/MinaMajed.png'
import img_11 from "../../assets/images/OurTeamImages/MustfaRamzi.png"
import img_12 from "../../assets/images/OurTeamImages/MustfaAhmed.png"
import img_13 from "../../assets/images/OurTeamImages/Howraa.png"




const OurTeam =()=>{
    return(
        <div>
            <h1 className="text-center mt-5 color-title fw-bold">{i18next.t("ourteam-title")}</h1>
            <div className="container ">
            <div className="row justify-content-center align-items-center mb-sm-5 mb-lg-0 ">
                <div className="image col-lg-4 col-md-6 col-sm-12">
                    <img src={img_1} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m1-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m1-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4 col-md-6 col-sm-12 ">
                    <img src={img_2} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m2-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m2-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4 col-md-6 col-sm-12">
                    <img src={img_3} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m3-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m3-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4 col-md-6 col-sm-12">
                    <img src={img_11} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m11-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m11-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4 col-md-6 col-sm-12">
                    <img src={img_4} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m4-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m4-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_7} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m5-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m5-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_12} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m12-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m12-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_5} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center">{i18next.t("our-team-m6-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m6-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img  src={img_6} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m7-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m7-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_8} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center ">{i18next.t("our-team-m8-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m8-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_9} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center">{i18next.t("our-team-m9-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m9-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_13} alt="" />
                    <div className="carsoul">
                    <h1 className="text-center">{i18next.t("our-team-m13-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m13-position")}</h2>
                    </div>
                </div>
                <div className="image col-lg-4  col-md-6 col-sm-12">
                    <img src={img_10} alt="" />
                      <div className="carsoul">
                    <h1 className="text-center">{i18next.t("our-team-m10-name")}</h1>
                    <h2 className="">{i18next.t("our-team-m10-position")}</h2>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    )
} 
export default OurTeam ;