import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./ProjectInfo.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Rating } from "react-simple-star-rating";
import Card from "../../components/Card/Card";
import axios from "axios";
import { useRecoilState } from "recoil";
import { projectState, userState } from "../../atom";
import i18next from "i18next";
import Swal from "sweetalert2";
import ShareProject from "../../components/ShareProject/ShareProject";
import StartRating from "../../components/StarRating";
import LoginModel from "../../components/LoginModel/LoginModel";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import CardSub from "../../components/CardSub/CardSub";

const idUser = localStorage.id;

const ProjectInfo = () => {
  let { id } = useParams();
  let history = useHistory();
  const [project] = useRecoilState(projectState);
  const [user, setUser] = useRecoilState(userState);
  const [video, setVideo] = useState(project?.video);
  const [title, setTitle] = useState(project?.name);
  const [status, setStatus] = useState(project?.status);
  const [authorID, setAuthorID] = useState(project?.authorID);
  const [courses, setCourses] = useState([]);
  const [desc, setDesc] = useState(null);
  const [userRate, setUserRate] = useState(0);
  const [projectRate, setProjectRate] = useState(0);

  const [author, setAuthor] = useState(null);

  //project.videoURL.split("/")[2]

  const handleRate = (val) => {
    axios
      .post(`/api/ProjectRating`, {
        projectId: Number(id),
        userId: idUser,
        rate: val,
      })
      .then((res) => {
        Swal.fire(i18next.t("create-projects-msg-success"), "", "success");
        getData()
      })
      .catch((e) =>
        Swal.fire(i18next.t("create-projects-msg-error"), "", "error")
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: i18next.t("projects-info-confirm-msg"),
      showCancelButton: true,
      confirmButtonText: i18next.t("projects-info-confirm"),
      confirmButtonColor: "#dc3545",
      cancelButtonText: i18next.t("projects-info-cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/UserProject/${id}`)
          .then((res) => {
            Swal.fire(i18next.t("create-projects-msg-success"), "", "success");
            history.goBack();
          })
          .catch((e) =>
            Swal.fire(i18next.t("create-projects-msg-error"), "", "error")
          );
      }
    });
  };

  const validateInit = (_authorID) => {
    if (status === "Pending" && idUser !== _authorID) {
      history.goBack();
    }
  };

  const getData =() =>{
    axios.get(`/api/UserProject/${id}?UserId=${idUser}`).then((res) => {
     
      setVideo(res?.data?.videoURL?.split("/")[2]);
      setCourses(
        res?.data?.recCourses.filter(
          (el) => el?.courses?.lang === i18next.language.toUpperCase()
        )
      );
      setTitle(
        i18next.language === "ar"
          ? res?.data?.projectName
          : res?.data?.projectNameEN
      );
      setDesc(
        i18next.language === "ar"
          ? res?.data?.projectDescription
          : res?.data?.projectDescriptionEN
      );
      setUserRate(res?.data?.userRate);
      setProjectRate(res?.data?.projectRate);
      setAuthor(res?.data?.userFullName);
      setAuthorID(res?.data?.userId);
      validateInit(res?.data?.userId);
    });
  }

  useEffect(() => {
    
    if (id) {
      getData()
    }
  }, [id]);

  return (
    <div className="bg bg-comp">
    <div className="container">
      <div className="project-info">
        <div className="video-container" onClick={window.scrollTo(0,0)}>
          {video && <Vimeo id="projectVideo" responsive={true} video={video} />}
        </div>
        <section className="info">
          <div className="detials">
            <h5>{title}</h5>
            <p>{desc}</p>
            <div className="creator">
              <small>{i18next.t("projects-info-created-by")}</small>
              <b>{author}</b>
            </div>
          </div>

          <div className="action">
            {idUser && idUser === authorID ? (
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => history.push(`/myprojects/update/${id}`)}
                >
                  {i18next.t("projects-info-edit")}
                </button>
                <div style={{ width: 10 }} />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  {i18next.t("projects-info-delete")}
                </button>
              </div>
            ) : (
              <div>
                {userRate ? (
                  <>
                    <small>{i18next.t("projects-info-rate-user")}</small>
                    <StartRating rating={userRate} />
                  </>
                ) : (
                  <>
                    {idUser ? (
                      <>
                        <StartRating onChange={handleRate} />
                        <small>{i18next.t("projects-info-rate")}</small>
                      </>
                    ) : (
                      <a
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                        href="#"
                      >
                        <StartRating />
                        <small>{i18next.t("projects-info-rate")}</small>
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </section>

        {courses.length > 0 && idUser !== authorID && (
          <section className="courses">
            <h5>{i18next.t("projects-info-related")}</h5>
            <p>{i18next.t("projects-info-related-sub")}</p>
            <div className="row">
              {courses?.map((el) => (
                <div className="col-12 col-md-6 col-lg-4 col-xxl-4">
                {user ?
                  <CardSub
                    user={idUser}
                    name={el?.courses?.title}
                    price={el?.courses?.priceIQD}
                    image={el?.courses?.ref03}
                    description={el?.courses?.description}
                    //  onClick={this.update(el?.courses?.id)}
                    path={{
                      // pathname: `/course/${el?.courses?.id}`,
                      pathname: `/course/${el?.courses?.id}`,
                      state: {
                        id: el?.courses?.id,
                      },
                    }}
                    pathId={el?.courses?.id}
                  />
                  :
                  <Card
                    user={idUser}
                    name={el?.courses?.title}
                    price={el?.courses?.priceIQD}
                    image={el?.courses?.ref03}
                    description={el?.courses?.description}
                    //  onClick={this.update(el?.courses?.id)}
                    path={{
                      // pathname: `/course/${el?.courses?.id}`,
                      pathname: `/course/${el?.courses?.id}`,
                      state: {
                        id: el?.courses?.id,
                      },
                    }}
                    pathId={el?.courses?.id}
                  />
                }
                </div>
              ))}
            </div>
          </section>
        )}
        <div className="mt-4">
          {idUser === authorID && status === "Approved" ? (
            <ShareProject
              facebook={`http://www.facebook.com//share.php?u=https://iotkidsiq.com/projects/${id}/`}
              whatsapp={`https://api.whatsapp.com/send?text=هل يمكنك تقييم مشروعي https://iotkidsiq.com/projects/${id}/ `}
              telegram={`https://telegram.me/share/url?url=https://iotkidsiq.com/projects/${id}/&text=هل يمكنك تقييم مشروعي`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <LoginModel />
      <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
    </div>
  );
};

export default ProjectInfo;
