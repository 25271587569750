import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "../../components/Button/Button";
// import '../joinOurTeam.scss'
import "../JoinOurTeam/JoinOurTeam.scss"

import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import i18next from "i18next";

const Jobdetails = () => {
  return (
    <div className="bg">
    <div className="container" onLoad={window.scrollTo(0,0)}>
      <div className="job-details">
        <div className="container">
        <h1 className="privacy-policy-title">{i18next.t("job-details-title")}</h1>
          <div className="row my-5">
            {/* start text */}
            <div className="col-12">
              <p className="paragraph-job-details-1">{i18next.t("job-details-desc")}</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="job-details-title-1">{i18next.t("job-details-title-2")}</h2>
              <p className="paragraph-job-details-1">
                1-{i18next.t("resp-1")}<br></br>
                2-{i18next.t("resp-2")}<br></br>
                3-{i18next.t("resp-3")}<br></br>
                4-{i18next.t("resp-4")}<br></br>
                5-{i18next.t("resp-5")}<br></br>
                6-{i18next.t("resp-6")}<br></br>
                7-{i18next.t("resp-7")}
              </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="job-details-title-1">{i18next.t("job-details-title-3")}</h2>
              <p className="paragraph-job-details-1">
                1-{i18next.t("req-1")}<br></br>
                2-{i18next.t("req-2")}<br></br>
                3-{i18next.t("req-3")}<br></br>
                4-{i18next.t("req-4")}<br></br>
                5-{i18next.t("req-5")}<br></br>
                6-{i18next.t("req-6")}<br></br>
                7-{i18next.t("req-7")}<br></br>
                8-{i18next.t("req-8")}<br></br>
             
             
              </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="job-details-title-1">{i18next.t("job-details-title-4")}</h2>
              <p className="paragraph-job-details-1">
                1-{i18next.t("benf-1")}<br></br>
                2-{i18next.t("benf-2")}<br></br>
                3-{i18next.t("benf-3")}<br></br>
                4-{i18next.t("benf-4")}<br></br>
               
             
             
              </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
            <p className="paragraph-job-details-1">
              {i18next.t("end-job-details")}
              </p>
              <h2 className="job-details-title-1">{i18next.t("email-to-apply")} : ahmed.salman@iotkidsiq.com</h2>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
  );
};

export default Jobdetails;