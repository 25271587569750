import React from "react";
import "./CardProgress.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "../Button/Button";
import Dialoge from "../Dialoge/Dialoge";
import "./CardProgress";
import $ from "jquery";
import i18next from "i18next";
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import downloadIcon from "../../assets/images/courses/download-c.png"
/* import ReactDOM from 'react-dom'; */

const CardProgress = (props) => {
  const certshow = (percentage, title, id) => {
    return (

      <div className={`${props.iqquestion== `true` ?"d-none":""}`}
      >
        <div className={`card-top-progress ${props.percentage > 100 ? "d-none": ""}`}>
          <CircularProgressbarWithChildren value={props.percentage} styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '26px',
            pathTransitionDuration: 0.5,
            pathColor: `rgba(241, 112, 170, ${100})`,
            textColor: '#f88',
            trailColor: '#d6d6d6',
            backgroundColor: '#3e98c7',
          })}>
            <div style={{ fontSize: 14, marginTop: 5 }}>
              {
               props.iqquestion== 'true' ? null :
                props?.path?.state?.courseId == 30 || props?.path?.state?.courseId == 31 || props?.path?.state?.courseId == 32 || props?.path?.state?.courseId == 33 ?
                  <strong>{props.percentage}%</strong>
                  : percentage == 100 ?
                    <Link
                      to={{
                        pathname: `/certificate/${id}`,
                        state: {
                          title: `${title}`,
                        },
                      }}
                    >
                      <img src={downloadIcon} alt="download" />
                    </Link>
                    :
                    <strong>{props.percentage}%</strong>
              }
            </div>
            <span style={{ fontSize: 16, marginTop: 5, fontWeight: 700 }}>{props.text}</span>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  };

  const btnshow = (percentage) => {
    if (percentage == 0) {
      return (
        <div className="mx-2">
          <Button name={i18next.t("btn-start-course")} />
        </div>
      );
    }
    else if (props.iqquestion==`true`) {
      return(
        <Button name="تنزيل الاسئلة" />
      )
    } 
    else  {
      return (
        <div className="mx-2 ">
          <Button name={i18next.t("my-courses-card-btn")} />
        </div>
      );
     
    }
    
  };
  return (
    <div>
      <div>
        <div className="card">
          {certshow(props.percentage, props.name, props.id)}
          <Link to={props.path}>
            <img
              src={props.image}
              className="card-img-progress"
              alt="Image Corse"
            />
            <div className="card-body">
              <p className="card-text ">{props.name}</p>

              <div className="">
                {btnshow(props.percentage)}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

$(".card").hover(function () {
  $(".dialoge-card").removeClass("d-none");
});
export default CardProgress;
