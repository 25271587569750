import React from "react";

import "./style.scss";
import Button from "../Button/Button";

const QtyBtn = ({ qty, onChange,color }) => {
  return (
    <div className="qty-btn">
      <button className={`btn-c-price text-${color}`} onClick={() => onChange(qty + 1)} name="+" ><h6>+</h6></button>
      <span className={`fw-bold text-${color} span-text`}>{qty==0?0:qty}</span>
      <button className={`btn-c-price text-${color}  ${qty==0?"pointer-no-drop":""}`} disabled={qty == 0} onClick={() => onChange(qty - 1)} name="-" ><h6>-</h6></button>
    </div>
  );
};

export default QtyBtn;
