import React, { useState, useEffect } from "react";
import img_star_1 from "../../assets/images/home/star-1.png";
import img_star_2 from "../../assets/images/home/star-2.png";
import "./style.scss";

let initValues = [1, 2, 3, 4, 5].map((el) => {
  return {
    key: el,
    value: false,
  };
});

const idUser = localStorage.id;

const StartRating = ({ onChange, rating = 0 }) => {
  const [value, setValue] = useState(initValues);

  useEffect(() => {
    if (rating) {
      let rate = Math.round(rating);
      let newList = value.map((el) => {
        return {
          key: el.key,
          value: rate >= el.key ? true : false,
        };
      });
      setValue(newList);
      
    }
  }, [rating]);

  return (
    <>
      <p className="card-price mx-2">
        <div className="stars" style={{ direction: "rtl" }}>
          {value.map((el, index) =>
            el.value ? (
              <img
                onClick={() => onChange(el.key)}
                key={index}
                className="star-1"
                src={img_star_1}
                alt="star"
              />
            ) : (
              <img
                onClick={() => onChange(el.key)}
                key={index}
                className="star-2"
                src={img_star_2}
                alt="star"
              />
            )
          )}
        </div>
      </p>
     
    </>
  );
};

export default StartRating;
