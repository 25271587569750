import React, { Component } from "react";
import img_video from "/src/assets/images/courses/play-icon.png";
import "./CoursesList.scss";

//import ReactDOM from 'react-dom';
const CoursesList = (props) => {
  return (
    // this is component lesson
    <li className="my-1 px-0 bg-list" id={`bg-list${props.id}`}>
      <img src={img_video} alt="icon" />
      <h6>{props.title}</h6>
    </li>
  );
};

export default CoursesList;
