import React from "react";

import "./style.scss";
import QtyBtn from "../../QtyBtn";
import { basketState } from "../../../atom";
import { useRecoilState } from "recoil";
import i18next from "i18next"


const CartItem = ({ id, name, qty, price, endprice, image }) => {
  const [basket, setBasket] = useRecoilState(basketState);

  const handleBasket = (_qty) => {
    let item = { id, name, price, endprice, image };
    let basketItem = basket.find((el) => el.id === id);
    let basketItemIndex = basket.findIndex((el) => el.id === id);
    let newBasket = [...basket];
    if (basketItem) {
      if (_qty === 0) {
        newBasket = basket.filter((el) => el.id !== id);
      } else {
        newBasket[basketItemIndex] = { ...item, qty: _qty };
      }
    } else {
      item = { ...item, qty: _qty };
      newBasket.push(item);
    }
    setBasket(newBasket);
    localStorage.setItem("iot_basket", JSON.stringify(newBasket));
  };

  return (
    <div className="cart-item">
      <div className="info">
        <img src={image} />
        <div className="detials">
          <b>{name}</b>
          {price === endprice ? (
            <p>
              {Number(endprice * qty).toLocaleString()} <span>{i18next.t("card-currency")}</span>
            </p>
          ) : (
            <>
              <small>
                {Number(price * qty).toLocaleString()} <span>{i18next.t("card-currency")}</span>
              </small>
              <p>
                {Number(endprice * qty).toLocaleString()} <span>{i18next.t("card-currency")}</span>
              </p>
            </>
          )}
        </div>
      </div>
      <QtyBtn qty={qty || 0} color={'black'} onChange={(val) => handleBasket(val)} />
     
    </div>
  );
};

export default CartItem;
