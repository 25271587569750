import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "./PayFail.scss";
import i18next from "i18next";
import Button from "../../components/Button/Button";
import img_fail from "../../assets/images/charecter/boycry.png"
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

class PayFail extends Component {
render(){
    return (
      <div className="bg bg-login">
      <div className="pay m-0 p-0">
        <div className="nav-item">
        
            <button onClick={() => history.back(-1)} className="navbar-btn">
              X
            </button>
        
        </div>
        <div className="iqtest-item-title">
          <h2 className="my-4">{i18next.t('payFail-title')}</h2>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="paynow">
                <div className="pay-with">
                  <img className="d-flex justify-content-center mx-auto mb-4 mt-1" src={img_fail} alt="image" />
                  <div className="paynow-top">
                    <h4>{i18next.t("payFail-Stateus")}</h4>
                  </div>
                  <hr className="my-4" />
                  <h4>{i18next.t("payFail-goto")}</h4>
                  <div className="paynow-center">
                    {/* <div className="m-2">
                      <Button name={i18next.t("payFail-goto-course")} />
                    </div> */}
                    <div className="m-2">
                     
                      <Button onClick={()=> history.back(-1)} name={i18next.t("payFail-goto-courses")} />
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dialog */}
        <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
      </div>
      </div>
    );
  };
}
export default PayFail;
