import React, { Component } from 'react';
import './Button.scss';

//import ReactDOM from 'react-dom';
const Button = (props)=>{
return(
<div className="btn-all">
    <button onClick={props.onClick} className={`btn-c ${props.color}`} style={{opacity: props.opacity}}><h6>{props.name}</h6></button>
</div>
);
}

export default Button;