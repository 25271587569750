import React, { Component } from "react";
import "./CardCvStudent.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
import { cvImageState } from "../../atom";
import { useRecoilState } from "recoil";
import CvShowModal from "../CvShowModal/CvShowModal";
//import ReactDOM from 'react-dom';
const CardCvStudent = (props) => {
  const [imgCv, setImgCv] = useRecoilState(cvImageState);

  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className="card-cv" data-bs-toggle="modal"
          data-bs-target="#cvShowModal" onClick={() => setImgCv(props.image)}
        >
          <img src={props.image} className="card-img-top" alt="CV" />
          <div className="card-cv-body">
            {/* <a data-bs-toggle="modal"
              data-bs-target="#cvShowModal"
              className="btn-show"
              onClick={() => setImgCv(props.image)}
            >
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 936q-24 0-42-18t-18-42V704h60v172h172v60H180Zm428 0v-60h172V704h60v172q0 24-18 42t-42 18H608ZM120 448V276q0-24 18-42t42-18h172v60H180v172h-60Zm660 0V276H608v-60h172q24 0 42 18t18 42v172h-60Z" /></svg>
              <p>{i18next.t("cvShow-zoom")}</p>
            </a> */}
            {props.path_1 &&
              <Link to={props.path_1} className="btn-project">
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 896q-24 0-42-18t-18-42V236q0-24 18-42t42-18h600q24 0 42 18t18 42v247q-14-6-29-11t-31-8V236H180v404h170q19 29 43.5 50t54.5 29q-2 16-1 31t3 30q-37-6-70-26.5T326 700H180v136h284q6 16 13.5 31t17.5 29H180Zm0-60h284-284Zm517 100-5-48q-23-7-42-18.5T617 845l-42 20-35-54 38-30q-5-19-5-41.5t5-41.5l-38-30 35-55 42 20q14-12 33-23.5t42-18.5l5-49h60l6 49q23 7 42 18.5t33 23.5l42-20 35 55-38 30q5 19 5 41.5t-5 41.5l38 30-35 54-42-20q-14 13-33 24.5T763 888l-6 48h-60Zm30-95q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z" /></svg>
                <p>المشروع الاول</p>
              </Link>
            }
            {props.path_2 &&
              <Link to={props.path_2} className="btn-project">
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 896q-24 0-42-18t-18-42V236q0-24 18-42t42-18h600q24 0 42 18t18 42v247q-14-6-29-11t-31-8V236H180v404h170q19 29 43.5 50t54.5 29q-2 16-1 31t3 30q-37-6-70-26.5T326 700H180v136h284q6 16 13.5 31t17.5 29H180Zm0-60h284-284Zm517 100-5-48q-23-7-42-18.5T617 845l-42 20-35-54 38-30q-5-19-5-41.5t5-41.5l-38-30 35-55 42 20q14-12 33-23.5t42-18.5l5-49h60l6 49q23 7 42 18.5t33 23.5l42-20 35 55-38 30q5 19 5 41.5t-5 41.5l38 30-35 54-42-20q-14 13-33 24.5T763 888l-6 48h-60Zm30-95q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z" /></svg>
                <p>المشروع الثاني</p>
              </Link>
            }
          </div>
        </div>
      </div>
      <CvShowModal />
    </div>
  );
};
export default CardCvStudent;
