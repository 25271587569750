import React from "react";
import Button from "../../components/Button/Button";
import "./NotFound.scss";
import { BrowserRouter as BrowserRouter , Switch , Link } from "react-router-dom";
import i18next from "i18next";
import img_404 from '../../assets/images/charecter/boythink.png'
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
const NotFound = () => {
  return (
    <div className="container">
      <div className="notfound">
        <div className="container">
          <div className="row my-5">
            <div className="col-12">
              <div className="notfound-top">
                <img src={img_404} alt="404" />
                <h1>{i18next.t("404-title")}</h1>
                <p>{i18next.t("404-des")}</p>
                <Link to="/">
                  <div className="notfound-btn">
                    <Button name={i18next.t("404-btn")} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
  );
};

export default NotFound;
