import React, { Component } from "react";
import "./CardOpenCompetitions.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
//import ReactDOM from 'react-dom';
const CardOpenCompetitions = (props) => {
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className="card-open-competitions">
          <Link
            className="card-price-btn w-100"
            type="button"
            to={props.link}
          >
            <img src={props.image} className="card-img-top" alt="Image Corse" />
            <div className="card-body">
              <p className="card-text">{props.name}</p>
              <p className="card-price mx-2">{props.price}</p>
              <div className="m-2"></div>
            </div>
            <div className="content py-3 px-2">
              <p className="card-text my-3">{props.name}</p>
              <p className="card-des my-3">{props.description}</p>
              <p className="card-price-hover my-3 mx-2">{props.price}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardOpenCompetitions;
