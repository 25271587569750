import React, { useEffect, useState } from "react";
import "./Switch.scss";
import axios from "axios";
import { useRecoilState } from "recoil";
import { coursesIdSwithState } from "../../atom";

// Switch Card style
const paymentWidgetStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "9999",
  width: "100%",
};

const Switch = ({ courseId, orderNo, couponID, paymentMethod, priceIQD , refCode}) => {
  const [checkoutId, setCheckoutId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [coursesIdSwith , setCoursesIdSwith] = useRecoilState(coursesIdSwithState)

  // For local development
  const BaseURL =
    window.location.hostname === "localhost"
      ? `http://${window.location.hostname}:3000`
      : `https://${window.location.hostname}`;

  // const BaseURL = "https://iotkidsiq.com";

  // Get checkout id
  // #Step 1
  const getPaymentId = () => {
    const userId = localStorage.id;
    axios
      .get(`/api/Switch/PrepareCheckOut?Amount=${priceIQD}&Currency=IQD&UserId=${userId}`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        eval(data);
        if (data) {
          let KEY_WORD = "wpwl.ndc = ";
          let KEY_WORD_LENGTH = KEY_WORD.length;
          let index = data.search(KEY_WORD);
          if (index !== -1) {
            let checkoutId = data.substring(
              index + KEY_WORD_LENGTH + 1,
              index + KEY_WORD_LENGTH + 48
            );
            localStorage.setItem("checkoutId", checkoutId);
            setCheckoutId(checkoutId);
            setShowModal(true);
          }
        }
      });
  };

  // useEffect(() => {
  //   if (checkoutId) {
  //     const script = document.createElement("script");
  //     script.src = `https://ahmeddawood88-003-site1.etempurl.com/api/Switch/PrepareCheckOut?Amount=${priceIQD}`;
  //     document.body.appendChild(script);
  //   }
  // }, [checkoutId]);

  // show switch card modal
  return (
    <>
      {showModal ? (
        <div className="form paymentWidgetsCustom" style={paymentWidgetStyles}>
          <a>close</a>
          <form
            style={paymentWidgetStyles}
            action={`${BaseURL}/processPayment?courseId=${courseId}&couponId=${couponID}&checkoutId=${checkoutId}&refCode=${refCode}`}
            className="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </div>
      ) : null}
      <button onClick={() => getPaymentId()}></button>
    </>
  );
};
export default Switch;
