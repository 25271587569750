// src/pages/Login/Login.jsx
import React, { useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userState } from "../../atom";
import i18next from "i18next";
import GoogleAuth from "../../components/GoogleAuth/GoogleAuth";
import { apiPostLogin } from "../../services/api/Login";

const Login = ({ btnGroup }) => {
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const setUser = useSetRecoilState(userState);
    const history = useHistory();

    const postLogin = useCallback(async () => {
        const dataUser = { email, password };
        const lang = localStorage.getItem("lang");
        setIsLoading(true);
        try {
            const data = await apiPostLogin(dataUser);
            if (data && data.jwtToken) {
                localStorage.setItem("token", data.jwtToken);
                localStorage.setItem("id", data.id);
                localStorage.setItem("iot_user", JSON.stringify(data));
                setUser(data);
                setTimeout(() => {
                    window.location.href = "/";  // Use direct navigation instead of history
                }, 100);
            } else {
                setMessage(lang === "ar" ? "البريد الإلكتروني أو كلمة المرور غير صحيحة" : "Email or password is incorrect");
            }
        } catch (error) {
            console.error("Login error:", error);
            setMessage(lang === "ar" ? "حدث خطأ أثناء تسجيل الدخول" : "An error occurred during login");
        } finally {
            setIsLoading(false);
        }
    }, [email, password, setUser, history]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setMessage("");
        postLogin();
    }, [postLogin]);

    return (
        <div className="w-full max-w-md mx-auto">
            <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-3xl px-8 pt-6 pb-8 mb-4">
                <div className="my-4">
                    <h1 className="md:text-3xl text-2xl font-bold text-center text-gray-700 mb-4">{i18next.t("login-title")}</h1>
                    {btnGroup}
                    {message && <p className="text-red-500 text-xs italic mb-2">{message}</p>}
                    <input
                        type="email"
                        className="appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={i18next.t("login-input-email")}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                </div>
                <div className="mb-6 relative">
                    <input
                        type={showPassword ? "text" : "password"}
                        className="appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={i18next.t("login-input-password")}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                    />
                    <button
                        type="button"
                        className="absolute left-0 top-4 pl-3 flex items-center"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <svg className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                            </svg>
                        ) : (
                            <svg className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        )}
                    </button>
                </div>
                <div className="mb-6 text-center">
                    <Link to="/sendEmail" className="text-blue-500 hover:text-blue-800 text-sm">
                        {i18next.t("login-btn-forget-password")}
                    </Link>
                </div>
                <div className="flex flex-col items-center justify-between">
                    <button
                        className={`w-full bg-custom-yellow hover:bg-custom-yellow-dark text-zinc-700 font-bold py-3 px-4 rounded-full focus:outline-none focus:shadow-outline ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : i18next.t("login-btn-login")}
                    </button>
                    <div className="mt-4 w-full">
                        <GoogleAuth />
                    </div>
                </div>
            </form>
            <p className="text-center hover:text-custom-blue text-custom-blue text-base">
                <Link to="/register" className="hover:text-custom-blue">
                    {i18next.t("login-des")} {i18next.t("login-btn-rigester")}
                </Link>
            </p>
        </div>
    );
};

export default Login;