import React, {useEffect, useState} from "react";
import {BrowserRouter as BrowserRouter, Link} from "react-router-dom";
import Button from "../../components/Button/Button";
import "./Home.scss";
import Why from "../../components/Why/Why";
import i18next from "i18next";
import CardIQTest from "../../components/CardIQTest/CardIQTest";
import CardSub from "../../components/CardSub/CardSub";
import Card from "../../components/Card/Card";
import LoginModel from "../../components/LoginModel/LoginModel";
import CardVideo from "../../components/CardVideo/CardVideo";
import Lottie from "lottie-web";
import anim_play from '../../assets/images/anim-play.json';
import {closeVideoState, itemAllCourseState, selectedVideoState} from "../../atom";
import {useRecoilState} from "recoil";
import StartCreatingModal from "../../components/StartCreatingModal/StartCreatingModal";
import VideoModal from "../../components/VideoModal/VideoModal";
import {BgHome} from "../../components/BgHome/BgHome";
import OverviewIqCourseModel from "../../components/OverviewIqCourseModel/OverviewIqCourseModel";
import GiftModel from "../../components/GiftModel/GiftModel";
import {apiGetCourse} from "../../services/api/generalApi";
import {apiGetAllProjectsInHome} from "../../services/api/homeApi";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import CardCvStudent from "../../components/CardCvStudent/CardCvStudent";
import img_cv_1 from "../../assets/images/cv/1.png";
import img_cv_2 from "../../assets/images/cv/2.png";
import img_cv_3 from "../../assets/images/cv/3.png";
import img_cv_4 from "../../assets/images/cv/4.png";
import img_cv_5 from "../../assets/images/cv/5.png";
import CardCompetitions from "../../components/CardCompetitions/CardCompetitions";
import data_ar from "../../assets/json/competitions_ar.json";
import data_en from "../../assets/json/competitions_en.json";
import img_c from "../../assets/images/c-img.png";
import img_c_2022 from "../../assets/images/codev2022.png";
import OpenCompetitionsModal from "../../components/OpenCompetitionsModal/OpenCompetitionsModal";
import CardOpenCompetitions from "../../components/CardOpenCompetitions/CardOpenCompetitions";


const Home = (props) => {
    // state to save data form api
    const [item, setItem] = useRecoilState(itemAllCourseState)
    const [copoun, setCopoun] = useState("halloween2022");
    const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
    const [itemProjects, setItemProjects] = useState([]);
    const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);
    const [compdata, setcompdata] = useState([])
    const lang = localStorage.getItem("i18nextLng");
    const userId = localStorage.id;
    const langUpperCase = localStorage.i18nextLng.toUpperCase();

    // api from service
    const GetCourse = async () => {
        const data = await apiGetCourse(langUpperCase, userId);
        setItem(data);
    }
    const getcompetitons = () => {
        const userId = localStorage.id;
        const language = localStorage.getItem("i18nextLng");
        if (language === "ar") {
            const item = data_ar.dataIndex;
            setcompdata(item)

        } else if (language === "en") {
            const item = data_en.dataIndex;
            setcompdata(item)
        }
    }
    const GetAllProjectsInHome = async () => {
        const data = await apiGetAllProjectsInHome(langUpperCase);
        setItemProjects(data);
    }

    // state to save data form api
    useEffect(() => {
        GetCourse();
        GetAllProjectsInHome();
        getcompetitons();
    }, []);

    // animation
    useEffect(() => {
        Lottie.loadAnimation({
            container: document.getElementById('play-icon'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: anim_play
        })
    }, []);

    return (
        <div className="bg">
            <BgHome/>
            <div id="content" className="container ">
                {/* <GiftModel /> */}
                <div className="home">
                    <div className="img-bg">
                    </div>
                </div>
                <div className="home-center">
                    {/*  <div className="row">
            <h2 className="home-center-title">{i18next.t("home-s2-title")}</h2>
            <div className="courses">
              <div className="container my-4">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                    <CardIQTest
                      link={"/iqtestlevels"}
                      user={props.user}
                      name={i18next.t("iqtest-title")}
                      price={i18next.t("iqtest-price")}
                      image="https://messarat.com/images/course%20images/rsz_1iq_test.jpg"
                      description={i18next.t("iqtest-des")}
                    />
                  </div>
                  {item.map((postDetails, index) => {
                    if (
                      postDetails.isFeatured === true &&
                      postDetails.lang === `${langUpperCase}`
                    ) {
                      return (
                        <div
                          className="col-12 col-md-6 col-lg-4 col-xxl-3"
                          key={index}
                        >
                          {postDetails.ref02 === 1 ? (
                            <>
                              {
                                postDetails.id === 32 ? (
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#overview-iq-courseModal"
                                    href=""
                                  >
                                    <CardSub
                                      name={postDetails.title}
                                      price={postDetails.priceIQD.toLocaleString()}
                                      image={postDetails.ref03}
                                      description={postDetails.description}
                                      path={{
                                        pathname: `/course/${postDetails.id}`,
                                        state: {
                                          id: postDetails.id,
                                          ref02: postDetails.ref02,
                                        },
                                      }}
                                      pathname={postDetails.id}
                                      pathId={postDetails.id}
                                    />
                                  </a>
                                ) : (
                                  <CardSub
                                    name={postDetails.title}
                                    price={postDetails.priceIQD.toLocaleString()}
                                    image={postDetails.ref03}
                                    description={postDetails.description}
                                    path={{
                                      pathname: `/course/${postDetails.id}`,
                                      state: {
                                        id: postDetails.id,
                                        ref02: postDetails.ref02,
                                      },
                                    }}
                                    pathname={postDetails.id}
                                    pathId={postDetails.id}
                                  />
                                )
                              }
                            </>
                          ) : (
                            <Card
                              user={props.user}
                              name={postDetails.title}
                              price={postDetails.priceIQD.toLocaleString()}
                              image={postDetails.ref03}
                              description={postDetails.description}
                              //  onClick={this.update(postDetails.id)}
                              path={{
                                // pathname: `/course/${postDetails.id}`,
                                pathname: `/course/${postDetails.id}`,
                                state: {
                                  id: postDetails.id,
                                },
                              }}
                              pathId={postDetails.id}
                            />
                          )
                          }
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="row justify-content-center" style={{ maxWidth: 520, margin: 'auto' }}>
              <Link className="btn-featured-courses" to="/Messarat/1">
                <Button name={i18next.t("home-s2-btn")} />
              </Link>
              <a data-bs-toggle="modal"
                data-bs-target="#videoModal"
                className="btn-featured-courses"
                href="#" onClick={() => setSelectedVideo(813950359) + setCloseVideo(false)}>
                <Button name={i18next.t("bg-home-btn")} />
              </a>
            </div>
          </div> */}
                    <div className="courses bg " onLoad={window.scrollTo(0, 0)}>
                        <div className="container my-4">
                            <div className="row justify-content-center">
                                <div className="col-12 ">
                                    <h2 className="home-center-title my-5">{i18next.t("competitions-title")}</h2>

                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <CardOpenCompetitions
                                        link={"/competitions/1000coders"}
                                        name={i18next.t("c-title")}
                                        price={i18next.t("competitions-finsh")}
                                        image={img_c}
                                        description={i18next.t("c-des")}
                                    />
                                </div>
                                {compdata.map((postDetails, index) => (
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3" key={index}>
                                        <CardCompetitions
                                            name={postDetails.title}
                                            price={postDetails.ranking}
                                            image={postDetails.image}
                                            description={postDetails.description}
                                            pathId={postDetails.id}
                                            //  onClick={this.update(postDetails.id)}
                                            path={{
                                                pathname: `/competition/${postDetails.id}`,
                                                state: {
                                                    id: postDetails.id,
                                                },
                                            }}
                                        />
                                    </div>
                                ))}
                                <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <CardOpenCompetitions
                                        link={"/competitions/competition-codeavour-2022"}
                                        name={i18next.t("competition-codeavour-2022-title")}
                                        price={i18next.t("competitions-finsh")}
                                        image={img_c_2022}
                                        description={i18next.t("competition-codeavour-2022-des")}
                                    />
                                </div>
                            </div>
                            <OpenCompetitionsModal/>
                        </div>
                        <div className="footer-mobile-desktop">
                            {/*<div className="nav-chat">*/}
                            {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"*/}
                            {/*       className="btn-whatsapp-19">*/}
                            {/*        <img src={img_whatsapp} alt="icon"/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="home-center">
                    <div className="row">
                        <h2 className="home-center-title mt-4 mb-1">{i18next.t("home-s4-title")}</h2>
                        <div className="courses">
                            <div className="container my-4">
                                <div className="row">
                                    {Array.isArray(itemProjects) && itemProjects.map((project, index) => (
                                        <div
                                            className="col-12 col-md-6 col-lg-4 col-xxl-3"
                                            key={index}
                                            onClick={() => {
                                                setSelectedVideo(project.videoURL.split("/")[2]);
                                                setCloseVideo(false);
                                            }}
                                        >
                                            <CardVideo
                                                name={lang === "en" ? project.projectNameEN : project.projectName}
                                                price={project.noOfRates}
                                                image={project.ref03}
                                                description={project.description}
                                                rating={4}
                                                video={project.videoURL}
                                            />
                                        </div>
                                    ))}                </div>
                            </div>
                        </div>
                        <div className="row" style={{maxWidth: 520, margin: 'auto'}}>
                            <a className="col-lg-3col-md-6 col-12 btn-featured-courses" type="button"
                               data-bs-toggle="modal"
                               data-bs-target="#start-creating-Modal"
                               href="#">
                                <Button name={i18next.t("home-s4-btn")}/>
                            </a>
                            <a data-bs-toggle="modal"
                               data-bs-target="#videoModal"
                               className="btn-featured-courses"
                               href="#" onClick={() => setSelectedVideo(813950347) + setCloseVideo(false)}>
                                <Button name={i18next.t("bg-home-btn")}/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="home-center">
                    <div className="row">
                        <h2 className="home-center-title mt-4 mb-1">{i18next.t("home-s6-title")}</h2>
                        <div className="courses">
                            <div className="container my-4">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mt-4">
                                        <CardCvStudent image={img_cv_1} path_1={"/projects/102"}/>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mt-4">
                                        <CardCvStudent image={img_cv_2} path_1={"/projects/100"}
                                                       path_2={"/projects/101"}/>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mt-4">
                                        <CardCvStudent image={img_cv_3} path_1={"/projects/103"}/>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mt-4">
                                        <CardCvStudent image={img_cv_4} path_1={"/projects/98"}
                                                       path_2={"/projects/99"}/>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mt-4">
                                        <CardCvStudent image={img_cv_5} path_1={"/projects/97"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div
                    className="home-why"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                    data-aos-once="false"
                >
                    <Why user={props.user}/>
                </div>
                <LoginModel/>
                <StartCreatingModal name={i18next.t("home-s5-title")}/>
                <VideoModal/>
                <OpenCompetitionsModal/>
                <OverviewIqCourseModel/>
            </div>
            <div className="footer-mobile-desktop">
                {/*<div className="nav-chat">*/}
                {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"*/}
                {/*       className="btn-whatsapp-19">*/}
                {/*        <img src={img_whatsapp} alt="icon"/>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Home;