import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./PrivacyPolicy.scss";
import img_1 from "/src/assets/images/about/kids-01.jpeg";
import img_2 from "/src/assets/images/about/kids-02.jpeg";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import i18next from "i18next";

const PrivacyPolicy = () => {
  return (
    <div className="bg">
    <div className="container" onLoad={window.scrollTo(0,0)}>
      <div className="privacy-policy">
        <div className="container">
        <h1 className="privacy-policy-title">Privacy Policy</h1>
          <div className="row my-5">
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Introduction</h2>
              <p className="paragraph-1">Our privacy policy will help you understand what information we collect at IoT Kids Application, how the application uses it, and what choices you have. IoT Kids built the app as a free app to download. If you choose to use our Service, you agree to collect and use information about this policy. The Personal Information we collect is used to create a user’s account for easy access to the application. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Information Collection and Use</h2>
              <p className="paragraph-1">For a better experience, while using the IoT Kids application, we may require you to provide us with certain personally identifiable information, including but not limited to the parent’s email address, phone number, and child’s first and last name. The information we request will be retained and used as described in this privacy policy.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Cookies</h2>
              <p className="paragraph-1">Cookies are files with small amounts of data commonly used as anonymous unique identifiers. These are sent to your browser from your website and stored on your device’s internal memory.</p>
              <p className="paragraph-1">This app does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve its services. You can either accept or refuse these cookies and know when a cookie is being sent to your device. If you refuse our cookies, you may not be able to use some portions of this app.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Children’s Privacy</h2>
              <p className="paragraph-1">This app does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 except for the child’s first and last name to generate course completion certificates. If we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and are aware that your child has provided us with personal information, please contact us so that we can take the necessary actions.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Third-Party Service Providers: </h2>
              <p className="paragraph-1">We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, and marketing assistance.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Mobile Device Access:</h2>
              <p className="paragraph-1"> We may request access or permission to certain features from your mobile device, including your mobile device’s storage. If you wish to change our access or permissions, you may do so in your device’s settings. </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Push Notifications:</h2>
              <p className="paragraph-1"> We may request to send you push notifications regarding your account or the Application. If you wish to opt-out of receiving these types of communications, you may turn them off in your device’s settings. </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">USE OF YOUR INFORMATION</h2>
              <p className="paragraph-1">Accurate information about you allows us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:</p>
              <ol type="1">
                <li>Create and manage your account </li>
                <li>Compile anonymous statistical data and analysis for use internally.</li>
                <li> Email you regarding your account or order.</li>
                <li>Increase the efficiency and operation of the Application.</li>
                <li>Request feedback and contact you about your use of the Application.</li>
                <li>Request feedback and contact you about your use of the Application.</li>
                <li> Send you a newsletter.</li>
                <li>Solicit support for the Application.</li>
                <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.</li>
                <li> Fulfill and manage purchases, orders, payments, and other transactions related to the Application.</li>
                <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
                <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
                <li>Notify you of updates to the Application.</li>
                <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
                <li>Prevent fraudulent transactions, monitor theft, and protect against criminal activity.</li>
                <li>Process payments and refunds.</li>
                <li>Respond to product and customer service requests.</li>
                </ol>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">SECURITY OF YOUR INFORMATION </h2>
              <p className="paragraph-1">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information. </p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Changes to This Privacy Policy</h2>
              <p className="paragraph-1">We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
            </div>
            {/* end text */}
            {/* start text */}
            <div className="col-12">
              <h2 className="title-1">Contact Us</h2>
              <p className="paragraph-1">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
              <p className="paragraph-1">Contact Information:</p>
              <p className="paragraph-1">Email: support@iotkidsiq.com</p>
            </div>
            {/* end text */}
          </div>
        </div>
      </div>
    </div>
    <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
  );
};

export default PrivacyPolicy;