import React from "react";
import "./ContainerLoading.scss";


const ContinerLoading = () => {
  return (
    
    <div className="skeleton-card ">
      <div className="skeleton-card-img" />
      <div className="skeleton-card-body ">
        <div className="skeleton-card-title" />
        <div className="skeleton-card-price" />
      </div>
    </div>
  );
};
export default ContinerLoading;
