import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp, CheckCircle2, PlayCircle, BookOpen, Lock } from "lucide-react";
import axios from "axios";

const Expander = ({ title, lessons, detailsid, isFirstCourse = false, isEnrolled = false }) => {
    const [isExpanded, setIsExpanded] = useState(isFirstCourse);
    const [completedLessons, setCompletedLessons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Create an axios instance with default headers
    const api = axios.create({
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    useEffect(() => {
        if (isFirstCourse) {
            setIsExpanded(true);
            if (isEnrolled) {
                fetchCompletionData();
            }
        }
    }, [isFirstCourse, isEnrolled]);

    const fetchCompletionData = async () => {
        const token = localStorage.getItem("token");
        const idUser = localStorage.getItem("id");

        if (!token || !idUser) {
            setError("Authentication required");
            setLoading(false);
            window.location.href = '/login';
            return;
        }

        try {
            const response = await api.get(`/api/SLPLesson/${idUser}/${detailsid}`);

            if (response.data) {
                setCompletedLessons(response.data);
            }
            setLoading(false);
            setError(null);
        } catch (error) {
            console.error("Error fetching completion data:", error);
            setLoading(false);

            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                setError("Failed to fetch lesson data");
            }
        }
    };

    useEffect(() => {
        if (isExpanded && !isFirstCourse && isEnrolled) {
            fetchCompletionData();
        }
    }, [isExpanded, detailsid, isEnrolled, isFirstCourse]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const completionPercentage = Math.round((completedLessons.length / lessons.length) * 100) || 0;

    const isLessonCompleted = (lessonId) => {
        return completedLessons.some(completed => completed.lessonId === lessonId);
    };

    const getStartingLesson = () => {
        if (!lessons?.length) return { index: 0, id: null, forceFirst: true };

        if (completedLessons.length === 0) {
            return {
                index: 0,
                id: lessons[0]?.id,
                forceFirst: true
            };
        }

        const firstUncompletedIndex = lessons.findIndex(
            lesson => !isLessonCompleted(lesson.id)
        );

        if (firstUncompletedIndex === -1) {
            return {
                index: lessons.length - 1,
                id: lessons[lessons.length - 1]?.id,
                forceFirst: false
            };
        }

        return {
            index: firstUncompletedIndex,
            id: lessons[firstUncompletedIndex]?.id,
            forceFirst: false
        };
    };

    const startingLesson = getStartingLesson();

    if (error) {
        return (
            <div className="mb-6 bg-white rounded-2xl shadow-md p-4">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    return (
        <div className="mb-6 bg-white rounded-2xl shadow-md overflow-hidden">
            <div
                className={`flex justify-between items-center p-4 cursor-pointer transition-colors ${
                    isExpanded ? 'bg-custom-blue text-white' : 'bg-gray-50 text-zinc-900'
                }`}
                onClick={toggleExpand}
            >
                <div className="flex items-center space-x-3 space-x-reverse">
                    <BookOpen size={20} />
                    <h4 className="text-base mt-1 font-bold tracking-wide">{title}</h4>
                </div>
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>

            {isExpanded && (
                <div className="p-4">
                    {isEnrolled ? (
                        <>
                            <div className="flex justify-between items-center mb-4 border-b pb-4">
                                <div className="flex items-center gap-2">
                                    <span className="text-gray-600 text-sm">
                                        {lessons.length} دروس
                                    </span>
                                    <span className="text-gray-600 text-sm">
                                        • {completionPercentage}% مكتمل
                                    </span>
                                </div>
                                <Link
                                    to={{
                                        pathname: `/courselessons/${detailsid}`,
                                        state: {
                                            pathCourse: `/course/${detailsid}`,
                                            id: detailsid,
                                            startingLesson: startingLesson.index,
                                            lessonId: startingLesson.id,
                                            forceFirstLesson: startingLesson.forceFirst
                                        },
                                    }}
                                    className="inline-flex items-center gap-2 bg-custom-yellow hover:bg-yellow-400 text-zinc-900 font-semibold text-sm px-4 py-2 rounded-full transition-colors"
                                >
                                    <PlayCircle size={18} />
                                    ابدأ الدورة
                                </Link>
                            </div>

                            <div className="space-y-2">
                                {loading ? (
                                    <div className="flex justify-center py-4">
                                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-custom-blue"></div>
                                    </div>
                                ) : (
                                    lessons.map((lesson, index) => (
                                        <div
                                            key={index}
                                            className={`flex items-center justify-between py-3 px-2 rounded-lg transition-colors ${
                                                isLessonCompleted(lesson.id) ? 'bg-custom-blue-light' : 'hover:bg-gray-50'
                                            }`}
                                        >
                                            <div className="flex items-center gap-3 text-gray-700 flex-1">
                                                <span className="text-sm font-medium">{index + 1}.</span>
                                                <span className="text-sm">{lesson.title}</span>
                                            </div>
                                            {isLessonCompleted(lesson.id) ? (
                                                <CheckCircle2 size={18} className="text-custom-blue flex-shrink-0" />
                                            ) : (
                                                <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex-shrink-0" />
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="py-4 text-center">
                            <div className="flex flex-col items-center gap-3">
                                <Lock size={24} className="text-gray-400" />
                                <p className="text-gray-600">اشترك في المسار للوصول إلى جميع الدروس</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Expander;