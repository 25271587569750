import Button from "../../../components/Button/Button";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./MyAccount.scss";
import img_camera from "/src/assets/images/camera.png";
import axios from "axios";
import i18next from "i18next";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import img_profile from "../../../assets/images/charecter/boyhi.png"
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
const idUser = localStorage.id;

class MyAccount extends Component {
  // state to save data form api
  state = {
    item: [],
    Msg: "",
  };
  // get data form api
  componentDidMount() {
    axios.get(`/api/users/GetUserbyId/${idUser}`).then((res) => {
      const item = res.data;
      this.setState({ item });
    });
  }
  // function post update
  submit = (e) => {
    e.preventDefault();
    const data = {
      id: this.id,
      fullName: this.fullName,
      email: this.email,
      address: this.address,
      profilePic: this.profilePic,
      password: this.password,
    };
    axios.put(`/api/users/${idUser}`, data);
    axios.patch(
      `/api/users/ResetPassword/${idUser}?NewPassword=${password}`,
      data
    );
  };

  constructor() {
    super();
    this.inputfullName = React.createRef();
    this.inputphoneNumber = React.createRef();
    this.inputemail = React.createRef();
    this.inputpassword = React.createRef();
    // this.inputprofilePic = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: idUser,
      fullName: this.inputfullName.current.value,
      phoneNumber: this.inputphoneNumber.current.value,
      email: this.inputemail.current.value,
      password: this.inputpassword.current.value,
    };
    axios.put(`/api/users/${idUser}`, data).then((res) => {
      var Msg = document.getElementById("err-message");
      var Msg_succsess = i18next.t("msg-succsess");
      var Msg_fail = i18next.t("msg-succsess");
      if (res.status === 200 || res.status === 201) {
        Msg.style.display = "block";
        this.setState({ Msg: `${Msg_succsess}` });
      } else {
        Msg.style.display = "block";
        this.setState({ Msg: `${Msg_fail}` });
      }
    });
  };
  render() {
    return (
      <div className="bg bg-login">
      <div className="container">
        <MenuProfile />
        <div className="my-account">
          <form onSubmit={this.handleSubmit}>
          <h6 id="err-message" className="err-message">{this.state.Msg}</h6>
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="mb-3 my-account-lable">
                  <label className="form-label">
                    {i18next.t("my-account-text-1")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    //placeholder={this.state.item.fullName}
                    ref={this.inputfullName}
                    defaultValue={this.state.item.fullName}
                  />
                </div>
                <div className="mb-3 my-account-lable">
                  <label className="form-label">
                    {i18next.t("my-account-text-2")}
                  </label>
                  <input
                    type="number"
                    ref={this.inputphoneNumber}
                    defaultValue={this.state.item.phoneNumber}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 my-account-lable">
                  <label className="form-label">
                    {i18next.t("my-account-text-3")}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={this.state.item.email}
                    ref={this.inputemail}
                  />
                </div>
                <div className="mb-3 my-account-lable">
                  <label className="form-label">
                    {i18next.t("my-account-text-4")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    defaultValue=""
                    ref={this.inputpassword}
                  />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="profile-pic">
                  <label className="-label" htmlFor="file">
                    <span className="glyphicon glyphicon-camera"></span>
                    <div className="change-image">
                      <img src={img_camera} alt="icon" />
                      {/* <h6>Change Image</h6> */}
                    </div>
                  </label>
                  <input
                    id="file"
                    type="file"
                    onChange={(e) => (this.profilePic = e.target.value)}
                  />
                  <img
                    // src={this.state.item.profilePic}
                    src={img_profile}
                    id="output"
                    width="200"
                  />
                </div>
              </div>
              <div className="my-account-btn">
                <Button name={i18next.t("my-account-btn")} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
      </div>
    );
  }
}

export default MyAccount;
