import React, { Component } from "react";
import "./CardIQTestLevel.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import img_open from "../../assets/images/test/check-mark.png";

//import ReactDOM from 'react-dom';
const CardIQTestLevel = (props) => {
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className={`card ${props.lock}`}>
          <Link to={props.path}>
            {/* <img
              src="/src/assets/images/IQ-Test-382x300.jpg"
              className="card-img-top"
              alt="Image Corse"
            /> */}
            <div className="card-body">
              <div className="card-text">
                 <img src={props.image} alt="icon" /> 
                {props.text}
              </div>
              <div className="m-2"></div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CardIQTestLevel;
