import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./OrderSuccess.scss";
import i18next from "i18next";
import Button from "../../components/Button/Button";
import img_success from "../../assets/images/pay/succeeded.png";

class OrderSuccess extends Component {
  render() {
    return (
      <div className="pay m-0 p-0">
        <div className="nav-item">
          <button onClick={() => history.back(-1)} className="navbar-btn">
            X
          </button>
        </div>
        <div className="iqtest-item-title">
          <h2 className="my-4">{i18next.t("orderSuccess-title")}</h2>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="paynow">
                <div className="pay-with">
                  <img
                    className="d-flex justify-content-center mx-auto mb-4 mt-1"
                    src={img_success}
                    alt="image"
                  />
                  <div className="paynow-top">
                    <h4>{i18next.t("orderSuccess-Stateus")}</h4>
                  </div>
                  <hr className="my-4" />
                
                  <div className="paynow-center">
                   
                    <div className="m-2">
                      <Link to={"/store"}>
                      <Button
                       
                        name={i18next.t("conti-shoping")}
                      />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dialog */}
      </div>
    );
  }
}
export default OrderSuccess;
