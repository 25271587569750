import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { userState } from '../../atom';

// Import all your components here
import Home from "../../pages/Home/Home";
import CourseDetails from "../../pages/CourseDetailsv2/CourseDetails";
import CoursePlayer from "../../pages/CoursePlayer/CoursePlayer";
import LoginPage from "../../pages/Login/Login";
import About from "../../pages/About/About";
import CompetitionsPage from "../../pages/CompetitionsPage/CompetitionsPage";
import CompetitionDetails from "../../pages/CompetitionDetails/CompetitionDetails";
import IQTestLevels from "../../pages/IQTest/IQTestLevels/IQTestLevels";
import IQTestItem from "../../pages/IQTest/IQTestItem/IQTestItem";
import PaySuccess from "../../pages/PaySuccess/PaySuccess";
import PayFail from "../../pages/PayFail/PayFail";
import ProcessPayment from "../../pages/ProcessPayment/ProcessPayment";
import Pay from "../../pages/Pay/PayBulk";
import Certificate from "../../pages/Courses/Certificate/Certificate";
import NotFound from "../NotFound/NotFound";
import MidTest from "../MidTest/MidTest";
import FinalTest from "../../components/FinalTest/FinalTest";
import MyCourses from "../../pages/Profile/MyCourses/MyCourses";
import Overview from "../../pages/Profile/Overview/Overview";
import IQTest from "../../pages/Profile/IQTest/IQTest";
import MyAccount from "../../pages/Profile/MyAccount/MyAccount";
import Rewards from "../../pages/Profile/Rewards/Rewards";
import MyProjects from "../../pages/Profile/MyProjects/MyProjects";
import CreateProject from "../../pages/Profile/CreateProject/CreateProject";
import ForgetPasswordEmail from "../../pages/ForgetPassword/ForgetPasswordEmail/ForgetPasswordEmail";
import ForgetPasswordNew from "../../pages/ForgetPassword/ForgetPasswordNew/ForgetPasswordNew";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import Projects from "../../pages/Projects/Projects";
import ProjectInfo from "../../pages/ProjectInfo/ProjectInfo";
import Shop from "../../pages/Shop/Shop";
import Cart from "../../pages/Cart/index";
import OurPartner from "../../pages/OurPartner/Ourpartner";
import JoinOurTeam from "../../pages/JoinOurTeam/JoinOurTeam";
import ContactUs from "../../pages/ContactUs/ContactUs";
import MyMessart from "../../pages/Profile/MyMessart/MyMessarat";
import Offers from "../../pages/Offers/Offers";
import ShopPay from "../../pages/ShopPay/ShopPay";
import ShopProduct from "../../pages/ShopProduct/ShopProduct";
import IQCoursesDetails from "../../pages/IQCoursesDetails/IQCoursesDetails";
import PreviousYearQuestion from "../../pages/PreviousYearQuestion/PreviousYearQuestion";
import CoursesOffers from "../../pages/CoursesOffers/CoursesOffers";
import UserList from "../../pages/UserList/UserList";
import Jobdetails from "../../pages/JoinOurTeam/JobDetails";
import PathPage from "../../pages/TailwindcssCourseDetails/CourseDetails";
import Wallet from "../../pages/WalletDetails/Wallet";
import Codeavour2022 from "../../pages/Competitions/CompetitionCodeavour2022/CompetitionCodeavour2022";

// PrivateRoute component
const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useRecoilValue(userState);
    return (
        <Route
            {...rest}
            render={(props) =>
                user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
            }
        />
    );
};

const RouterApp = () => {
    const user = useRecoilValue(userState);

    return (
        <div className="router-app">
            <Switch>
                {/* Public routes */}
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/register" component={LoginPage} />
                <Route exact path="/competitions" component={CompetitionsPage} />
                <Route exact path="/competition/:id" component={CompetitionDetails} />
                <Route exact path="/competition/codeavour2022" component={Codeavour2022} />
                <Route exact path="/course/:id" component={CourseDetails} />
                <Route exact path="/courselessons/:id" component={CoursePlayer} />
                <Route exact path="/iqtestlevels" component={IQTestLevels} />
                <Route exact path="/iqtestitem" component={IQTestItem} />
                <Route exact path="/pay/:id" component={Pay} />
                <Route exact path="/processPayment" component={ProcessPayment} />
                <Route exact path="/paySuccess" component={PaySuccess} />
                <Route exact path="/payFail" component={PayFail} />
                <Route exact path="/certificate/:id" component={Certificate} />
                <Route exact path="/midtest/:id" component={MidTest} />
                <Route exact path="/finaltest/:id" component={FinalTest} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/projects" component={Projects} />
                <Route exact path="/projects/:id" component={ProjectInfo} />
                <Route exact path="/store" component={Shop} />
                <Route exact path="/store/:id" component={ShopProduct} />
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/shoppay" component={ShopPay} />
                <Route exact path="/ourpartner" component={OurPartner} />
                <Route exact path="/joinourteam" component={JoinOurTeam} />
                <Route exact path="/jobdetails" component={Jobdetails} />
                <Route exact path="/contactus" component={ContactUs} />
                <Route exact path="/offers" component={Offers} />
                <Route exact path="/iqcourses-details" component={IQCoursesDetails} />
                <Route exact path="/prevquestions" component={PreviousYearQuestion} />
                <Route exact path="/coursesoffers" component={CoursesOffers} />
                <Route exact path="/userlist" component={UserList} />
                <Route exact path="/packagedetails/:id" component={PathPage} />
                <Route exact path="/wallet" component={Wallet} />
                <Route exact path="/sendEmail" component={ForgetPasswordEmail} />
                <Route exact path="/forgetpasswordnew" component={ForgetPasswordNew} />

                {/* Protected routes */}
                <PrivateRoute exact path="/overview" component={Overview} />
                <PrivateRoute exact path="/mycourses" component={MyCourses} />
                <PrivateRoute exact path="/myprojects" component={MyProjects} />
                <PrivateRoute exact path="/myprojects/create" component={CreateProject} />
                <PrivateRoute exact path="/myprojects/update/:id" component={CreateProject} />
                <PrivateRoute exact path="/rewards" component={Rewards} />
                <PrivateRoute exact path="/iqtest" component={IQTest} />
                <PrivateRoute exact path="/myaccount" component={MyAccount} />
                <PrivateRoute path="/mymessarat" component={MyMessart} />

                {/* Catch-all route */}
                <Route exact path="/404" component={NotFound} />
                <Redirect to="/404" />
            </Switch>
        </div>
    );
};

export default RouterApp;