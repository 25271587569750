import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState, notificationState } from "../../atom";
import i18next from "i18next";

// Import SVGs as assets
import HomeIcon from "/src/assets/images/navbarIcons/Home.svg";
import logo from "/src/assets/images/header/new-logo.png";
import TrophyIcon from "/src/assets/images/navbarIcons/trophy.svg";
import LayersIcon from "/src/assets/images/navbarIcons/Layers.svg";
import ShoppingBagIcon from "/src/assets/images/navbarIcons/ShoppingBag.svg";
import UserIcon from "/src/assets/images/navbarIcons/User.svg";
import LogInIcon from "/src/assets/images/navbarIcons/LogIn.svg";
import MenuIcon from "/src/assets/images/navbarIcons/menuNav.svg";

const AppHeader = () => {
    const lang = localStorage.getItem("lang") || "en";
    const user = useRecoilValue(userState);
    const notificationNo = useRecoilValue(notificationState);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const langChange = (e) => {
        const newLang = e.target.value;
        localStorage.setItem("lang", newLang);
        window.location.reload();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const navItems = [
        { path: "/", label: "header-home", icon: HomeIcon },
        { path: "/competitions", label: "header-competitions", icon: TrophyIcon },
        { path: "/projects", label: "header-projects", icon: LayersIcon },
        // { path: "/store", label: "header-shop", icon: ShoppingBagIcon },
    ];

    const NavLinks = ({ onClick }) => (
        <>
            {navItems.map((item) => (
                <NavLink
                    key={item.path}
                    className={({ isActive }) =>
                        `flex items-center px-4 py-2 text-gray-700 hover:text-custom-blue transition-colors duration-300 ${
                            isActive ? 'font-bold text-custom-blue' : ''
                        }`
                    }
                    to={item.path}
                    onClick={onClick}
                >
                    <img src={item.icon} alt={item.label} className="ml-3 w-6 h-6 flex-shrink-0" />
                    <span>{i18next.t(item.label)}</span>
                </NavLink>
            ))}

            {user ? (
                <NavLink
                    className={({ isActive }) =>
                        `flex items-center px-4 py-2 text-gray-700 hover:text-custom-blue transition-colors duration-300 ${
                            isActive ? 'font-bold text-custom-blue' : ''
                        }`
                    }
                    to="/mymessarat"
                    onClick={onClick}
                >
                    <img src={UserIcon} alt="User" className="ml-2 w-5 h-5 flex-shrink-0" />
                    <span>{i18next.t("header-profile")}</span>
                </NavLink>
            ) : (
                <NavLink
                    className={({ isActive }) =>
                        `flex items-center px-4 py-2 text-gray-700 hover:text-custom-blue transition-colors duration-300 ${
                            isActive ? 'font-bold text-custom-blue' : ''
                        }`
                    }
                    to="/login"
                    onClick={onClick}
                >
                    <img src={LogInIcon} alt="Login" className="ml-3 w-5 h-5 flex-shrink-0" />
                    <span>{i18next.t("header-signin")}</span>
                </NavLink>
            )}
        </>
    );

    return (
        <nav className="bg-white shadow-sm py-2">
            {/* Header Content - Always visible */}
            <div className="container mx-auto px-4 relative z-50">
                <div className="flex items-center justify-between">
                    <Link className="flex-shrink-0 transition-transform duration-300 hover:scale-105" to="/">
                        <img src={logo} alt="Logo" className="h-11" />
                    </Link>

                    {/* Desktop Navigation */}
                    <div className="hidden lg:flex items-center space-x-1">
                        <div className="flex items-center space-x-1 overflow-x-auto scrollbar-hide">
                            <NavLinks />
                        </div>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="lg:hidden">
                        <button
                            className="p-2 focus:outline-none"
                            onClick={toggleMenu}
                            aria-expanded={isMenuOpen}
                            aria-label="Toggle menu"
                        >
                            <img src={MenuIcon} alt="Menu" className="w-6 h-6" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu Overlay */}
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity duration-300 lg:hidden ${
                    isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
                onClick={() => setIsMenuOpen(false)}
            />

            {/* Mobile Menu */}
            <div
                className={`absolute top-0 pt-14 left-0 right-0 bg-white shadow-lg transition-all duration-300 z-40 lg:hidden ${
                    isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-2 opacity-0 pointer-events-none'
                }`}
            >
                <div className="px-4 py-2 space-y-2">
                    <NavLinks onClick={() => setIsMenuOpen(false)} />
                </div>
            </div>
        </nav>
    );
};

export default AppHeader;