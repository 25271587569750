import Button from "../../components/Button/Button";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Projects.scss";
import axios from "axios";
import CardProject from "../../components/CardProject/CardProject";
import MenuProjects from "./MenuProjects/MenuProjects";
import Loading from "../../components/Loading/Loading";
import ContinerLoading from "../../components/ContainerLoading/ContaineLoading";
import CardVideo2 from "../../components/CardVideo2/CardVideo";
import { useRecoilState } from "recoil";
import Ques from "../../assets/images/Ques.png"
import { visitedstate,selectedVideoState,closeVideoState,ProjectSItems } from "../../atom";
import { apigetFeaturedProjects,apiGetProjectsByCategory } from "../../services/api/generalApi";
import VideoModal from "../../components/VideoModal/VideoModal";
import i18next from "i18next";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
const idUser = localStorage.id;

const Projects = () => {
  const [item, setItem] = useRecoilState(ProjectSItems);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
  const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);
  
  const [categoryID, setCategoryID] = useState(null);
  const [visited, setVisited] = useRecoilState(visitedstate);
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);
  const GetProjects = async ()=>{
    const data =await apigetFeaturedProjects()
    setItem(data)
  }
  const GetProjectsByCatefory = async (CatID)=>{
    const data =await apiGetProjectsByCategory(CatID)
    setItem(data)
  }

  useEffect(() => {
    if (categoryID) {
      setLoading(true);
      if (categoryID == "IsFeatured") {
      GetProjects();
      setLoading(false)
      } else {
       GetProjectsByCatefory(categoryID)
       setLoading(false)
      }
    }
  }, [categoryID]);

 

  return (
    <div className="bg">
    <div className="container">
      <MenuProjects onChange={(id) => setCategoryID(id)} />
      <div className="projects">
        {loading==true?
         <div className="row justify-content-center ">
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
         <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
         <ContinerLoading  />
         </div>
        
       </div>
       :
       <div>
       <div className="row"  onLoad={handleOnLoad}>
         {item.map((project, index) => {
           return (
             <div className="col-12 col-md-6 col-lg-3" key={index}>
               <CardVideo2
                 //authorID={project?.userId}
                 path={`/projects/${project.id}`}
                 name={
                   (i18next.language === "ar" ? project.projectName : project.projectNameEN)?.length > 20
                     ? `${(i18next.language === "ar" ? project.projectName.slice(0, 20) : project.projectNameEN).slice(0, 20)}...`
                     : (i18next.language === "ar" ? project.projectName.slice(0, 20) : project.projectNameEN)
                 }
                 
                 video={project.videoURL.split("/")[2]}
                 rate={project.projectRate}
               />
             </div>
           );
         })}
       </div>
     </div>


        }
      </div>
      
    </div>
    <a  data-bs-toggle="modal"
                data-bs-target="#videoModal"
                className="btn-featured-courses"
                href="#"  onClick={() => setSelectedVideo(813950347) + setCloseVideo(false)}>
                  <Button name={i18next.t("bg-home-btn")} />
                </a>
            
            
     <VideoModal/>
     <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
    
    
  );
};

export default Projects;
