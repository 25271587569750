import React, { Component, useEffect } from 'react';
import "./Loading.scss";
import loading from "/src/assets/images/loading.gif";
// import iotKidsLogo from "/src/assets/images/header/IoT-KIDS-logo.png";
// import spider from '/src/assets/images/spider.json';
// import anim_2 from '/src/assets/images/loading.json';
import Lottie from 'lottie-web';
import i18next from 'i18next';
import anim_loadding from "../../assets/images/loadingsm.json"


//import ReactDOM from 'react-dom';
const Loading = (props) => {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector('#lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: anim_loadding
    })

    //   Lottie.loadAnimation({
    //     container: document.querySelector('#lottie_2'),
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: true,
    //     animationData: anim_2
    //   })
  }, [])
  if (props.width || props.height) {
    return <img src={loading} width={props.width} height={props.height} alt="logo" />
  }

  return (
    <div className="loading" >
      <span id="lottie"></span>
      {/* <span id="lottie_2"></span> */}
      {/* <img src={loading} alt="logo" /> */}

      <div className='download-app d-block d-md-none'>
        <h3>{i18next.t("download-app-title")}</h3>
        <div className="appStore-googlePlay d-flex w-100 row mr-3">
          <a href="https://apps.apple.com/us/app/iot-kids/id1637665030" target="_blank" className="market-btn-light apple-btn my-2" role="button">
            <span className="market-button-subtitle">Download on the</span>
            <span className="market-button-title">App Store</span>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.iotmaker.iotkids" target="_blank" className="market-btn-light google-btn my-2" role="button">
            <span className="market-button-subtitle">GET IT ON</span>
            <span className="market-button-title">Google Play</span>
          </a>
        </div>
      </div>
    </div>
  );
}


export default Loading;