import React from "react";
import i18next from "i18next";

import imge_1 from "../../assets/images/ourpartnerimages/51lab.jpg";
import imge_2 from "../../assets/images/ourpartnerimages/comu.png";
import imge_3 from "../../assets/images/ourpartnerimages/pled.png";
import imge_4 from "../../assets/images/ourpartnerimages/minofedu.png";
import imge_5 from "../../assets/images/ourpartnerimages/ZainlogoB.png";
import imge_6 from "../../assets/images/ourpartnerimages/earthlink.png";
import imge_7 from "../../assets/images/ourpartnerimages/giz.png";
import "./Ourpartner.scss";

const OurPartner = () => {
  return (
    <div id="ourpartner">
      <div>
        <h1 className="text-center  fs-1 title-1 fw-bold">
          {i18next.t("partner-title")}
        </h1>
      </div>
      {/* Our Partners Images And Title */}

      <div className="container text-center ">
        <div className="row align-items-center text-center hover">
          <div className="images-minofedu col-lg-4 col-md-6 mt-5">
            <img className="" src={imge_4} alt="" />
          </div>
          <div className="images-51lab col-lg-4 col-md-6">
            <img className="  mt-3 px-lg-0" src={imge_1} alt="" />
          </div>
          <div className="images-comu col-lg-4 col-md-6">
            <img className="mt-md-5" src={imge_2} alt="" />
          </div>
          <div className="images mt-md-5 col-lg-4 col-md-6">
            <img className="mt-zain" src={imge_5} alt="" />
          </div>
          <div className="images-pled mt-md-5 col-lg-4 mt-zain  col-md-6">
            <img className="mt-pled" src={imge_3} alt="" />
          </div>
          <div className="images-earthlink mt-md-5 col-lg-4 mt-earthlink col-md-6">
            <img className="mt-3 mt-earthlink" src={imge_6} alt="" />
          </div>
          </div>
        </div>
      </div>
    
  );
};
export default OurPartner;
