import React, { useEffect, useState } from 'react';
import Spline from '@splinetool/react-spline';
import "./BgHome.scss";
import Button from '../Button/Button';
import i18next from 'i18next';
import Lottie from 'lottie-web';
import anim_down from "../../assets/images/home/down.json";
import bg_shadow_1 from "../../assets/images/home/umbrella.png";
import bg_shadow_2 from "../../assets/images/home/send.png";
import bg_shadow_3 from "../../assets/images/home/botanical.png";
import bg_shadow_4 from "../../assets/images/home/file.png";
import bg_shadow_5 from "../../assets/images/home/sun.png";
import bg_shadow_6 from "../../assets/images/home/abc-block.png";
import bg_shadow_7 from "../../assets/images/home/autism.png";
import bg_shadow_8 from "../../assets/images/home/falling-star.png";
import bg_shadow_9 from "../../assets/images/home/bulb.png";
import bg_shadow_10 from "../../assets/images/home/question.png";
import { useRecoilState } from 'recoil';
import { closeVideoState, selectedVideoState } from '../../atom';
import VideoModal from '../VideoModal/VideoModal';
import img_bg_home_md from "../../assets/images/home/bg-home-md.png";
import anim_c_home from "../../assets/images/summer-home.json";
import CoursesOffers from '../../pages/CoursesOffers/CoursesOffers';

export const BgHome = () => {
    const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);
    const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
    const lang = i18next.language;
    const videoAds = 774682475;
    useEffect(() => {
        Lottie.loadAnimation({
            container: document.querySelector('#bg-home-anim'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: anim_down
        })
        Lottie.loadAnimation({
            container: document.querySelector('#lottie-home'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: anim_c_home
        })
    }, [])
    return (
        <div className='bg-home'>
            {
                lang == 'en' &&
                <span className='bg-shadow-1'>
                    <img src={bg_shadow_1} alt="Image" />
                </span>
            }

            <span className='bg-shadow-2'>
                <img src={bg_shadow_2} alt="Image" />
            </span>

            {
                lang == 'en' &&
                <span className='bg-shadow-3'>
                    <img src={bg_shadow_3} alt="Image" />
                </span>
            }

            <span className='bg-shadow-4'>
                <img src={bg_shadow_4} alt="Image" />
            </span>

            {
                lang == 'en' &&
                <span className='bg-shadow-5'>
                    <img src={bg_shadow_5} alt="Image" />
                </span>
            }

            <span className='bg-shadow-6'>
                <img src={bg_shadow_6} alt="Image" />
            </span>

            <span className='bg-shadow-7'>
                <img src={bg_shadow_7} alt="Image" />
            </span>

            <span className='bg-shadow-8'>
                <img src={bg_shadow_8} alt="Image" />
            </span>

            <span className='bg-shadow-9'>
                <img src={bg_shadow_9} alt="Image" />
            </span>

            <span className='bg-shadow-10'>
                <img src={bg_shadow_10} alt="Image" />
            </span>
            <div className='row h-100'>
                

                <div className='col-12 col-lg-12'>
                    {lang == 'en' ?
                        <div className='bg-home-text container'>
                            <h1>{i18next.t("bg-home-title")} </h1>
                            <p>{i18next.t("bg-home-des")}</p>
                            <a
                                className="bg-home-text-btn"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#videoModal"
                                href="#"
                                onClick={() => setSelectedVideo(videoAds) + setCloseVideo(false)}
                            >
                                <Button name={i18next.t("bg-home-btn")} />
                            </a>
                        </div>
                        :
                        <CoursesOffers />
                    }
                </div>
                <div className='col-12 col-lg-12 mt-lg-5 mt-0'>
                    <div className='bg-home-anim'>
                        <span id="lottie-home"></span>
                        {/* <Spline setZoom="0" scene="https://prod.spline.design/UX7G65A2I9zScLqQ/scene.splinecode" /> */}
                    </div>
                    <div className='bg-home-anim-mobile d-none'>
                        <img src={img_bg_home_md} alt="Image" />
                    </div>
                </div>
                <div className='col-12 mt-lg-4 mt-0'>
                    <a href='#content' id='bg-home-anim'>
                    </a>
                </div>
            </div>

            <VideoModal />
        </div>
    )
}