const en ={
  "lang": "en",
  "LANG": "EN",
  "language": "Language",

  "header-home": "Home",
  "header-courses": "Courses",
  "header-projects": "Projects",
  "header-about": "About",
  "header-shop": "store",
  "header-competitions": "Competitions",
  "header-signin": "Sign in",
  "header-profile": "My Profile",
  "home-s1-text": "Prepare for The AI Competition",
  "home-s1-text-p": "Prepare for The AI Competition with the Preparatory Courses. Enroll now and get 35% Discount.",
  "home-s1-text_2": "Learn the Basics of Programming",
  "home-s1-text-p_2": "In this set of Courses, the Child Will Acquire new Skills that in Turn Strengthen his Imagination to Think, Creative and Logical design, as He Learns Programming in an Easy and Fun Way. Subscribe Now and get 25% off",
  "home-s1-btn": "Enroll now",
  "home-s2-title": "Featured Courses",
  "invaild-phone-number":"Invaild Phone Number",
  "home-s2-btn": "More Courses",
  "home-s3-title": "Why IoT Kids Courses ?",
  "home-s3-point-1": "Invest in your Children at an Early Age",
  "home-s3-point-2": "No Limits to Children's Imagination with IoT Kids",
  "home-s3-point-3": "No More Mobile Phone Addiction",
  "home-s3-point-4": "Problem-Solving and Logical Thinking is the key to your Children's Success",
  "home-s3-btn": "Start Learning Now",
  "home-s4-title":"What have our Children Developed!",
  "home-s4-btn":"Start Creating Now",
  "go-to-roadmap":"Go to Roadmap",
  "home-s5-title":"Assisting you in Choosing the Right Courses for Your Project",
  // "home-offers-btn":"Offers",
  "explore-roadmap": "Explore Roadmap",
  "home-s6-title": "IoT Kids Ambassadors",
  "home-offers-btn":"Offers",
  "more-icon-text":"More",

  "job-details-title":"Job Details ",
  "job-details-desc":"We are looking for a skilled and enthusiastic Front End UI Developer to join our team. You will play a critical role in the design and implementation of the user interface for our learning platform. You will work closely with our multiple operational teams to create visually stunning, easy-to-use websites that deliver an exceptional user experience. If you are passionate about creating innovative digital solutions and have a solid understanding of front-end development technologies, we would be happy to connect with you",
  "job-details-title-2":"Responsibilities",
  "resp-1":"Collaborate with designers and back-end developers to transform user interface designs into functional web interfaces.",
  "resp-2":"Develop clean, efficient and scalable code using HTML, CSS, JavaScript and React JS",
  "resp-3":"Optimize sites for maximum speed and scalability.",
  "resp-4":"Ensure compatibility with multiple browsers and different platforms",
  "resp-5":"Implement responsive designs to ensure a seamless user experience across different devices.",
  "resp-6":"Perform comprehensive testing and debugging to ensure smooth functionality.",
  "resp-7":"Stay up-to-date with the latest trends and best practices in front-end development.",
  "job-details-title-3":"ٌequirements",
  "req-1":"Bachelor's degree in Computer Science or related field (or equivalent work experience).",
  "req-2":"Proven work experience as a front-end developer at least 2 years",
  "req-3":"Strong familiarity with HTML, CSS, JavaScript and associated frameworks/libraries Strong understanding of  Responsive design and mobile-first development.",
  "req-4":"Knowledge of version control systems (eg Git) and software collaboration platforms (eg GitHub).",
  "req-5":"Experience with user interface design principles and attention to detail.",
  "req-6":"Excellent problem-solving skills and good troubleshooting skills.",
  "req-7":"Ability to work effectively in a fast-paced and collaborative work environment.",
  "req-8":"Strong communication skills and ability to articulate technical concepts to non-technical stakeholders",
  "job-details-title-4":"Benfits",
  "benf-1":"Opportunity to work on pioneering and innovative projects.",
  "benf-2":"Opportunities for professional growth and development.",
  "benf-3":"A collaborative and inclusive work environment.",
  "benf-4":"Working hours are from 10:00 AM  until 3:00 PM",
  "end-job-details":"If you are excited to be part of our team, please send your resume or CV to the application email. We look forward to reviewing your application and welcoming you to our team",
  "email-to-apply":"Apply On",

  "change-lang-t": "This Course is in Arabic",
  "change-lang-d": "The Language Must be Changed to Arabic to Continue",
  "change-lang-btn": "Change to Arabic",
  "register-input-email": "Email Address",
  "courses-nav-1": " Feauter Courses",
  "courses-nav-2": "Coding",
  "courses-nav-3": "Robotic",
  "courses-nav-4": "Design",
  "courses-nav-5": "Electronic",
  "courses-nav-6": "Mind Growth",
  "courses-details": "Course Details",
  "first-name":"First Name",
  "last-name":"Last Name",
  "payment-form-name-hint2":"Enter your First Name",
  "payment-form-name-hint3":"Enter your Last Name",


  "basket-continue": "Continue",
  "cart-title": "Cart List",
  "cart-desc": "Please Make Sure of your Selected Items and Click Checkout to Continue",
  "cart-total": "Sub Total",
  "total-price":"Totla Price",
  "cart-discount": "Discount",
  "cart-delivery": "Delivery Fee",
  "cart-final-price": "Final Price",
  "cart-checkout": "Checkout",

  "ref-text": "Send the Coupon Code to your Friends and get 20 Points for Every Course Purchase your Friends Make.",
  "ref-input-title":"Coupon Code :",
  "ref-copy-btn": "Copy",

  "competitions-title": "Competitions",
  "competitions-des": "Did you know that IoT KIDS Hosts National and International Events and Competitions Every Year? Our Events Include IQ Test Contests, Codeavour, Arab Coding Marathon, and 1000 Coder.",
  "competitions-finsh":"Ended",
  "competitions-online":"On-Going",
  "about-s1-title": "Did You Know That,",
  "about-s1-des": "IoT KIDS is the First Company of it's kind in Iraq Where you Can Invest in your Children’s Time and Creativity. IoT KIDS Provides Online Courses for Children Aged 7-15 Years old in Game Design, Robotics, Coding, Electronics, and Artificial Intelligence. We have the Largest Community in Iraq for Children and Currently, we are Operating in 9 Different Cities Throughout the Country. Our Goal is to  Eliminate the Addiction of Children to Smartphones and Encourage Children to use Technology in the Right Way to Innovate and Invent at an Early Age",
  "about-s2-title-1": "Our Mission",
  "about-s2-des-1": "To Provide the Children with the Knowledge and Skills Needed Throughout Their Careers to Solve the Problems of the 21st Century and Beyond.",
  "about-s2-title-2": "Our Vision",
  "about-s2-des-2": "To Enable the New Generation to Implement Higher-Order Thinking Skills and to Develop Skills Necessary to Research, Produce, and Communicate.",

  "login-modal-title": "First you Must Login or Create a New Account ",

  "login-title": "Login",
  "register-title": "Register",
  "login-btn-regester": "Register",
  "login-input-email": "Email Or Phone Number",
  "login-input-fullname": "Student Full Name",
  "login-input-phone": "Phone Number",
  "login-input-password": "Password",
  "login-btn-login": "Login",
  "login-btn-forget-password": "Forget Password",
  "login-btn-rigester": "Register Now ",
  "login-des": "Don't have an Account?",
  "register-des": "Do you have Account?",

  "profile-nav-1": "Overview",
  "profile-nav-2": "My Courses",
  "profile-nav-3": "IQ Test",
  "profile-nav-4": "Rewards",
  "profile-nav-5": "My Account",
  "profile-nav-6": "Log out",
  "profile-nav-7": "My Projects",

  "overflow-text-1": "My Courses",
  "overflow-text-2": "Completed Courses",
  "overflow-text-3": "IQ Test Passed",
  "overflow-text-4": "Collected Points",
  "overflow-text-5": "Prizes Roadmap",
  "overflow-points": "Points",
  "overflow-btn-1": "Support",
  "overflow-btn-2": "Continue Learning",
  "overflow-btn-3": "IQ Test",

  "my-courses-card-btn": "Go to the Course",
  "my-courses-btn": "Continue Learning",
  "my-course-no-data": "You don't have Courses",
  "my-course-no-data-btn": "Go to Courses",


  "my-projects-card-btn": "Go to the Course",
  "my-projects-btn": "Continue Learning",
  "my-projects-no-data": "You don't have Projects",
  "my-projects-no-data-btn": "Add your Project",
  "my-projects-new": "Do you have a new Projects ?",
  "my-projects-new-btn": "Add New Project",

  "create-projects-title": "Add Your Project Detials",
  "create-projects-input-title": "Project Title",
  "create-projects-input-title-hint": "Write Your Project Title",
  "create-projects-input-desc": "Project Description",
  "create-projects-input-desc-hint": "Write your Project Description",
  "create-projects-input-type": "Category",
  "create-projects-input-type-hint": "Select Category",
  "create-projects-input-file": "Project Video",
  "create-projects-input-file-hint": "Drag video file or Click to Browse",
  "create-projects-save": "Save",
  "create-projects-cat-1": "Game Design",
  "create-projects-cat-2": "Control a Robot",
  "create-projects-cat-3": "AI",
  "create-projects-cat-4": "Facial Recog",
  "create-projects-cat-5": "Arduino",
  "create-projects-submit": "Upload Now",
  "create-projects-edit": "Save Edits",
  "create-projects-lang": "Project Language",
  "create-projects-ar": "Arabic",
  "create-projects-en": "English",
  "create-projects-msg-success": "Operation Done Successfully",
  "create-projects-msg-error": "Something is Wrong Please try Again!",


  "projects-info-edit": "Edit",
  "projects-info-delete": "Delete",
  "projects-info-created-by": "Created By",
  "projects-info-related": "Want to Create Similer Project ?",
  "projects-info-related-sub": "Explore These Courses",
  "projects-info-confirm-msg": "Do you Want to Remove this Project ?",
  "projects-info-confirm": "Confirm",
  "projects-info-cancel": "Cancel",
  "projects-info-rate": "Add your Rate",
  "project-new": "New",
  "projects-info-rate-user": "Your Rating",
  "projects-featured": "Featured Projects",

  "back-to-store": "Back To Store",
  "back-to-invoice": "View Invoice",

  "product-course": "This Product Comes with a Free Course that Will Teach you How to use it.",
  "product-youtub": "Learn More About the Product",
  

  

  "rewards-btn": "Get More",

  "my-account-text-1": "Full Name",
  "my-account-text-2": "Phone Number",
  "my-account-text-3": "Email Address",
  "my-account-text-4": "Password",
  "my-account-btn": "Update",

  "logout-title": "Log Out",
  "logout-des": "Are You Sure You Want to Sign Out",
  "logout-btn-1": "Close",
  "logout-btn-2": "Log Out",

  "lock-title": "Locked Test",
  "lock-des": "This Test is Closed. You Must Complete the Previous Level or Wait 24 Hours.",
  "lock-btn-1": "Close",

  "schools-competition-second-title": "School Championship Path 2024",
  "schools-competition-second-description": "It's time for your children to participate in the championship and make a change! The championship will witness the participation of more than 300 male and female students from all Iraqi governorates, where they will compete over several stages to solve a set of problems facing education in Iraq.",
  "schools-competition-second-stages": "Stages",
  "schools-competition-second-stage-1-title": "First Stage: ",
  "schools-competition-second-stage-1-description": "Registration is done through the attached form.",
  "schools-competition-second-register": "Register Now",
  "schools-competition-second-stage-2-title": "Second Stage: ",
  "schools-competition-second-stage-2-description": "Testing phase.",
  "schools-competition-second-stage-3-title": "Third Stage: ",
  "schools-competition-second-stage-3-description": "Innovation and Ideas phase: Each student must present an idea that solves a problem facing education in Iraq. The idea can be (a game, or using Arduino, or using a CNC machine).",
  "schools-competition-second-stage-4-title": "Fourth Stage: ",
  "schools-competition-second-stage-4-description": "Idea implementation phase: The qualifiers begin to implement their ideas and present an initial project presentation. The work is evaluated by the judging committee. Championship conclusion phase: The qualifiers present the final project to the judging committee. Projects are evaluated and the first winner is selected.",
  "schools-competition-second-prizes": "Prizes 🎁🎁",
  "schools-competition-second-prizes-description": "At the end of the championship, three students will win prizes as a reward for the projects they presented. The first winner will have a special opportunity to travel to Dubai.",

  "btn-details": "Details",
  "btn-buynow": "Buy Now",
  "btn-go-course": "Go to Course",
  "offers-no-title":"Lessons",
  "offers-page-title":"Offers on Courses",
  "offers-page-desc":"Special offers for a limited time on a group of courses",

  "lessons-nav-btn-1": "Back to Course",
  "lessons-btn-back": "Previous",
  "lessons-btn-next": "Mark as Complete",
  "next-lesson": "Next Lesson",
  "back-lesson": "Back Lesson",
  "lessons-des": "Description",
  "lessons": "Lessons",
  "lessons-certificate": "Certificate",
  "lessons-btn-mid-test": "Go to Mid Test",
  "lessons-btn-final-test": "Go to Final Test",
  "lessons-btn-certificate": "Get the Certificate",

  "c-title": "The Schools Championship",
  "c-title-2": "The Schools Championship for Coding and Innovation",
  "c-des": "Kids Competition for Programming and Innovation The Competition Will Include 1000 Students Who Will Compete on Different Levels on Topics that Range from Environmental Solutions to Technological, Mathematical, Engineering Questions, and More. After the End of the Competition, three winners will be Receiving Prizes. The Competition Will Start by the End of Eid Al-Adha. Sign up Soon and Start Practicing for the First Level of the Competition! And of Course, our Platform Has All The Courses you Will Need to Get Ready for the Competition! Acceptance will be on a First-Come-First-Served Basis. The Competition Will be Online and Free of Charge. ",
  "r-title": "Preparatory Courses for the Second Stage",
  "c-price": "dddd",
  "iqtest-title": "IQ Test",
  "iqtest-degree": "Test Result",
  "iqtest-des": "it's IQ Test lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  "iqtest-pass-title": "Congratulations! You Passed the Test and Won 10 Points",
  "iqtest-pass-des": "Please Wait 24 Hours to Unlock the Next Level",
  "iqtest-pass-btn": "Back to Levels",
  "iqtest-faild-title": "Oh No! You Failed. Take the Test Again",
  "iqtest-faild-btn-1": "Try Again",
  "iqtest-faild-btn-2": "Go to Levels",
  "iqtest-price": "Free",
  "my-iqtest-no-data": "You haven't Passed any IQ Test",
  "my-iqtest-btn": "Go to IQ Test",

  "pass-title": "You Passed the Test",
  "pass-des": "Well Done, you Passed this Test, you Can Move on to the Next Test",


  "text-content": "Course Objectives :",


  "password-h": "Changed Password",
  "password-input": "Enter your Email",
  "password-btn": "Send Massage",
  "password-h-sucsess": "Changed Password",
  "password-b-sucsess": "A Link to Change your Password has been sent to your Email.",
  "password-h-error": "Something Went Wrong",
  "password-b-error": "The Email Address is not Registered in Our System.",
  "password-b-btn": "Contact Us",
  "newpassword-input": "Enter your New Password",
  "newpassword-btn": " Submit",
  "newpassword-h-sucsess": "Changed Password",
  "newpassword-b-sucsess": "Your Password has been Changed Successfully.",
  "newpassword-h-error": "Something Went Wrong",
  "newpassword-b-error": "Something Went Wrong",
  

  "pay-coupon": "Do you have a Coupon",
  "pay-coupon-Error": "The Discount Code is Inactive",
  "pay-coupon-Error-2": "This Coupon is not Valid for this Course",
  "pay-input": "Enter your Coupon",
  "pay-btn-apply": "Apply",
  "pay-now": "Pay Now with",
  "pay-prodect": "Your Prodect",
  "pay-title": "Course Payment",
  "pay-btn-back": "Back to Course",
  "pay-btn-active": "Active",
  "pay-havenot": "If you don't have a Payment Method, Please Contact us",
  "pay-contact": "Contact Us",
  "pay-btn-zain":"Zain Cash",
  "pay-btn-zoodpay":"Zood Pay",
  "pay-head-zoodpay":"ZoodPay Payment",
  "pay-btn-card":"Cedit Card",
  "pay-delivery":"Delivery",
  "pay-cancel-btn":"Cancel",
  "pay-confirm-btn":"Continue",
  "pay-confirm-title":"Confirm Payment!",
  "pay-confirm-desc":"Click on Continue to Confirm Payment Process",

  
  "payment-form-name": "Full Name",
  "payment-form-name-hint": "Please Write your Name",
  "payment-form-phone": "Phone Number",
  "payment-form-phone-hint": "Please write your Phone Number",
  "payment-form-province": "Province Name",
  "payment-form-province-hint": "Chose your Province",
  "payment-form-city": "Area",
  "payment-form-city-hint": "Write Area Name",
  "payment-money": "Deserved Amount",
  "payment-title": "Payment Continue",


  "btn-add-basket": "Add To Cart",
  "btn-remove-basket": "Remove from Cart",
  

  "points-level-1":"Level 1",
  "points-level-2":"Level 2",
  "points-level-3":"Level 3",
  "points-level-4":"Level 4",
  "points-level-5":"Level 5",
  "points-level-6":"Level 6",
  "points-point-1":"50 Point",
  "points-point-2":"150 Point",
  "points-point-3":"250 Point",
  "points-point-4":"400 Point",
  "points-point-5":"600 Point",
  "points-point-6":"1000 Point",
  "points-button-1":"Cuntact Us",
  "points-des-1":"You Will get a Free Educational Course",
  "points-des-2":"You will get a Free IoT Kids Gift Box",
  "points-des-3":"You will get a Fold Scope for Free",
  "points-des-4":"You will get Micro Bit for Free",
  "points-des-5":"You will get Qnarqi Robot for Free",
  "points-des-6":"You will get Robot (Codey Rocky) for Free",

  "paySuccess-title": "Payment Succsess",
  "paySuccess-Stateus": "Your Purchase has been Successfully Completed",
  "paySuccess-goto": "Do you want",
  "paySuccess-goto-course": "Go to Course",
  "paySuccess-goto-courses": "Back to Courses",

  "orderSuccess-title":"Payment Successfuly",
  "orderSuccess-Stateus":" Delivery Service will Contact you Soon",

  "payFail-title": "Payment Fail",
  "payFail-Stateus": "Your Purchase has been Fail",
  "payFail-goto": "Do you Want",
  "payFail-goto-course": "Try Again",
  "payFail-goto-courses": "Back to Recent Page",

  "404-title": "Oops!",
  "404-des": "oops. There is Something Wrong",
  "404-btn": "Go to Home",

  "product-label": "Product Description", 

  "mid-test-title": "Mid Test",
  "mid-test-pass-title": "Congratulations! You Passed the Test",
  "mid-test-pass-btn": "Back to Course",
  "mid-test-faild-title": "Oh no! You Failed. Take the Test Again",
  "mid-test-prev": "Prev",
  "mid-test-next": "Next",
  "mid-test-complete": "Complete",

  "final-test-title": "Final Test",
  "final-test-pass-title": "Congratulations! You Passed the Test and Won 10 Points",
  "final-test-pass-btn": "Back to Course",
  "final-test-faild-title": "Oh No! You Failed. Take the Test Again",
  "final-test-prev": "Prev",
  "final-test-next": "Next",
  "final-test-complete": "Complete",
  "final-test-faild-btn": "Try Again",

  "certificate-title": "Certificate",
  "certificate-btn": "Download Certificate",

  "card-currency": "IQD",
  "card-is-sub": "Enrolled",

  "learn-step-1-title": "Enter Student Full Name here",
  "learn-step-2-title": "Enter your Email here *must be unused already",
  "learn-step-3-title": "Enter your Phone Number ex: 07800000xxx",
  "learn-step-4-title": "Enter your Password * It must be between 6-30 letters or numbers",
  "learn-btn-next": "Next",
  "learn-btn-skip": "Skip",

  "msg-succsess": "The Information has been Updated Successfully",
  "msg-fail": "Failed to Update Information",

  "overview-all-course-modal-title": "All Courses",
  "overview-completed-course-modal-title": "Completed Courses",
  "overview-iqtest-modal-title": "IQ Test Passed",
  "overview-point-modal-title": "Number of Points",
  "overview-notification-title": "Notification",
  "home-s99-text":"Coding Courses",
  "p-s99-text":"You can now subscribe to all courses in the software category. Subscribe now and get a 40% discount",
  "home-s99-text-3":"Electronics Courses",
  "p-s99-text-3":"You can now subscribe to all courses in the Eelectronics category. Subscribe now and get a 40% discount",

  "open-competitions-modal-btn-1": "The Winners to the Second Stage",
  "open-competitions-modal-btn-2": "Download",
  "coupon":"Coupon :",
  "coupon-title": "Eid Al-Adha Mubarak, and Every Year and you are Good",
  "coupon-des": "IoT Kids Congratulates you on Eid Al-Adha and Offers you a Gift of 20% Discount on all Training Courses During the Eid Period.",
  "coupon-btn": "Close",

  "erroe-msg-1": "Email Already Exists, Please Enter Another Email",
  "erroe-msg-2": "An Error Occurred, Please try Again",
  "erroe-msg-3": "All Fields Must be Filled Out",

  "footer-s1-1": "Social Media:",

  "footer-s2-text-1": "Who are We?",
  "footer-s2-text-2": "About US",
  "footer-s2-text-3": "Categories",
  "footer-s2-text-4": "Our Team",

  "footer-s3-text-1": "Categories",
  "footer-s3-text-2": "Game Design",
  "footer-s3-text-3": "Robotics",
  "footer-s3-text-4": "Electronics",

  "footer-s4-text-1": "Stores",
  "footer-s4-text-2": "Children Stores",
  "footer-s4-text-3": "Global winners",
  "footer-s4-text-4": "Maker space",
  

  "notification-level-1-title":"You Passed the First Level Successfully",
  "notification-level-2-title":"You Passed the Second Level Successfully",
  "notification-level-3-title":"You Passed the Third Level Successfully",
  "notification-level-4-title":"You Passed the Fourth Level Successfully",



  "footer-s5-text-1": "Contact US",
  "footer-s5-text-2": "+9647838300650",
  "footer-s5-text-3": "Info@iotkidsiq.com",

  "footer-copy": "Copyright @2022 | Developed by IoT KIDS",
  "home-s1-btn-p":"Pay Now",
  "pay-bulk-title-1":"You Will Buy",
  "pay-bulk-title-2":"of Courses",
  "pay-bulkgame-title-2":"of Courses",

  "competition-1-des":"The championship Aims to Develop Children's Abilities and Creative Imagination in Refining and Rationalizing their Talents and to Invest them Properly in the Field of Programming and Innovation in a Professional Manner to keep Pace with Global Technological Development. The Program Targets Children from all Iraqi Governorates for Ages Between (8-15) Years. The Championship is held in Cooperation with the Iraqi Ministry of Education and the Sponsorship of Zain Iraq for Telecommunication.",
  "competition-1-objects":"The Objectives",
  "competition-1-objects-1":"Shedding Light on the Distinguished Children in the Field of Codding and Innovation by Highlighting them on the Press and Social Media  ",
  "competition-1-objects-2":"Provide Programming Training for Children in a Way that is Reflected Positively in Developing their Imagination by Posing Problems and Finding Solutions  ",
  "competition-1-objects-3":"Providing Applicable Scientific Content in Programming and Informatics that Provides a Supportive Means for Teaching Computer Science at the School Stage  ",
  "competition-1-stages":"The Stages",
  "competition-1-stages-1-1":"The First Stage :",
  "competition-1-stages-1-2":"After the Announcement and Registration for the Championship, an Online Test was Conducted to Test the Candidates' Basic Programming Knowledge, 1000 Participants From all Private and Government Schools in the Iraqi Governorates Qualified to Participate in the Championship.  ",
  "competition-1-stages-2-1":"The Second Stage :  ",
  "competition-1-stages-2-2":"A Specialized Workshop Was Held on Innovation and Future Jobs Within Ten Days. The Second Test, From Which 500 Candidates Qualified for the Third Stage. ",
  "competition-1-stages-3-1":"The Third Stage :",
  "competition-1-stages-3-2":"There are Various Online Workshops for Five Days, and Then the Third Test is Done by Submitting Programming Solutions Projects that Solve Specific Problems. The Projects are Presented to the Judging Panel to Choose the Best 100 Projects.",
  "competition-1-stages-4-1":"The Fourth Stage :",
  "competition-1-stages-4-2":"The Last Stage was Dedicated to Finding Solutions Through Programming and Artificial Intelligence (Desertification, Water Pollution, Better Health), which is Part of the Sustainable Development Goals. The Projects were Submitted and then Assessed by the Judges Panel. The Judges Panel Selected 14 Projects as the Finalists.",
  "competition-1-awards":" Awards",
  "competition-1-awards-1-1":"First Place: ",
  "competition-1-awards-1-2":"2 Laptops",
  "competition-1-awards-2-1":"Second Place: ",
  "competition-1-awards-2-2":"2 Tablets",
  "competition-1-awards-3-1":"Thirst Place: ",
  "competition-1-awards-3-2":" 2 Educational Robots",
  "competition-1-awards-4-1":"Outstanding Projects: ",
  "competition-1-awards-4-2":"Shields with a Certificate of Appreciation  ",
  "competition-1-schools":"The Distinguished Schools",
  "competition-1-schools-0":"Al-Rahebat Advanced School - Baghdad",
  "competition-1-schools-1":"Maryam Elementary School - Babil",
  "competition-1-schools-2":"Alamal School - Baghdad",
  "competition-1-schools-3":"Almouhobon School - Baghdad",
  "competition-1-schools-4":"AL-Mubdioon Primary School - Mosul",
  "competition-1-schools-5":"Almaohobon High School in Najaf - Najaf",
  "competition-1-schools-6":"Qayrawan for The Distinguished High school   - Baghdad",
  "competition-1-schools-7":"Alabedaat Secondary School - Najaf",
  "competition-1-schools-8":"Marouna Alhabib School - Baghdad",
  "competition-1-schools-9":"Alqada High School - Mosul",
  "competition-1-schools-10":"Almutefaouqon for Boys High School - Kut",
  "competition-1-schools-11":"Almunathra High School - Najaf",
  "competition-1-schools-12":"Al-gharbia for Boys School - Kut",  
  "competition-1-card":"The Judges Panel",
  "competition-1-card-1-1":"Mr. Sajad Ali",
  "competition-1-card-1-2":"The Ministry of Education",
  "competition-1-card-1-3":"",
  "competition-1-card-2-1":"Mr. Muhammed Abdulmahdi",
  "competition-1-card-2-2":"CMC",
  "competition-1-card-2-3":"",
  "competition-1-card-3-1":"Dr. Sa’ad Ahmed Thiab",
  "competition-1-card-3-2":"Higher Education and Research",
  "competition-1-card-3-3":"",
  "competition-1-card-4-1":"Dr. Sa’ad Hamid",
  "competition-1-card-4-2":"Higher Education and Research",
  "competition-1-card-4-3":"",
  "competition-1-card-5-1":"Mr. Ali Muhammed",
  "competition-1-card-5-2":"Zain Iraq for Telecommunications",
  "competition-1-card-5-3":"",
  "competition-1-winners":"Winners – Children Category",
  "competition-1-winner-1-1":"Roqaya Laith",
  "competition-1-winner-1-2":"First Place",
  "competition-1-winner-2-1":"Ali Husam",
  "competition-1-winner-2-2":"Second Place",
  "competition-1-winner-3-1":"Malek Hayder",
  "competition-1-winner-3-2":" Third Place",
  "competition-1-winner-2":"Winners – Teen Category",
  "competition-1-winner-4-1":"Taher Hayder",
  "competition-1-winner-4-2":"First Place",
  "competition-1-winner-5-1":"Rayan Salwan",
  "competition-1-winner-5-2":"Second Place",
  "competition-1-winner-6-1":"Karar Hatem",
  "competition-1-winner-6-2":" Third Place",
  "competition-header-1-title": "1805 Students Participated",
  "competition-header-2-title": "96 Nominated Team",
  "competition-header-3-title": "7 Winners",
  "competition-1-sponsor":"Sponsors",
  "no-data-notification":"You don't have any Notifications",
  "no-data-iq-test":"You have not Passed any IQ Test",
  "go-to-comp-title":"Go to Competition",
  "competition-cod2022-winner-1":"Ezzeddin Ahmed",
  "competition-cod2022-winner-2":"Zainab Mohammed",
  "competition-cod2022-winner-3":"Mohammed Asaad",
  "competition-cod2022-winner-4":"Stela Noor",
  "competition-cod2022-winner-5":"Adrian Fadi",
  "competition-cod2022-winner-6":"Ibrahim Ahmed",
  "competition-cod2022-winner-7":"Mustafa Khudair",
  "winners-cod2022-comp-title":"Winners",
  "competition-cod2022-project-1":"Robot to Help Plants Grow In Perfect Environment ",
  "competition-cod2022-project-2":"Smart Eye Project",
  "competition-cod2022-project-3":"Smart Gates",
  "competition-cod2022-project-4":"Smart Home",
  "competition-cod2022-project-5":"Smart Garage",
  "competition-cod2022-project-6":"Smart Fire Extinguisher",
  "competition-cod2022-project-7":" Smart Talking Camera To Monitoring Childrens",
  "special-product":"Special Product",
  "courses-offers-product":"You get these Free courses when you buy the Product",
  "conti-shoping":"Continue shopping",

  
  "competition-codeavour-2022-title":"2022 Codeavour",
  "competition-codeavour-2022-des":"The World Championships in Programming and Artificial Intelligence is an International Competition for Artificial Intelligence and Programming Organized by Stempedia. And Because we are the Regional Partner in this Competition, and out of our Desire to Build Children’s Skills From a Young Age and Train them in Programming Skills Practically and Directly, we Aspire to Involve our Heroes in this Competition and Compete with all Participants from Different Countries of the World.",
  "competition-codeavour-2022-objects":"Objectives",
  "competition-codeavour-2022-objects-1":"Shedding Light on the Distinguished Children in the Field of Programming and Innovation by Highlighting Them on Different Media Channels.",
  "competition-codeavour-2022-objects-2":"Provide Programming Training for Children in a way That is Reflected Positively in Developing Their Imagination by Posing Problems and Finding Solutions.",
  "competition-codeavour-2022-objects-3":"Providing Training Content in the Field of Programming and Informatics that Provides a Supportive Means for Teaching Computer Science at the School Stage.",
  "competition-codeavour-2022-awards":"Conditions to Participate",
  "competition-codeavour-2022-awards-1-2":"Ages From 8-15 Years",
  "competition-codeavour-2022-awards-2-2":"Having a Computer or Tablet at Home.",
  "competition-codeavour-2022-schools":"Our Plan to Participate",
  "competition-codeavour-2022-schools-0":"We Aspire to Train 500 Male and Female Students Through our Educational Platform so that we Nominate 50 Teams to Participate and Represent Iraq in the Championship.",
  "competition-codeavour-2022-sub-title":"Submission and Participation",
  "competition-codeavour-2022-sub-des":"Participation in the Championship is Online. Participants Will Have to Implement Projects to Find innovative Solutions to the Issues Posted by the Organizers that the Globe Suffers From. The Participants will Find Ways to Use Artificial Intelligence to Solve Such Issues.",
  "competition-codeavour-2022-winer-title":"About Our Previous Participation in the Tournament",
  "competition-codeavour-2022-winer-des":"IoT Kids Formed 50 Teams to Participate in the Championship for the Years 2019, 2020, and 2021 Respectively. As a Result, our Teams Won Two Medals in the Championship Among More than 68,000 Teams from 65 Countries Competing in the Championship. The Winners from Iraq Were Honored by the Prime Minister, Mr. Mustafa Al-Kazemi, the Minister of Education, Mr. Ali Hamid Mukhlif, and Several Officials in the Ministry.",
  "competition-codeavour-2022-sponsor":"Visit Championship Website",
  "about-title":"About IoT KIDS",
  "about-ourteam-title":"Meet Our Team",
  "partner-title":"Our Partner",
  "partner-1-title":"Media and Communications Authority",
  "partner-2-title":"Ministry of Education",
  "partner-3-title":"Zain Iraq",
  "partner-4-title":"Earthlink Telecom",
  "partner-5-title":"German International Cooperation Agency",
"competition-codeavour-2021-img":"Images from Championship",
  "text-course-bulk-title":"Preparatory Courses",
  "thems-title":"Themes",
  "thems-des":"Select Among these Five Inspiring Themes and Brainstorm over Emerging Global Issue and Build Solution-Oriented Projects to Solve Real World Problems",
  "thems-1-title":"Automate the Surroundings",
  "thems-2-title":"Revolutionize the Agriculture",
  "thems-3-title":"Save the Environment",
  "thems-4-title":"Strengthen the Health Infrastructure",
  "thems-5-title":"Make Smart Transportation System",
  "thems-dev-des":"Participants can Use one of These Devices to Develop their Projects, Provided that it is Connected to the PictoBlox Software",
  "thems-dev-title-1":"Twatch",
  "thems-dev-title-2":"Quarky",
  "thems-dev-title-3":"Microbit",
  "thems-dev-title-4":"Makey Makey",
  "thems-dev-title-5":"Lego",
  "thems-dev-title-6":"Evive",
  "thems-dev-title-7":"ESP32",
  "thems-dev-title-8":"Boffin",
  "thems-dev-title-9":"Ardouino",
  "sponsors-title":"Sponsors",
  "contact-title":"Contact Us",
  "location-title":"Makers Building, Al-Sena'a St, Karada, Baghdad, Iraq",
  "mobile-num":"+9647838300650",
  "share-project-title-2":"Share Your Project in",
  "share-project-title":"Share your Project!",
  "share-project-des":"Now Shear your Achievments with your Friends on Social Midia and ask Them to Rate your Project",
  "download-app-title-2":"Download the App Now",
  "download-app-title":"Download the Application Now",
  "download-app-des":"Now our Application is Ready to Download so that it Provides you with More Possibility and More Ease to View our Educational Courses.",
  "start-creating-title-1":"My age is:",
  "start-creating-title-2":"I'm Interested in:",
  "start-creating-title-3":"Now Enroll in this Course",
  "go-to-course":"Go to Course",
  "join-our-team-title":"Join Our Team",
  "no-jobs-des":"Unfortunately, there are no Available Jobs at the Moment",
  "home-s1-text-3":"Happy Halloween",
  "home-s1-text-p-3":"Enroll now and Get 40% off all Training Courses.",
  "ourteam-title":"Our Team",
  "footer-company":"The Company",
  "footer-company-about":"About IoT KIDS",
  "footer-company-ourteam":"Our Team",
  "footer-company-ourpartner":"Our Partners",
  "footer-company-contactus":"Contact us",
  "footer-company-joinourteam":"Join Our Team",
  "footer-company-privcypolicy":"Privcy Policy",
  "footer-ourcourses":"Our Courses",
  "footer-ourcourses-gamedesign":"Game Design",
  "footer-ourcourses-robotic":"Robotics",
  "footer-ourcourses-Electronic":"Electronics",
  "footer-ourcourses-mindgrouth":"Mind Grouth",
  "pop-up-complete-text":"You have received 1000 dinars in your wallet that you can use to purchase tracks and products from our store",
  "wallet-brn-title":"Go to Wallet",
  "footer-kidsachivment":"Kids Achievements",
  "footer-kidsachivment-projects":"Projects",
  "footer-kidsachivment-competition":"Competitions",
  "download-title":"Download Our App",
  "emailus-title":"Email Us",
  "callus-title":"Call Us",
  "address-title":"Address",
  "our-team-m1-name":"Ali Muhsen",
  "our-team-m1-position":"Co-Founder ",
  "our-team-m2-name":"Mohammed Khalid",
  "our-team-m2-position":"Co-Founder ",
  "our-team-m3-name":"Ahmed Dawood",
  "our-team-m3-position":"Co-Founder ",
  "our-team-m4-name":"Ahmed Majid",
  "our-team-m4-position":"Technical Lead",
  "our-team-m5-name":"Muntather Jabar",
  "our-team-m5-position":"Development Lead",
  "our-team-m6-name":"Fatima Jasim",
  "our-team-m6-position":"Lead Trainer",
  "our-team-m7-name":"Ola Ahmed",
  "our-team-m7-position":"Social Media Specialist",
  "our-team-m8-name":"Islam Ahmed",
  "our-team-m8-position":"Trainer",
  "our-team-m9-name":"Ayat Emad",
  "our-team-m9-position":"Trainer",
  "our-team-m10-name":"Mina Majed",
  "our-team-m10-position":"Trainer",
  "our-team-m11-name":"Mustafa Ramzi",
  "our-team-m11-position":"Finance Manager",
  "our-team-m12-name":"Mustafa Ahmed",
  "our-team-m12-position":"Developer",
  "our-team-m13-name":"Howraa Loai",
  "our-team-m13-position":"Trainer",
  "btn-start-course":"Start the course",
  "see-btn":"Watch your friends' projects",
  "projects-text-1":"you don't have any Project, Create your First project.",
  "projects-text-2":"If you Want Inspiration to Create your own Project, watch your Friends Projects.",
  "start-now-btn-text":"Choose the right Course for you",
  "Subscribe-course-learn":"How to Buy a Course",
  "start-now-des-text":"Start your Journey into the World of Programming with a Game Design Course. Through this Course, Children will Learn to The Basics of Programming in an Easy and Simple way Creative and Logical Design Designing Games in a Very fun way   After Completing this Course. Kids will be Able to Move on to the Courses More Actively",
  "start-now-card-des":"if you dont have any Course yet Let us Help you to Choose the Right Course for you",
  "popup-btn-text":"Start the Learning Now",
  "how-to-upload":"How to upload My Project",
  "recomandded-courses":"Let us Help you Choose the Right Course for You",
  "UN":"ESCWA Award for Best Arab Digital Content for Sustainable Development 2023",
  "AUIS":"Iraq Pioneers Award in the Project Upgrading category as the best pilot project among 3000 projects 2019",
  "Iraq-Pre":"Iraq Pioneers Award in the Project Upgrading category as the best pilot project among 3000 projects 2019",
  "Iraq-min":"The Youth Shield presented by the Ministry of Youth and Sports at the conclusion of the Maker Camp 2019 activities",
  "seed":"Local Winners of the 2019 Seedstar Accelerator Award",
  "iq-comp-title":"IQ Competition",

  "bg-home-title":"Learn |Imagine |Innovate ",
  "bg-home-des":"IoT KIDS Offers a Set of Courses That Guide Your Child to use Technology Correctly by Using Easy and fun Learning Methods.",
  "bg-home-btn":"Learn More",

  "iqcourses-title":"IQ Courses",
  "iqcourses-price": "50000 IQD",
  "iqcourses-des":"حزمة من الدورات مجهزة لك من اجل خوض اختبارات الذكاء ",
  "iq-course-modal-note" :"You will Get the Certificate in the Course (Numeric Arithmetic and Logic Tests).",
  "go-to-my-courses":"Go to my Courses",
  "final-test-dwonload":"Download Solutions",
  "chat-tooltip":"Chat with Your teacher",
  "cvShow-title":"CV",
  "cvShow-zoom":"Zoom in"

}
export default en