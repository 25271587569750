import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";

const lang = localStorage.getItem("lang");
const id = localStorage.getItem("id");

const MyMessaratCard = (props) => {
    useEffect(() => {
        console.log(props.sub);
    }, [props]);

    return (
        <div className="bg-white rounded-xl shadow-lg overflow-hidden md:mt-0 w-full">
            <div className="p-3">
                <img src={props.image} alt={props.name} className="w-full h-52 object-cover rounded-xl mb-4" />
                <h3 className="text-xl font-bold mb-3 text-center">{props.name}</h3>

                <h1 className="text-base font-semibold text-center text-gray-700 mb-4">
                    {props.NOofcourses} {i18next.t("offers-no-title")}
                </h1>

                <div className="flex justify-center">
                    {props.sub ? (
                        <Link to={`/packagedetails/${props.RoadmapID}`} className="w-full">
                            <button className="w-full bg-custom-blue text-base text-white font-bold py-3 px-4 rounded-full hover:bg-custom-blue-dark transition duration-300">
                                الذهاب الى المسار
                            </button>
                        </Link>
                    ) : id == null || id === undefined ? (
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#loginModal"
                            className="w-full bg-custom-yellow text-zinc-900 text-base font-bold py-3 px-4 rounded hover:bg-yellow-600 transition duration-300"
                        >
                            {i18next.t("home-s1-btn")}
                        </button>
                    ) : (
                        <Link to={`/paybulk/${props.idpay}`} className="w-full">
                            <button className="w-full bg-custom-blue text-base text-zinc-900 font-bold py-3 px-4 rounded hover:bg-custom-blue-dark transition duration-300">
                                {i18next.t("home-s1-btn")}
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyMessaratCard;