import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import './VideoPlayer.scss';

const VideoPlayer = ({ src }) => {
    // Extract Vimeo ID from URL
    const getVimeoId = (url) => {
        if (!url) return null;
        // Handle direct IDs
        if (/^\d+$/.test(url)) return url;

        // Handle full URLs
        const match = url.match(/(?:vimeo.com\/|\/)?(\d+)/);
        return match ? match[1] : null;
    };

    const vimeoId = getVimeoId(src);

    if (!vimeoId) return null;

    return (
        <div className="videoplayer">
            <Vimeo
                video={vimeoId}
                responsive={true}
                autoplay={false}
                controls={true}
                showTitle={false}
                showByline={false}
                showPortrait={false}
            />
        </div>
    );
};

export default VideoPlayer;