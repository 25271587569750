import React, { Component } from "react";
import "./PassModel.scss";
import { Link, useHistory, Redirect } from "react-router-dom";
import i18next from "i18next";
import img_pass from "../../assets/images/test/pass.png";

const PassModel = () => {
  return (
    <div
      className="modal modal-pass fade"
      id="passModal"
      aria-labelledby="passModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
          <h5 className="modal-title" id="passModalLabel">
              {i18next.t("pass-title")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img className="pass-img" src={img_pass} alt="image wait" />
            <h6> {i18next.t("pass-des")}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassModel;
