import Button from "../../../components/Button/Button";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./MyProjects.scss";
import axios from "axios";
import img_sorry from "../../../assets/images/charecter/boycry.png";
import CardProgress from "../../../components/CardProgress/CardProgress";
import i18next from "i18next";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import CardMyProject from "../../../components/CardMyProject/CardMyProject";
import Loading from "../../../components/Loading/Loading";
import CardVideo2 from "../../../components/CardVideo2/CardVideo";
import anim_1 from '../../../assets/images/my-projects/anim-1.json';
import anim_2 from '../../../assets/images/my-projects/anim-2.json';
import Lottie from "lottie-web";
import VideoModal from "../../../components/VideoModal/VideoModal";
import Ques from "../../../assets/images/Ques.png"
import { useRecoilState } from "recoil";
import { selectedVideoState,closeVideoState,UserProject } from "../../../atom";
import StartCreatingModal from "../../../components/StartCreatingModal/StartCreatingModal";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";




const MyProjects = () => {
  const [item, setItem] = useRecoilState(UserProject);
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoState);
  const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);

  useEffect(() => {
    const idUser = localStorage.id;
    axios.get(`/api/UserProject/GetUserProjects/${idUser}`).then((res) => {
      setItem(res.data);
    });
  }, []);
  return (
    <div className="bg bg-login">
    <div className="container">
      <MenuProfile />
      <div className="my-projects">
        {item.length == 0? <div className="row">
        <div className="my-projects-no-data">
          <img src={img_sorry} alt="no data" />
          <h4>{i18next.t("my-projects-no-data")}</h4>
        </div>
 <div className="row mt-2" style={{ maxWidth: 520, margin: 'auto' }}>
              <Link to="/myprojects/create" className="col-lg-3col-md-6 col-12 btn-featured-courses" type="button"
               >
                <Button name={i18next.t("home-s4-btn")} />
              </Link>
              <a  data-bs-toggle="modal"
                data-bs-target="#videoModal"
                className="btn-featured-courses"
                href="#"  onClick={() => setSelectedVideo(813950337) + setCloseVideo(false)}>
                  <Button name={i18next.t("how-to-upload")} />
                </a>
            </div>
      </div>:  <div>
        <div className="row">
          {item.map((project, index) => {
            return (
              <div className="col-12 col-md-6 col-lg-4" key={index}>
                <CardVideo2
                  //authorID={project?.userId}
                  status={project.status}
                  path={`/projects/${project.id}`}
                  name={project.projectName}
                  video={project.videoURL.split("/")[2]}
                  rate={project.projectRate}
                />
              </div>
            );
          })}
        </div>
        <div className="add-new-container">
          <h5>{i18next.t("my-projects-new")}</h5>
          <div className="my-projects-btn">
            <Link to="/myprojects/create">
              <Button name={i18next.t("my-projects-new-btn")} />
            </Link>
          </div>
        </div>
      </div>}
        <div className="anim-card-overview-input">
          <div className="card-ref-overview d-lg-flex">
            <div id="card-project-anim-2" className="card-ref-overview-anim justify-content-lg-start"></div>
            <h6>{i18next.t("projects-text-2")}</h6>
            <Link className="mx-lg-3 projects-alert justify-content-lg-end"
              to="/projects">
              <Button name={i18next.t("see-btn")} />
            </Link>
          </div>
        </div>
      </div>
      <StartCreatingModal />
    </div>
   
    <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
            
     <VideoModal/>
    </div>
  );
};

export default MyProjects;
