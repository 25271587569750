import service from "../../../services";

export const apiGetCourseLessons = (id) => {
    return new Promise((resolve) => {
        service.getData(`/api/Lesson/courselessons/${id}`).then((data) => {
            resolve(data);
        });
    });
};
export const apiGetCourseLessonsById = (idCourse) => {
    return new Promise((resolve) => {
        service.getData(`/api/Lesson/courselessons/${idCourse}`).then((data) => {
            resolve(data);
        });
    });
};
