import React, { Component, useState } from "react";
import "./CardVideo.scss";
import ImageDemo from "../../assets/images/c-img.png";
import { useEffect } from "react";
import Lottie from "lottie-web";
import anim_play from "../../assets/images/anim-play.json";
import img_star_1 from "../../assets/images/home/star-1.png";
import img_star_2 from "../../assets/images/home/star-2.png";
import VideoModal from "../VideoModal/VideoModal";
import StartRating from "../StarRating";
import { useRecoilState } from "recoil";
import { selectedVideoState } from "../../atom";

//import ReactDOM from 'react-dom';
const CardVideo = (props) => {

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.getElementById("play-icon"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_play,
    });
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card-video" data-aos="flip-down" data-aos-delay="200">
          <a
            className="card-price-btn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#videoModal"
            href="#"
          >
            <img
              src={`https://vumbnail.com/${props?.video.split("/")[2]}.jpg`}
              className="card-img-top"
              alt="Image Corse"
            />
            <div className="wrapper">
              <div className="circle pulse"></div>
              <div className="circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                  <polygon points="40,30 65,50 40,70"></polygon>
                </svg>
              </div>
            </div>
            <div className="card-body">
              <p className="card-text">{props.name}</p>
             
              {/* <p className="card-price mx-2">
                {props.rating == 0 ?
                (<div className="stars">
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                </div>
                )
                :props.rating == 1 ?
                (<div className="stars">
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                </div>
                ):props.rating == 2 ?
                (<div className="stars">
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                </div>
                ):props.rating == 3 ?
                (<div className="stars">
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                </div>
                ):
                props.rating == 4 ?
                (<div className="stars">
                <img className="star-2" src={img_star_2} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                </div>
                ): props.rating == 5 ?
                (<div className="stars">
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                <img className="star-1" src={img_star_1} alt="star" />
                </div>
                ):
                 "" }
              </p> */}
              <div className="m-2"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default CardVideo;
