import React, { useState, useEffect } from "react";
import './CoursesOffers.scss'
import CardOffers from "../../components/CardOffers/CardOffers"
import { useRecoilState } from "recoil";
import { MessaratDetails } from "../../atom";
import { apigetMessartDetails } from "../../services/api/generalApi/index";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LoginModel from "../../components/LoginModel/LoginModel";

const CoursesOffers = () => {
  const [packagedetails, setpackagedetails] = useRecoilState(MessaratDetails)

  const GetMessaratDetails = async (id) => {
    const data = await apigetMessartDetails(id)
    setpackagedetails(data)
  }

  useEffect(() => {
    const id = localStorage.getItem("id")
    GetMessaratDetails(id);
  }, []);

  return (
      <div className="page-all">
        <div className="container">
          <div className="courses-grid">
            {packagedetails?.slice().reverse().map((item) => (
                <div className="course-item" key={item.id}>
                  <Link to={`/packagedetails/${item.id}`}>
                    <CardOffers
                        name={item.title}
                        RoadmapID={item.id}
                        delprice="200,000"
                        price="150,000"
                        NOofcourses={item.messaratCourse.filter((el) => el.messaratDetailsDto.isMessar != true).length}
                        idpay={item.id}
                        image={item.image}
                        sub={item.isEnrolled}
                    />
                  </Link>
                </div>
            ))}
          </div>
          <LoginModel />
        </div>
      </div>
  )
}

export default CoursesOffers