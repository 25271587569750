import i18next from 'i18next';
import React from 'react';
import './ShareProject.scss';

//import ReactDOM from 'react-dom';
const ShareProject = (props) => {
  return (
    <div className="share-project">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="share-project__title">
            <span>{i18next.t("share-project-title")}</span>
            <p>{i18next.t("share-project-des")}</p>
          </div>
        </div>
        <div className="col-12 col-md-6 mt-md-0 mt-4">
          <h3 className="project__social__title_2">{i18next.t("share-project-title-2")}</h3>
          <div className="share-project__social">
            <a className="share-project__social__facebook" href={props.facebook} target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a className="share-project__social__linkedin" href={props.whatsapp} target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <a className="share-project__social__instagram" href={props.telegram} target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-telegram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ShareProject;