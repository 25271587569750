import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import "./IQTestLevels.scss";
import CardIQTestLevel from "../../../components/CardIQTestLevel/CardIQTestLevel";
import CardLockIQTestLevel from "../../../components/CardLockIQTestLevel/CardLockIQTestLevel";
import CardPassIQTestLevel from "../../../components/CardPassIQTestLevel/CardPassIQTestLevel";
import img_open from "../../../assets/images/test/check-mark.png";
import img_wait from "../../../assets/images/test/open.png";
import axios from "axios";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

class IQTestLevels extends Component {
  // state to save data form api
  state = {
    item: [],
  };
  // get data form api
  componentDidMount() {
    // const Language = localStorage.i18nextLng
    const Language = "Ar";
    const idUser = localStorage.id;
    axios.get(`api/IQTest?Lang=${Language}&UserId=${idUser}`).then((res) => {
      const item = res.data;
      this.setState({ item });
      
    });
  }
  render() {
    return (
      <div className="container">
        <div className="iqtest-level">
          <div className="row">
            {this.state.item.map((postDetails, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                  {postDetails?.userPassed ? (
                    <CardPassIQTestLevel
                          text={postDetails.title}
                        />
                  ) : (
                    <div>
                      {postDetails.userCanTakeTest ? (
                        <CardIQTestLevel
                      text={postDetails.title}
                      image={img_wait}
                      path={{
                        pathname: `/GetIQTestQuestions/${postDetails.id}`,
                        state: {
                          title: `${postDetails.title}`,
                          id: `${postDetails.id}`,
                        },
                      }}
                    />
                      ) : (
                        <CardLockIQTestLevel text={postDetails.title} />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
      </div>
    );
  }
}
export default IQTestLevels;
