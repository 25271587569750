import React, { Component } from 'react';
import './CourseBuy.scss';
import Button from '../Button/Button';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import i18next from 'i18next';
import LoginModel from '../LoginModel/LoginModel';
//import ReactDOM from 'react-dom';
const CourseBuy = (props) =>{

  
let btn_buy ;
if(props.user){
  btn_buy=(
    <Link to={props.path}>
      <Button name={i18next.t("btn-buynow")} />
    </Link>
  )
}
else{
  btn_buy=(
  <a
  className='w-100'
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#loginModal"
    href="#">
      <Button name={i18next.t("btn-buynow")}/>
  </a>
  )
}
return (
  <div className="courses-buy">
    {/*<div className="price ">*/}
    {/*  <del className=''>*/}
    {/*    <span className="des px-1">{props.oldPrice ? props.oldPrice : "50,000"}{i18next.t("card-currency")} </span>*/}
    {/*  </del>*/}
    {/*  <ins>*/}
    {/*    <span className="amount">{props.price?.toLocaleString()} {' '} {i18next.t("card-currency")}</span>*/}
    {/*  </ins>*/}
    {/*</div>*/}

    <div className="btn-buy">
      {btn_buy}
    </div>

    <ul className="courses-buy-">
      <li>
        <h4>{i18next.t("text-content")}</h4>
      </li>
      <li>
        <div className="courses-buy-title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
      </li>
    </ul>
    <LoginModel/>
  </div>
);
}


export default CourseBuy;