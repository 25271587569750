import React, { Component, useState, useEffect } from "react";
import "./CreateProject.scss";
import i18next from "i18next";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import axios from "axios";
import Button from "../../../components/Button/Button";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
// import { upload } from 'youtube-videos-uploader'

const idUser = localStorage.id;

const CreateProject = () => {
  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [name, setName] = useState(null);
  const [cat, setCat] = useState(null);
  const [desc, setDesc] = useState(null);
  const [lang, setLang] = useState(i18next.language);
  const [videoURL, setVideoURL] = useState(null);
  let history = useHistory();
  let { id } = useParams();
  let percent = 0;

  useEffect(() => {
    axios.get(`/api/ProjectCategory`).then((res) => {
      setCategories(res.data);
    });
  }, []);

  useEffect(() => {
    if (id) {

     
      axios.get(`/api/UserProject/${id}`).then((res) => {
        let project = res?.data;
        setLang(project?.lang);
        setName(
          project?.lang === "ar" ? project?.projectName : project?.projectNameEN
        );
        setDesc(
          project?.lang === "ar"
            ? project?.projectDescription
            : project?.projectDescriptionEN
        );
        setCat(project?.categoryId);
        setVideoURL(project?.videoURL);
      });
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateProject(e);
    } else {
      createProject(e);
    }
  };

  const createProject = (e) => {
    setIsLoading(true);
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    formData.append("UserId", idUser);
    // formData.append("videoFile", file);
    formData.append("ProjectDescriptionEN", formProps.ProjectDescription);
    formData.append("ProjectNameEN", formProps.ProjectName);
    axios
      .post(`api/VimeoUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //timeout: 55000,
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          percent = Math.floor((loaded * 100) / total);
          
          if (percent <= 100) {
            setPercentage(percent);
          }
        },
      })
      .then((res) => {
       
        if (res?.data?.status) {
          history.goBack();
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        alert("erro");
        setIsLoading(false);
      });
  };
  const updateProject = (e) => {
    setIsLoading(true);
    const formData = new FormData(e.target);
    formData.append("UserId", idUser);
    const formProps = Object.fromEntries(formData);
    formProps.videoURL = videoURL;
    axios
      .put(`api/UserProject/${id}`, formProps, {
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          percent = Math.floor((loaded * 100) / total);
        
          if (percent <= 100) {
            setPercentage(percent);
          }
        },
      })
      .then((res) => {
        
        if (res?.status === 200) {
          // history.goBack();
          Swal.fire(i18next.t("create-projects-msg-success"), "", "success");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        Swal.fire(i18next.t("create-projects-msg-error"), "", "error");
        setIsLoading(false);
      });
  };

  return (
    <div className="container">
      <MenuProfile />
      <div className="my-projects-create">
        {!id && <h3>{i18next.t("create-projects-title")}</h3>}

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  {i18next.t("create-projects-input-title")}
                </label>
                <input
                  disabled={isLoading}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name={"ProjectName"}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder={i18next.t("create-projects-input-title-hint")}
                />
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">
                  {i18next.t("create-projects-input-type")}
                </label>
                <select
                  value={cat}
                  onChange={(e) => setCat(e.target.value)}
                  disabled={isLoading}
                  name={"CategoryId"}
                  className="form-select"
                  aria-label="multiple select example"
                >
                  {categories.map((el) => (
                    <option selected value={el.id}>
                      {i18next.language === "ar"
                        ? el.categoryNameAR
                        : el.categoryNameEN}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              {i18next.t("create-projects-input-desc")}
            </label>
            <textarea
              required
              placeholder={i18next.t("create-projects-input-desc-hint")}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              disabled={isLoading}
              name={"ProjectDescription"}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>

          {!id && (
            <div className="mb-3">
              <label for="formFileLg" className="form-label">
               {i18next.t("create-projects-input-file")}
              </label>
              <input
                className="form-control form-control-lg"
                id="formFileLg"
                type="file"
                name="videoFile"
                required
                accept="video/mp4,video/x-m4v,video/*"
                placeholder={i18next.t("create-projects-input-file-hint")}
              />
            </div>
          )}
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              {i18next.t("create-projects-lang")}
            </label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Lang"
                  id="ar"
                  value="ar"
                  required
                  disabled={isLoading}
                  checked={lang === "ar"}
                  onChange={() => setLang("ar")}
                />
                <label className="form-check-label" for="ar">
                  {i18next.t("create-projects-ar")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Lang"
                  id="en"
                  value="en"
                  required
                  disabled={isLoading}
                  checked={lang === "en"}
                  onChange={() => setLang("en")}
                />
                <label className="form-check-label" for="en">
                  {i18next.t("create-projects-en")}
                </label>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-label="Animated striped example"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          ) : (
            <div style={{ maxWidth: 180, marginTop: 40 }}>
              <Button
                name={i18next.t(
                  id ? "create-projects-edit" : "create-projects-submit"
                )}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateProject;
