import React, { useEffect, useState } from "react";
import "./OverviewNotificationModel.scss";
import i18next from "i18next";
import CardProgress from "../CardProgress/CardProgress";
import axios from "axios";
import Button from "../Button/Button";
import img_sorry from '../../assets/images/charecter/boythink.png';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CardHorizantalNotification from "../CardHorizantalNotification/CardHorizantalNotification";
import img_gift_1 from "/src/assets/images/points/2-1.png";
import img_gift_2 from "/src/assets/images/points/2-2.png";
import img_gift_3 from "/src/assets/images/points/2-3.png";
import img_gift_4 from "/src/assets/images/points/2-4.png";
import Lottie from "lottie-web";
import anim_notification from '/src/assets/images/overview/notifiation.json';

const OverviewNotificationModel = (props) => {
  const idUser = localStorage.id;
  const [item, setItem] = useState([]);
  const [profileSummary, setProfileSummary] = useState({});
  useEffect(() => {
    axios.get(`/api/SLPCourse/${idUser}`).then((res) => {
      const item = res.data;
      setItem(item);

      axios
        .get(`/api/SLPCourse/Profile/${idUser}`)
        .then((res) => {
          const profileSummary = res.data;
          setProfileSummary(profileSummary);
        });
    });
  }, []);

  let data;
  if (props.num < 50) {
    data = (
      <div className="row">
        <div className="my-course-no-data mt-4">
          <img src={img_sorry} alt="no data" />
          <h4>{i18next.t("no-data-notification")}</h4>
        </div>
        {/* <div className="col-12">
          <div className="my-courses-btn mt-5 pt-4">
            <Link to="/courses">
              <Button name={i18next.t("my-course-no-data-btn")} />
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
  if (props?.num > 50) {
    data = (
      <div className="row">
        <div className="col-12">
          {props?.num > 50 ? (
            <CardHorizantalNotification
              name={i18next.t("notification-level-1-title")}
              image={img_gift_1} />
          ) : ""}{props?.num > 150 ? (
            <CardHorizantalNotification
              name={i18next.t("notification-level-2-title")}
              image={img_gift_2} />
          ) : ""}{props?.num > 250 ? (
            <CardHorizantalNotification
              name={i18next.t("notification-level-3-title")}
              image={img_gift_3} />
          ) : ""}{props?.num > 400 ? (
            <CardHorizantalNotification
              name={i18next.t("notification-level-4-title")}
              image={img_gift_4} />
          ) : ""}
        </div>
      </div>
    );
  }
  return (
    <div
      className="modal overview-notification-modal fade"
      id="overview-notificationModal"
      aria-labelledby="overview-notificationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="header-model">
            <h2 className="header-model-title">
                {i18next.t("overview-notification-title")}
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="body-model">
              {data}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewNotificationModel;
