import React, { Component } from "react";
import "./Card.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
import LoginModel from "../LoginModel/LoginModel";
const id =localStorage.getItem("id")
//import ReactDOM from 'react-dom';
const Card = (props) => {
  //get url path 
  const path = window.location.pathname;

  
  let btnPay;
 
    btnPay = (
      <Link
        to={`/course/${props.pathId}`}
        className="card-price-btn"
      >
        <p className="card-price my-3 mx-2">{i18next.t("btn-buynow")}</p>
      </Link>
    );
  
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className="card">
          <Link to={`/course/${props.pathId}`}>
            <img src={props.image} className="card-img-top" alt="Image Corse" />
            <div className="card-body">
              <p className="card-text">{props.name}</p>
              <p className="card-price mx-2">
              
              
              </p>
              <div className="m-2"></div>
            </div>
            <div className="content py-3 px-2">
              <p className="card-text my-3">{props.name}</p>
              <p className="card-des my-3">{props.description}</p>

            
              {props.iqCourse == 'true' ? "" : btnPay}
            </div>
          </Link>
        </div>
      </div>
      {path !== '/coursesoffers' &&
        <LoginModel />
      }
    </div>
  );
};
export default Card;
