import React, { useEffect, useState } from "react";
import "./CompetitionCodeavour2022.scss";
import i18next from "i18next";
import axios from "axios";
import CardCompetitions from "../../../components/CardCompetitions/CardCompetitions";
import img_c_1 from "./Images/1.png";
import img_c_2 from "./Images/2.png";
import img_c_3 from "./Images/3.png";
import img_c_4 from "./Images/4.png";
import img_c_5 from "../../../assets/images/competitions/16.png";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import img_h_1 from "./Images/icon-user.png";
import img_h_2 from "./Images/icon-ducment.png";
import img_h_3 from "./Images/icon-winners.png";
import img_h_4 from "./Images/chart-1.png";
import img_h_5 from "./Images/chart-2.png";
import img_1 from "./Images/I1.png";
import img_2 from "./Images/I2.png";
import img_3 from "./Images/I3.png";
import img_4 from "./Images/I4.png";
import img_5 from "./Images/I5.png";
import img_6 from "./Images/Ardouino.png";
import img_7 from "./Images/Boffin.png";
import img_8 from "./Images/ESP32.png";
import img_9 from "./Images/Evive.png";
import img_10 from "./Images/Lego.png";
import img_11 from "./Images/Makey.png";
import img_12 from "./Images/Microbit.png";
import img_13 from "./Images/Quarky.png";
import img_14 from "./Images/Twatch.png";
import img_15 from "./Images/minofedu.png";
import img_16 from "./Images/zain.png";
import img_17 from "./Images/pledges.png";
import img_s_1 from "./Images/student-1.png";
import img_s_2 from "./Images/student-2.png";
import img_s_3 from "./Images/student-3.png";
import img_s_4 from "./Images/student-4.png";
import img_s_5 from "./Images/student-5.png";
import img_s_6 from "./Images/student-6.png";
import img_s_7 from "./Images/student-7.png";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import LoginModel from "../../../components/LoginModel/LoginModel";
import { selectedImageState,closeVideoState } from "../../../atom";
import { useRecoilState } from "recoil";
import ImageModal from "../../../components/ImageModal/ImageModal";



const CompetitionCodeavour2022 = (props) => {
  const UserId = localStorage.id;
  const lang = localStorage.i18nextLng.toUpperCase();
  const [item, setItem] = useState([]);
  const [courses, setCourses] = useState([]);
  const [activeImage, setActiveImage] = useState();
  const [selectedImage,setSelectedImage]=useRecoilState(selectedImageState)
  const [closeVideo,setCloseVideo]=useRecoilState(closeVideoState)

  useEffect(() => {
    axios.get(`/api/UserReward/AllRewards/${UserId}`).then((res) => {
      const item = res.data;
      setItem(item);
    });
    axios
      .get(`/api/Course?Lang=${lang}&UserId=${UserId}`)

      .then((res) => {
        const item = res.data;
        setCourses(item);
      });
  }, []);
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);

  return (
    <div className="bg">
      <div className="competition-codeavour-2022-dialog competition-codeavour-2022-dialog-body ">
        <div className="competition-codeavour-2022 container" onLoad={handleOnLoad}>
          <div className="competition-codeavour-2022-dialog competition-codeavour-2022-dialog-body ">
            <div className="competition-codeavour-2022-content">
              <div className="body-model row">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-once="false"
                  className="body-top"
                >
                  <h3 className="titles-color">
                    {i18next.t("competition-codeavour-2022-title")}
                  </h3>
                  <p>{i18next.t("competition-codeavour-2022-des")}</p>

                  <div
                    className="text-center body-top mt-5"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="mt-5 row gap-5 justify-content-center">
                      <div className="col">
                        <img className="img-w-2" src={img_h_1} alt="" />
                        <h5 className="text-center body-top titles-color">
                          {i18next.t("competition-header-1-title")}
                        </h5>
                      </div>
                      <div className="col ">
                        <img className="img-w-2" src={img_h_2} alt="" />
                        <h5 className="text-center body-top titles-color">
                          {i18next.t("competition-header-2-title")}
                        </h5>
                      </div>
                      <div className="col">
                        <img className="img-w-2" src={img_h_3} alt="" />
                        <h5 className="text-center body-top titles-color ">
                          {i18next.t("competition-header-3-title")}
                        </h5>
                      </div>
                      {/* old compition % */}
                      <div className="row">
                        <div className="col">
                          <img className="img-w-3" src={img_h_4} alt="" />
                        </div>
                        <div className="col">
                          <img className="img-w-3" src={img_h_5} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-once="false"
                  className="body-top"
                >
                  <h3 className="titles-color">
                    {" "}
                    {i18next.t("competition-codeavour-2022-objects")}
                  </h3>
                  <ul type="circle">
                    <li className="p-0">
                      <p>{i18next.t("competition-codeavour-2022-objects-1")}</p>
                    </li>
                    <li className="p-0">
                      <p>{i18next.t("competition-codeavour-2022-objects-2")}</p>
                    </li>
                    <li className="p-0">
                      <p>{i18next.t("competition-codeavour-2022-objects-3")}</p>
                    </li>
                  </ul>
                </div>

                {/* Thems */}
                <div
                  className="text-center body-top mt-5"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <h3 className="titles-color">{i18next.t("thems-title")}</h3>
                  <p>{i18next.t("thems-des")}</p>
                  <div className="mt-5 row gap-5 justify-content-center">
                    <div className="col">
                      <img className="img-w" src={img_1} alt="" />
                      <h3 className="text-center body-top col5">
                        {i18next.t("thems-1-title")}
                      </h3>
                    </div>
                    <div className="col ">
                      <img className="img-w" src={img_2} alt="" />
                      <h3 className="text-center body-top col3">
                        {i18next.t("thems-2-title")}
                      </h3>
                    </div>
                    <div className="col">
                      <img className="img-w" src={img_3} alt="" />
                      <h3 className="text-center body-top col1">
                        {i18next.t("thems-3-title")}
                      </h3>
                    </div>
                    <div className="col">
                      <img className="img-w" src={img_4} alt="" />
                      <h3 className="text-center body-top col2">
                        {i18next.t("thems-4-title")}
                      </h3>
                    </div>
                    <div className="col">
                      <img className="img-w" src={img_5} alt="" />
                      <h3 className="text-center body-top col4">
                        {i18next.t("thems-5-title")}
                      </h3>
                    </div>
                  </div>
                  {/* device des */}
                  <div data-aos="zoom-in" data-aos-delay="200">
                    <p className="text-center body-top">
                      {i18next.t("thems-dev-des")}
                    </p>
                    <div className=" row gap-4 justify-content-center">
                      <div className="col">
                        <img src={img_6} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-9")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_7} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-8")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_8} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-7")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_9} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-6")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_10} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-5")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_11} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-4")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_12} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-3")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_13} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-2")}
                        </h3>
                      </div>
                      <div className="col">
                        <img src={img_14} alt="" />
                        <h3 className="text-center body-top">
                          {i18next.t("thems-dev-title-1")}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="false"
                className="body-top mt-4">
                <h3>{i18next.t("competition-codeavour-2021-img")}</h3>
                <div className="row justify-content-center">
                <div className={`col-12 my-3 img-last-winer ${activeImage == 1? "active-img" : "col-sm-6"}`}>
                  <a  data-bs-toggle="modal"
          className="col-12 col-md-4"
                            data-bs-target="#videoModal"
                            href="#"  onClick={() => setSelectedImage(img_c_1) + setCloseVideo(false)} target="_blank">
                        <img src={img_c_1} alt="image" />
                      </a>
                      
                    
                  </div>
                  <div className={`col-12 my-3 img-last-winer ${activeImage == 2 ? "active-img" : "col-sm-6"}`}>
                  <a  data-bs-toggle="modal"
          className="col-12 col-md-4"
                            data-bs-target="#videoModal"
                            href="#"  onClick={() => setSelectedImage(img_c_2) + setCloseVideo(false)} target="_blank">
                        <img src={img_c_2} alt="image" />
                      </a>
                      
                    
                  </div>
                  <div className={`col-12 my-3 img-last-winer ${activeImage == 3? "active-img" : "col-sm-6"}`}>
                  <a  data-bs-toggle="modal"
          className="col-12 col-md-4"
                            data-bs-target="#videoModal"
                            href="#"  onClick={() => setSelectedImage(img_c_3) + setCloseVideo(false)} target="_blank">
                        <img src={img_c_3} alt="image" />
                      </a>
                      
                    
                  </div>
                  <div className={`col-12 my-3 img-last-winer ${activeImage == 4? "active-img" : "col-sm-6"}`}>
                  <a  data-bs-toggle="modal"
          className="col-12 col-md-4"
                            data-bs-target="#videoModal"
                            href="#"  onClick={() => setSelectedImage(img_c_4) + setCloseVideo(false)} target="_blank">
                        <img src={img_c_4} alt="image" />
                      </a>
                      
                    
                  </div>
                </div>
              </div>

              <div data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="false"
                className="body-bottom">
                <h3>{i18next.t("winners-cod2022-comp-title")}</h3>
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-1")}
                      path="/projects/109"

                      image={img_s_1}
                      description={i18next.t("competition-cod2022-project-1")}
                    //  path={""}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-2")}
                      path="/projects/107"
                      image={img_s_2}
                      description={i18next.t("competition-cod2022-project-2")}
                      
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-4")}
                      path="/projects/110"
                      image={img_s_3}
                      description={i18next.t("competition-cod2022-project-4")}
                    //  onClick={this.update(postDetails.id)}
                    // path={{
                    //   // pathname: `/course/${postDetails.id}`,
                    //   pathname: `/competition/${postDetails.id}`,
                    //   state: {
                    //     id: postDetails.id,
                    //   },
                    // }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-3")}
                      path="/projects/108"
                      image={img_s_4}
                      description={i18next.t("competition-cod2022-project-3")}
                    //  onClick={this.update(postDetails.id)}
                    // path={{
                    //   // pathname: `/course/${postDetails.id}`,
                    //   pathname: `/competition/${postDetails.id}`,
                    //   state: {
                    //     id: postDetails.id,
                    //   },
                    // }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-5")}
                      path="/projects/111"
                      image={img_s_5}
                      description={i18next.t("competition-cod2022-project-5")}
                    //  onClick={this.update(postDetails.id)}
                    // path={{
                    //   // pathname: `/course/${postDetails.id}`,
                    //   pathname: `/competition/${postDetails.id}`,
                    //   state: {
                    //     id: postDetails.id,
                    //   },
                    // }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-6")}
                      path="/projects/106"
                      image={img_s_6}
                      description={i18next.t("competition-cod2022-project-6")}
                    //  onClick={this.update(postDetails.id)}
                    // path={{
                    //   // pathname: `/course/${postDetails.id}`,
                    //   pathname: `/competition/${postDetails.id}`,
                    //   state: {
                    //     id: postDetails.id,
                    //   },
                    // }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardCompetitions
                      name={i18next.t("competition-cod2022-winner-7")}

                      image={img_s_7}
                      description={i18next.t("competition-cod2022-project-7")}
                      path="/projects/105"
                    //  onClick={this.update(postDetails.id)}
                    // path={{
                    //   // pathname: `/course/${postDetails.id}`,
                    //   pathname: `/competition/${postDetails.id}`,
                    //   state: {
                    //     id: postDetails.id,
                    //   },
                    // }}
                    />
                  </div>

                </div>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="false"
                className="body-bottom mt-5"
              >
                <h3 className="titles-color">
                  {i18next.t("competition-codeavour-2022-sponsor")}
                </h3>
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="card-sponsor">
                      <a href="https://codeavour.org/" target="_blank">
                        <img src={img_c_5} alt="link" title="اضغط هنا" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* sponsors */}
              <div
                className="body-top text-center mt-5"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <h3 className="titles-color">{i18next.t("sponsors-title")}</h3>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  className=" justify-content-center mt-5 container row text-center"
                >
                  <div className="col">
                    <img className=" img-w-sponsors" src={img_16} alt="" />
                  </div>
                  <div className="col">
                    <img className=" img-w-sponsors mt-3 pt-1" src={img_15} alt="" />
                  </div>
                  <div className="col pt-2">
                    <img className=" img-w-sponsors mt-5 pt-4" src={img_17} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-mobile-desktop">
            <div className="nav-chat">
              <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
                <img src={img_whatsapp} alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <ImageModal/>
      </div>
    </div>
  );
};

export default CompetitionCodeavour2022;
