import React, { Component, useEffect } from "react";
import "./CardHorizantalNotification.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
import Lottie from "lottie-web";
import anim_notification from '../../assets/images/overview/notifiation.json';
//import ReactDOM from 'react-dom';
const CardHorizantalNotification = (props) => {
  useEffect(() => {
  Lottie.loadAnimation({
    container: document.getElementById('card-img-icon-2'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: anim_notification
  })
  }, []);
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <Link to={props.path}>
          <div className="card-horizantal-notification">
            <div className="d-flex">
              <img src={props.image} className="card-img-top" alt="Image Corse" />
              <div className="card-body">
                <p className="card-text">{props.name}</p>
                <div className="m-2"></div>
              </div>
            </div>
            <div>
            <div id="card-img-icon-2" className="m-2"></div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default CardHorizantalNotification;
