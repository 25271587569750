import React, { Component } from "react";
import LockModel from "../LockModel/LockModel";
import "./CardLockIQTestLevel.scss";
import img_close from "../../assets/images/test/locked-padlock.png";

//import ReactDOM from 'react-dom';
const CardLockIQTestLevel = (props) => {
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className={`card`}>
          <a
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#lockModal"
            href="#"
          >
            {/* <img
              src="/src/assets/images/IQ-Test-382x300.jpg"
              className="card-img-top"
              alt="Image Corse"
            /> */}
            <div className="card-body">
              <div className="card-text">
                 <img src={img_close} alt="icon" />  
                {props.text}
              </div>
              <div className="m-2"></div>
            </div>
          </a>
        </div>
      </div>
      <LockModel/>
    </div>
  );
};
export default CardLockIQTestLevel;
