import React from "react";
import "./ImageModal.scss";
import img_c_1 from "../../pages/Competitions/CompetitionCodeavour2022/Images/1.png";
import { selectedImageState, closeVideoState } from "../../atom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";

const ImageModal = (props) => {
  const [selectedImage, setSelectedImage] = useRecoilState(selectedImageState);
  const [closeVideo, setCloseVideo] = useRecoilState(closeVideoState);
  const element = document?.getElementById("videoModal");
  const showVideo = element?.classList.contains("some-class");

  useEffect(() => {
    if (closeVideo) {
      element?.classList.remove("show");
      element?.classList.add("hide");
    } else {
      element?.classList.remove("hide");
      element?.classList.add("show");
    }

    
  }, [closeVideo, showVideo]);

  return (
    <div
      className="modal fade"
      id="videoModal"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
      onClick={() => setCloseVideo(true)}
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body"></div>
          <div
            className="tab-content img-modal-body tab-content-video"
            id="pills-tabContent"
          >
            <img className="image" src={selectedImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ImageModal;
