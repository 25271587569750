export default [
  {
    id: 1,
    en: "Baghdad",
    ar: "بغداد",
  },
  {
    id: 2,
    en: "Al-Basrah",
    ar: "البصرة",
  },
  {
    id: 3,
    en: "Al-Muthanna",
    ar: "المثنى",
  },
  {
    id: 4,
    en: "Al-Qadisiyyah",
    ar: "القادسية",
  },
  {
    id: 5,
    en: "An-Najaf",
    ar: "النجف",
  },
  {
    id: 6,
    en: "Arbil",
    ar: "أربيل",
  },
  {
    id: 7,
    en: "As-Sulaymaniyyah",
    ar: "السليمانية",
  },
  {
    id: 8,
    en: "Babil - Babylon",
    ar: "بابل",
  },

  {
    id: 9,
    en: "Al-Anbar",
    ar: "الأنبار",
  },
  {
    id: 10,
    en: "Dhi Qar",
    ar: "ذي قار",
  },
  {
    id: 11,
    en: "Diyala",
    ar: "ديالى",
  },
  {
    id: 12,
    en: "Duhok",
    ar: "دهوك",
  },
  {
    id: 13,
    en: "Halabjah",
    ar: "حلبجة",
  },
  {
    id: 14,
    en: "Karbala",
    ar: "كربلاء",
  },
  {
    id: 15,
    en: "Kirkuk",
    ar: "كركوك",
  },
  {
    id: 16,
    en: "Maysan",
    ar: "ميسان",
  },
  {
    id: 17,
    en: "Mosul",
    ar: "موصل",
  },
  {
    id: 18,
    en: "Tikrit",
    ar: "صلاح الدين",
  },
  {
    id: 19,
    en: "Wasit",
    ar: "واسط",
  },
];
