import React from "react";
import "./Why.scss";
import{Link} from "react-router-dom"
import WhyPoint from "../WhyPoint/WhyPoint";
import Button from "../Button/Button";
import i18next from "i18next";
//import ReactDOM from 'react-dom';
const Why = (props) => {
  // hidden and show btn register in why iotkids
  let whybtn;
  if(props.user){
    whybtn = (
      <div className="why-btn d-none">
        <Button name={i18next.t("home-s3-btn")} />
      </div>
    );
  }else{
    whybtn = (
      <Link to="/register" className="why-btn">
        <Button name={i18next.t("home-s3-btn")} />
      </Link>
    );
  }
  return (
    <div className="why">
      <div className="container">
        <h2 className="why-title"> {i18next.t("home-s3-title")}</h2>
        <div className="row">
          
          <div className="col-lg-3 col-md-6 col-sm-12">
            <WhyPoint num={1} text={i18next.t("home-s3-point-1")} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <WhyPoint num={2} text={i18next.t("home-s3-point-2")} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <WhyPoint num={3} text={i18next.t("home-s3-point-3")} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <WhyPoint num={4} text={i18next.t("home-s3-point-4")} />
          </div>
          </div>
        </div>
        {whybtn}
      </div>
    
  );
};
export default Why;
