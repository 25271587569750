import React from "react";
import "./CardVideo.scss";
import { useEffect } from "react";
import Lottie from "lottie-web";
import anim_play from "../../assets/images/anim-play.json";
import { useRecoilState } from "recoil";
import { projectState } from "../../atom";
import { useHistory } from "react-router-dom";
import StartRating from "../StarRating";
import i18next from "i18next"

//import ReactDOM from 'react-dom';
const CardVideo2 = (props) => {
  const [project, setProject] = useRecoilState(projectState);
  let history = useHistory();

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.getElementById("play-icon"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_play,
    });
  }, []);
  return (
    <div
      className="row"
      onClick={() => {
        setProject(props);
        history.push(props.path);
      }}
    >
      <div className="col-12">
        <div className="card-video" data-aos="flip-down" data-aos-delay="200">
      
          <a
            className="card-price-btn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#videoModal"
            href="#"
          >
              {props.status === "Pending" && (
                <div className="status">
                  <span>قيد المراجعة</span>
                </div>
              )}
            <img
              src={`https://vumbnail.com/${props?.video}.jpg`}
              className="card-img-top"
              alt="Image Corse"
            />
            <div className="wrapper">
              <div className="circle pulse"></div>
              <div className="circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                  <polygon points="40,30 65,50 40,70"></polygon>
                </svg>
              </div>
            </div>
            <div className="card-body">
              <p className="card-text">{props.name || "...."}</p>
              {props?.rate ? <StartRating rating={props.rate} /> : <p className="new">{i18next.t("project-new")}</p>}
              <div className="m-2"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default CardVideo2;
