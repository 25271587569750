import i18next from "i18next";
import React, { useRef, useEffect } from "react";
import { usePDF } from 'react-to-pdf';
import Button from "../Button/Button";
import './Certificate.scss';

const Certificate = (props) => {
    const { toPDF, targetRef } = usePDF({
        filename: 'certificate.pdf',
        options: {
            orientation: "landscape",
            unit: "in",
            format: [8.4, 6.20],
        }
    });

    return (
        <div className="certificate-page">
            <div className="certificate pt-5" ref={targetRef}>
                <div className="certificate-title">
                    {/* <h1>Certificate of Completion</h1>
          <h3>This Acknowledges That</h3> */}
                </div>
                <div className="certificate-body">
                    <h3>{props.fullName}</h3>
                    {/* <p>
            Has successfully completed online {props.courseName} course powered
            by IOT Kids and Zain Iraq on <br /> (26/09/2020)
          </p> */}
                    <h6>{props.courseName}</h6>
                </div>
            </div>

            <div className="certificate-show">
            </div>

            <a className="btn-w" onClick={() => toPDF()}>
                <Button name={i18next.t('certificate-btn')} />
            </a>
        </div>
    );
};

export default Certificate;