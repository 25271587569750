import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import img_password from "../../../assets/images/login/password.png";
import img_fail from "../../../assets/images/login/info.png";
import img_404 from "../../../assets/images/charecter/boycry.png";
import img_done from "../../../assets/images/login/success.png";
import img_hello from "../../../assets/images/hello.png";

import "./ForgetPasswordEmail.scss";
import axios from "axios";
import i18next from "i18next";
const IQTestLevels = () => {
  const [email, setEmail] = useState(null);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const lang = localStorage.getItem("Lang");
  const [loading, setLoading] = useState(false);

  function sendEmail() {
    setLoading(true);
    axios
      .get(
        `https://ahmeddawood88-003-site1.etempurl.com/api/users/ForgetPassword/${email}/${lang}`
      )
      .then((res) => {
        setDone(true);
        setLoading(false);
      }).catch((err) => {
        setError(true)
        setLoading(false);
      })
  }
  return (
    <div className="container">
      <div className="password">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-4">
            {done ? (
              <div className="password-card">
                <div className="password-card-header">
                  <img className="password-h-img" src={img_done} alt="password" />
                  <h3>{i18next.t("password-h-sucsess")}</h3>
                </div>
                <div className="password-card-body">
                  <img className="img_sucsess" src={img_hello} alt="error" />
                  <h3 className="text-sucsess">{i18next.t("password-b-sucsess")}</h3>
                </div>
              </div>) : error ? (
                <div className="password-card">
                  <div className="password-card-header">
                    <img className="password-h-img" src={img_fail} alt="password" />
                    <h3>{i18next.t("password-h-error")}</h3>
                  </div>
                  <div className="password-card-body">
                    <img className="img_sucsess" src={img_404} alt="error" />
                    <h3 className="text-sucsess">{i18next.t("password-b-error")}</h3>
                    <a className="pw-btn-error" href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" >
                      <Button name={i18next.t("password-b-btn")} />
                    </a>
                  </div>
                </div>) : (
              <div className="password-card">
                <div className="password-card-header">
                  <img src={img_password} alt="password" />
                  <h3>{i18next.t("password-h")}</h3>
                </div>
                <div className="password-card-body">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder={i18next.t("password-input")}
                  />
                  <Button onClick={() => email == null ? "": sendEmail()}  name={loading ? 
                      (<div className="spinner-border text-loadding-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>) : i18next.t("password-btn")} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default IQTestLevels;
