import Button from "../../../components/Button/Button";
import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Overview.scss";
import axios from "axios";
import i18next from "i18next";
import CardOverview from "../../../components/CardOverview/CardOverview";
import img_courses from "../../../assets/images/overview/course.png";
import img_complete from "../../../assets/images/overview/course-complete.png";
import img_iqtest from "../../../assets/images/overview/iqtest.png";
import img_point from "../../../assets/images/overview/point.png";
import img_map from "../../../assets/images/overview/map.png";
import img_gift from "../../../assets/images/overview/gift.png";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import OverviewAllCourseModel from "../../../components/OverviewAllCourseModel/OverviewAllCourseModel";
import OverviewCompletedCourseModel from "../../../components/OverviewCompletedCourseModel/OverviewCompletedCourseModel";
import OverviewIQTestModel from "../../../components/OverviewIQTestModel/OverviewIQTestModel";
import OverviewPointsModel from "../../../components/OverviewPointsModel/OverviewPointsModel";
import Lottie from "lottie-web";
import anim_ref from "../../../assets/images/overview/ref.json";
import anim_start from "../../../assets/images/overview/start-course.json";
import anim_notification from "../../../assets/images/overview/notifiation.json";
import OverviewNotificationModel from "../../../components/OverviewNotificationModel/OverviewNotificationModel";
import StartCreatingModal from "../../../components/StartCreatingModal/StartCreatingModal";


const Overview = () => {

  const idUser = localStorage.id;

  // state to save data form api
  const [item, setItem] = useState([]);
  const [copoun, setCopoun] = useState("");

  // get data form api
  useEffect(() => {
    axios.get(`/api/SLPCourse/Profile/${idUser}`).then((res) => {
      const item = res.data;
      setItem(item);
      setCopoun(item.refCode);
    });
    Lottie.loadAnimation({
      container: document.getElementById("card-ref-overview-anim"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_ref,
    });

    Lottie.loadAnimation({
      container: document.getElementById("card-img-icon"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_notification,
    });
    Lottie.loadAnimation({
      container: document.getElementById("card-start-overview-anim"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_start,
    });
  }, []);


  return (
      <div className="container">
        <MenuProfile />
        <div className="overview">
          <div className="anim-card-overview-input ">
            <div className="card-ref-overview">
              <div
                  id="card-ref-overview-anim"
                  className="card-ref-overview-anim "
              ></div>
              <h6>{i18next.t("ref-text")}</h6>
              <div className="coupon-code">
                <h4>{i18next.t("ref-input-title")}</h4>
                <input id="myInput" value={copoun} type="text" />
                <button
                    className="btn-copy"
                    onClick={() => {
                      navigator.clipboard.writeText(copoun);
                    }}
                >
                  {i18next.t("ref-copy-btn")}
                </button>
              </div>
            </div>
          </div>
          {item.noOfCourses == 0 && (
              <div>
                <div className="Overview">
                  <div className="anim-card-overview-input ">
                    <div className="card-ref-overview ">
                      <div
                          id="card-start-overview-anim"
                          className="card-ref-overview-anim-2"
                      ></div>
                      <h6 className="">{i18next.t("start-now-card-des")}</h6>
                      <div className="">
                        <a
                            className="btn-featured-courses-overview"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#start-creating-Modal"
                            href="#"
                        >
                          <Button name={i18next.t("popup-btn-text")} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          )}
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="anim-card-overview">
                <div
                    className="card-overview"
                    onClick={() =>
                        localStorage.setItem("open-notification", true)
                    }
                >
                  <a
                      data-bs-toggle="modal"
                      data-bs-target="#overview-notificationModal"
                      href=""
                  >
                    <div id="card-img-icon" className="card-anim-icon"></div>
                    <div className="card-body m-0">
                      <h3 className="card-overview-text">
                        {i18next.t("overview-notification-title")}
                      </h3>
                      <p className="card-overview-num mx-2">
                      <span className="mx-2">
                        {item.noOfPoints >= 400
                            ? 4
                            : item.noOfPoints >= 250
                                ? 3
                                : item.noOfPoints >= 150
                                    ? 2
                                    : item.noOfPoints >= 50
                                        ? 1
                                        : 0}
                      </span>
                      </p>
                      <div className="m-2"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <a
                  data-bs-toggle="modal"
                  data-bs-target="#overview-all-courseModal"
                  href=""
              >
                <CardOverview
                    image={img_courses}
                    name={i18next.t("overflow-text-1")}
                    num={item.noOfCourses}
                />
              </a>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <a
                  data-bs-toggle="modal"
                  data-bs-target="#overview-completed-courseModal"
                  href=""
              >
                <CardOverview
                    image={img_complete}
                    name={i18next.t("overflow-text-2")}
                    num={item.completedCourses}
                />
              </a>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <a
                  data-bs-toggle="modal"
                  data-bs-target="#overview-iqtestModal"
                  href=""
              >
                <CardOverview
                    image={img_iqtest}
                    name={i18next.t("overflow-text-3")}
                    num={item.completedLevels}
                />
              </a>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <a
                  data-bs-toggle="modal"
                  data-bs-target="#overview-pointModal"
                  href=""
              >
                <CardOverview
                    image={img_point}
                    name={i18next.t("overflow-text-4")}
                    num={item.noOfPoints}
                />
              </a>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <a>
                <CardOverview
                    image={img_map}
                    name={i18next.t("overflow-text-5")}
                    num={
                      item.noOfPoints >= 400
                          ? 4
                          : item.noOfPoints >= 250
                              ? 3
                              : item.noOfPoints >= 150
                                  ? 2
                                  : item.noOfPoints >= 50
                                      ? 1
                                      : 0
                    }
                    path="/points"
                />
              </a>
            </div>
          </div>
          <div className="row my-3 justify-content-center">
            <div className="my-2 col-12 col-md-6 col-lg-4">
              <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول">
                <Button name={i18next.t("overflow-btn-1")} />
              </a>
            </div>
            <div className="my-2 col-12 col-md-6 col-lg-4">
              <Link to="/mycourses">
                <Button name={i18next.t("overflow-btn-2")} />
              </Link>
            </div>
            <div className="my-2 col-12 col-md-6 col-lg-4">
              <Link to="/iqtest">
                <Button name={i18next.t("overflow-btn-3")} />
              </Link>
            </div>
          </div>
        </div>
        <OverviewAllCourseModel />
        <StartCreatingModal name={i18next.t("start-now-btn-text")} />
        <OverviewCompletedCourseModel />
        <OverviewIQTestModel />
        <OverviewPointsModel />
        <OverviewNotificationModel num={item.noOfPoints} />
      </div>
  );
}


export default Overview;