import "./style.scss";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { shopTotalDiscountState, shopTotalPriceState, basketState } from "../../atom";
import i18next from "i18next";
import img_card from "../../assets/images/visa-master.png";
import img_zaincash from "../../assets/images/zaincash.png";
import img_delivery from "../../assets/images/pay/delivery-man.png";
import Zood_pay from "../../assets/images/zoodpay.png"
import SwitchShop from "../../components/SwitchShop/Switch";
import Provinces from "./provinces";
import { useHistory } from "react-router-dom";
import Dialoge from "../../components/Dialoge/Dialoge";
import ZoodPayModal from "../../components/ZoodPayModal/ZoodPayModal";
import axios from "axios";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";


const ZCModalInfo = {
  title: "اعادة توجيه",
  payType: "زين كاش",
  msg: "جاري تحويلك الى الموقع الرسمي لاكمال عملية الدفع",
  btnTitle: "اغلاق",
  isSuccess: false,
};

const ShopPay = () => {

  const handleButtonClick = () => {
    const username = '.uPL)@nSW2xu4zk';
    const password = 'jW4wB)pX*HxrdE}*';
  
    const headers = new Headers({
      'Authorization': 'Basic ' + btoa(username + ':' + password),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
  
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ market_code:"IQ" })
    };
  
    fetch('https://sandbox-api.zoodpay.com/v0/configuration', requestOptions)
      .then(response=>response.json())
      .then(data=>setData(data))
      
  };
  
  const history = useHistory();
  const [total, setTotal] = useRecoilState(shopTotalPriceState);
  const [basket] = useRecoilState(basketState);
  const [discount, setDiscount] = useRecoilState(shopTotalDiscountState);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState(
    i18next.language === "ar" ? Provinces[0].ar : Provinces[0].en
  );
  const [area, setArea] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activePay, setActivePay] = useState([]);
  const [zainCashURL, setZainCashURL] = useState("")
  const [data1, setData] = useState(null);

 
  useEffect(() => {
    if (total === 0) {
      history.goBack();
    }
  }, [total]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("iot_user")) || null;
    if (user) {
      setName(user.fullName);
    }
  }, []);

  const isPay = name && phone && city && area;

  const handleCheckout = () => {
    let costumerInfo = { name, phone, city, area };
    localStorage.setItem("iot_customer", JSON.stringify(costumerInfo));
    history.push(
      "processPayment?isShope=true&paymentMethod=Cash&orderStatus=Sent"
    );
  };

  const initZCSession = (e) => {
    let costumerInfo = { name, phone, city, area };
    localStorage.setItem("iot_customer", JSON.stringify(costumerInfo));

    e.preventDefault();
    // update modal info to suite Zain Cash

    setLoading(true);
    setShowModal(true);
    setModalInfo(ZCModalInfo);
    const activeprodect = activePay;
    let userID = localStorage.getItem("id");
    let orderID = userID + activeprodect.id;
    
    const data = {
      userId: userID,
      custName: name,
      custPhoneNo: phone,
      custCity: city,
      custArea: area,
      totalAmt: 0,
      currency: "IQD",
      status: "Paid",
      paymentMethod: "Zain Cash",
      details: basket.map((el) => [el.id, el.qty]),
      switchCheckOutId: "",
    };


    
  
    
    axios
      .post(
        `https://ahmeddawood88-003-site1.etempurl.com/api/ZainCash/GenerateStoreZainCashUrl`,
        data
      )
      .then((res) => {
        // get URL from the response
        let redirectedURL = res.data;
        // console.log("=====redirectedURL====");
        // console.log(redirectedURL);
        setZainCashURL(redirectedURL)
        let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
        if (safariAgent) {
          window.open(redirectedURL, "_blank").focus();
        } else {
          window
            .open(
              redirectedURL,
              "zc_popup_window",
              `left=100,top=100,width=570,height=${window.screen.height - "10%"
              }`
            )
            .focus();
        }

        console.log("end of the window");
        setLoading(false);
        setShowModal(false);
        setModalInfo({
          ...modalInfo,
          title: "",
          msg: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowModal(false);
        setModalInfo({
          ...modalInfo,
          title: "فشل",
          msg: "هناك خلل ما, يرجى اعادة المحاولة",
          isSuccess: false,
        });
      });
  };

  const closeDialog = (e) => {
    setLoading(false);
    setShowModal(false);
  };

  return (
    <div className="shop-pay">
      <div className="container my-4">
        <div className="payment-card">
          <section className="info">
            <div className="app-flex">
            <h5>{i18next.t("payment-title")}</h5>
            <div className="back-btn-1">
            <button onClick={() => history.goBack()} className="btn-back">
            {i18next.t("back-to-invoice")}
          </button>
          </div>
            </div>
            <br />
            <div className="mb-3">
              <div className="row">
                <div className="col-md-8 col-sm-12 mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    {i18next.t("payment-form-name")}
                  </label>
                  <input
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder={i18next.t("payment-form-name-hint")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 col-sm-12 mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label">
                    {i18next.t("payment-form-phone")}
                  </label>
                  <input
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder={i18next.t("payment-form-phone-hint")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label">
                    {i18next.t("payment-form-province")}
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {Provinces.map((el) => (
                      <option
                        key={el.id}
                        value={i18next.language === "ar" ? el.ar : el.en}
                      >
                        {i18next.language === "ar" ? el.ar : el.en}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-8 col-sm-12 mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label">
                    {i18next.t("payment-form-city")}
                  </label>
                  <input
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder={i18next.t("payment-form-city-hint")}
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="price">
            <h5> {i18next.t("payment-money")}</h5>
            <h1>
              {Number(total).toLocaleString("en")}
              <span> {i18next.t("card-currency")}</span>
            </h1>
            {/* <h4>{i18next.t("pay-now")}</h4> */}
          </section>{" "}
          <section className="actions">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <a
                  onClick={() => {
                    let costumerInfo = { name, phone, city, area };
                    localStorage.setItem("iot_customer", JSON.stringify(costumerInfo));
                  }}
                  className={isPay ? "paynow-btn" : "paynow-btn disabled-link"}
                  id="switch-pay-btn"
                >
                  <img src={img_card} alt="card image" />
                  <div className="switch-btn-shop">
                    <SwitchShop
                      courseId={1}
                      orderNo={12}
                      couponId={null}
                      couponID={21}
                      priceIQD={total}
                      refCode={"2321"}
                    />
                  </div>
                  {i18next.t("pay-btn-card")}
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <a
                  id="zaincash-pay-btn"
                  onClick={initZCSession}
                  className={isPay ? "paynow-btn" : "paynow-btn disabled-link"}
                >
                  <img src={img_zaincash} alt="card image" />
                  {i18next.t("pay-btn-zain")}
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <a
                  className={isPay ? "paynow-btn" : "paynow-btn disabled-link"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img
                    className="img-delivery"
                    src={img_delivery}
                    alt="card image"
                  />
                  {i18next.t("pay-delivery")}
                </a>
              </div>
              {/* zood pay gateway */}
            {/*   <div className="col-md-4 col-sm-12">
                <a
                onClick={handleButtonClick}
                  className={isPay ? "paynow-btn" : "paynow-btn disabled-link"}
                  data-bs-toggle="modal" data-bs-target="#ZoodpayModal"
                >
                  <img
                    className="img-delivery"
                    src={Zood_pay}
                    alt="card image"
                  />
                  {i18next.t("pay-btn-zoodpay")}
                </a>
              </div> */}
            </div>
          </section>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="modal-title fs-4 pt-2" id="exampleModalLabel">
                {i18next.t("pay-confirm-title")}
              </h1>

              <p className="pt-2"> {i18next.t("pay-confirm-desc")}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                {i18next.t("pay-cancel-btn")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCheckout}
              >
                {i18next.t("pay-confirm-btn")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal ? (
        <Dialoge
          closeDialog={closeDialog}
          isLoading={loading}
          info={modalInfo}
        />
      ) : null}
      <ZoodPayModal Price={Number(total).toLocaleString("en")} />
      {localStorage.setItem("TotalPrice", Number(total))}
      <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
    
  );
};

export default ShopPay;
