import React, { useEffect, useState } from "react";
import "./OverviewIqCourseModel.scss";
import i18next from "i18next";
import CardProgress from "../CardProgress/CardProgress";
import axios from "axios";
import Button from "../Button/Button";
import img_sorry from "../../assets/images/my-courses/sorry.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Card from "../Card/Card";

const OverviewIqCourseModel = (props) => {
  const idUser = localStorage.id;
  const [item, setItem] = useState([]);
  const [profileSummary, setProfileSummary] = useState({});
  useEffect(() => {
    axios.get(`/api/SLPCourse/${idUser}`).then((res) => {
      const item = res.data;
      const newItems = item.filter(
        (item) =>
          item.courseId === 30 || item.courseId === 31 || item.courseId === 33
      );
      setItem(newItems);
    });
  }, []);

  return (
    <div
      className="modal overview-iq-course-modal fade"
      id="overview-iq-courseModal"
      aria-labelledby="overview-iq-courseModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="header-model">
              <h2 className="header-model-title fs-4 fw-demi-bold">
                اختبارات الذكاء
              </h2>
              <button
                type="button"
                className="btn-close-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >X</button>
            </div>
            <div className="body-model">
              <div className="row">
                {item.map((mycourses, index) => {
                  return (
                    <>
                      
                      <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <CardProgress
                          name={mycourses?.course.title}
                          percentage={mycourses?.completedPercent}
                          image={mycourses?.course.ref03}
                          path={{
                            // pathname: `/course/${postDetails.id}`,
                            pathname: `courselessons/${mycourses?.courseId}`,
                            state: {
                              courseId: mycourses?.courseId,
                            },
                          }}
                        />
                      </div>
                    </>
                  );
                })}
                <div className="col-12 col-md-6 col-lg-4 mb-3 pb-5">
                  <CardProgress
                    iqquestion="true"
                    name="الاسئلة الوزارية للسنوات السابقة لاختبار الذكاء"
                    path="/prevquestions"
                    description="اسئلة السنين السابقة لاختبارات الذكاء لسنة 2019-2020-2021-2022"
                    image="https://messarat.com/images/course%20images/rsz_1iq_test.jpg"
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewIqCourseModel;
