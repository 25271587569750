import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import axios from "axios";
import i18next from "i18next";
import AOS from "aos";
import { initializeApp } from 'firebase/app';

import Root from './root';
import reportWebVitals from './reportWebVitals';

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "aos/dist/aos.css";
import "./i18n";

// Initialize language
const lang = localStorage.getItem("lang") || "ar";
i18next.changeLanguage(lang);
document.documentElement.lang = lang;

// Configure axios
axios.defaults.baseURL = "https://ahmeddawood88-003-site1.etempurl.com";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
axios.defaults.headers.common["Accept-Language"] = lang;

// Initialize AOS
AOS.init();

// Create root and render the app
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>
);

reportWebVitals();