import React, { useEffect, useState } from "react";
import { RecoilRoot, useRecoilValue, useSetRecoilState } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import AppHeader from "./components/AppHeader/AppHeader";
import AppFooter from "./components/AppFooter/AppFooter";
import RouterApp from "./components/Router/RouterApp";
import Loading from "./components/Loading/Loading";
import "./i18n";
import AppFooterMobile from "./components/AppFooterMobile/AppFooterMobile";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { basketState, userState, userDataState } from "./atom";
import { apiGetUserData } from "./services/api/generalApi";
import AuthenticationWrapper from "./components/AuthenticationWrapper/AuthenticationWrapper";
import $ from "jquery";

const InitApp = ({ children }) => {
    const setBasket = useSetRecoilState(basketState);

    useEffect(() => {
        const basketSaved = localStorage.getItem("iot_basket");
        if (basketSaved) {
            try {
                setBasket(JSON.parse(basketSaved));
            } catch (error) {
                console.error("Error parsing basket data:", error);
                localStorage.removeItem("iot_basket");
            }
        }
    }, [setBasket]);

    return <>{children}</>;
};

const AppContent = () => {
    const user = useRecoilValue(userState);
    const setUserData = useSetRecoilState(userDataState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserData = async () => {
            if (user) {
                try {
                    const data = await apiGetUserData();
                    if (data.fullName) setUserData(data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
            setIsLoading(false);
        };

        getUserData();
    }, [user, setUserData]);

    useEffect(() => {
        if (!localStorage.getItem("openStartVideo")) {
            localStorage.setItem("openStartVideo", "false");
            setTimeout(() => {
                localStorage.setItem("openStartVideo", "true");
            }, 15000);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => $('.page-loader').fadeOut('slow'), 3000);
        setTimeout(() => $(".page-loader-2").fadeOut("slow"), 1);
    }, []);

    if (isLoading) {
        return (
            <div className="page-loader">
                <Loading />
            </div>
        );
    }

    return (
        <div className="App">
            <AppHeader />
            <RouterApp />
            <AppFooterMobile />
            <AppFooter />
        </div>
    );
};

const App = () => {
    return (
        <RecoilRoot>
            <Router>
                <ScrollToTop />
                <AuthenticationWrapper>
                    <InitApp>
                        <AppContent />
                    </InitApp>
                </AuthenticationWrapper>
            </Router>
        </RecoilRoot>
    );
};

export default App;