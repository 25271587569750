const ar ={
  "lang": "ar",
  "LANG": "AR",
  "language": "اللغة",

  "header-home": "الصفحة الرئيسية",
  "header-courses": "المسارات",
  "header-projects": "المشاريع",
  "header-shop": "المتجر",
  "header-about": "من نحن",
  "header-competitions": "المسابقات",
  "header-signin": "تسجيل الدخول",
  "header-profile": "الملف الشخصي",
  "home-s1-text": "تحضر لمسابقة الذكاء الاصطناعي",
  "home-s1-text-p": "تحضر الان لمسابقة الذكاء الاصطناعي من خلال حزمة من الدورات التحظيرية للمسابقة. اشترك الان و احصل على خصم 35% على الدورات التحضيرية ",
  "home-s1-text_2": "تعلم اساسيات البرمجة",
  "home-s1-text-p_2": "في هذه المجموعة من الكورسات سيكتسب الطفل مهارات جديدة التي بدورها تعمل على تقوية مخيلته على التفكير والتصميم الابداعي والمنطقي حيث يتعلم البرمجة بصورة سهلة وممتعة. اشترك الان واحصل على خصم 25% ",
  "home-s1-btn": "اشتر المسار",
  "home-s2-title": "الدورات المميزة",
  "home-s2-btn": "المزيد من الدورات",
  "invaild-phone-number":"الرقم غير صحيح",
  "home-s3-title": " لماذا دورات  مسارات ؟ ",
  "home-s3-point-1": "استثمر في أطفالك في سن مبكرة",
  "home-s3-point-2": "لا حدود لخيال الأطفال مع IoT KIDS ",
  "home-s3-point-3": "لا مزيد من إدمان الهاتف المحمول",
  "home-s3-point-4": "حل المشكلات والتفكير المنطقي هو مفتاح نجاح أطفالك",
  "home-s3-btn": "ابدأ التعلم الان",
  "home-s4-title": "مشاريع ابطالنا",
  "home-s4-btn": "اصنع مشروعك الان",
  "explore-roadmap": "استكشاف المسار",
  "go-to-roadmap":"اذهب الى المسار",
  "home-s5-title": "دعنا نساعدك في اختيار الدورة المناسبة لصناعة مشروعك",
  // "home-offers-btn":"العروض على الدورات ",
  "home-s6-title": "سفراء مسارات",
  "home-offers-btn":"العروض ",
  "change-lang-t": "هذه الدورة باللغة الانكليزية",
  "change-lang-d": "يجب تغيير اللغة الى اللغة الانكليزية للمتابعة",
  "change-lang-btn": "التغيير الى اللغة الانكليزية",
  "more-icon-text":"المزيد",

  "courses-nav-1": "الدورات المميزة",
  "courses-nav-2": "مسار البرمجيات",
  "courses-nav-3": " الروبوتيكس",
  "courses-nav-4": "التصميم",
  "courses-nav-5": "مسار الالكترونيك والتصميم",
  "courses-nav-6": "مسار اختبارات الذكاء",
  "courses-nav-7": "مسار الذكاء الاصطناعي",
  "courses-details": "تفاصيل الدورة",

  "ref-text": "أرسل كود الخصم إلى أصدقائك واحصل على 20 نقطة على كل دورة يشتريها اصدقائك .",
  "ref-input-title": "كود الخصم :",
  "ref-copy-btn": "نسخ",

  "competitions-title": "المسابقات",
  "competitions-des": " IoT KIDS تقيم كل سنة مجموعة من المسابقات المحلية والدولية منها مسابقة اختبار الذكاء و مسابقة Codeavour و مسابقة كأس العرب و مسابقة Coder  1000",
  "competitions-finsh": "انتهت",
  "competitions-online": "مستمرة",
  "about-s1-title": "هل تعلم ان",
  "about-s1-des": "IoT KIDS هي الشركة الأولى من نوعها في العراق اللتي تمكنك من الاستثمار في وقت أطفالك وإبداعهم. حيث يوفر IoT KIDS مجموعة من الدورات عبر الإنترنت للأطفال الذين تتراوح أعمارهم بين 5 و 15 عامًا في تصميم الألعاب والروبوتات والبرمجة والإلكترونيات والذكاء الاصطناعي. لدينا أكبر مجتمع في العراق للأطفال ، ونعمل حاليًا في 9 مدن مختلفة في جميع أنحاء البلاد. هدفنا هو القضاء على إدمان الأطفال للهواتف الذكية وتشجيع الأطفال على استخدام التكنولوجيا بالطريقة الصحيحة من أجل الابتكار والاختراع في سن مبكر.",
  "about-s2-title-1": "رسالتنا",
  "about-s2-des-1": "تزويد الأطفال بالمعرفة والمهارات اللازمة طوال حياتهم  لحل مشاكل القرن الحادي والعشرين وما بعده.",
  "about-s2-title-2": "رؤيتنا",
  "about-s2-des-2": "تمكين الجيل الجديد لتنفيذ مهارات التفكير الابداعي وتطوير القدرات اللازمة للبحث والإنتاج والتواصل.",
  "job-details-title":"تفاصيل الوظيفة ",
  "job-details-desc":"نبحث عن مطور واجهة مستخدم أمامية ماهر ومتحمس للانضمام إلى فريقنا. ستلعب دورًا حاسمًا في تصميم وتنفيذ واجهة المستخدم لمنصتنا التعليمية. ستعمل عن كثب مع فرقنا التشغيلية المتعددة لإنشاء مواقع الويب المدهشة بصريًا وسهلة الاستخدام والتي توفر تجربة مستخدم استثنائية. إذا كنت متحمسًا لإنشاء حلول رقمية مبتكرة وتتمتع بفهم قوي لتقنيات تطوير الواجهة الأمامية، فسنكون سعداء بالتواصل معك",
  "job-details-title-2":"المسؤوليات",
  "resp-1":"التعاون مع المصممين ومطوري الواجهة الخلفية لتحويل تصاميم واجهة المستخدم إلى واجهات ويب عملية.",
  "resp-2":"تطوير كود نظيف وفعال وقابل للتطوير باستخدام HTML و CSS و JavaScript وReact JS",
  "resp-3":"تحسين المواقع للحصول على أقصى سرعة وقابلية للتوسع.",
  "resp-4":"ضمان التوافق مع متصفحات متعددة ومنصات مختلفة",
  "resp-5":"تنفيذ تصميمات استجابة لضمان تجربة مستخدم سلسة عبر الأجهزة المختلفة. ",
  "resp-6":"إجراء اختبارات وتصحيح أخطاء شاملة لضمان الوظائف السلسة.  ",
  "resp-7":"البقاء على اطلاع بأحدث الاتجاهات وأفضل الممارسات في تطوير الواجهة الأمامية.  ",
  "job-details-title-3":"المتطلبات",
  "req-1":"درجة البكالوريوس في علوم الحاسبات أو مجال ذي صلة (أو خبرة عمل مكافئة).",
  "req-2":"خبرة عمل مثبتة كمطور واجهة أمامية لا تقل عن سنتين",
  "req-3":"إلمام قوي بلغات HTML وCSS وJavaScript والإطارات / المكتبات المرتبطة بها فهم قوي لمبادئ التصميم المستجيب والتطوير الأول للهواتف المحمولة.",
  "req-4":"معرفة بأنظمة التحكم في الإصدار (مثل Git) ومنصات التعاون البرمجي (مثل GitHub).",
  "req-5":"خبرة في مبادئ تصميم واجهة المستخدم والاهتمام بالتفاصيل.",
  "req-6":"مهارات ممتازة في حل المشكلات ومهارات جيدة في استكشاف الأخطاء وإصلاحها.  ",
  "req-7":"القدرة على العمل بفعالية في بيئة عمل سريعة الخطى وتعاونية.  ",
  "req-8":"مهارات اتصال قوية وقدرة على التعبير عن المفاهيم التقنية لأصحاب المصلحة غير التقنيين",
  "job-details-title-4":"الفوائد",
  "benf-1":"فرصة للعمل في مشاريع ريادية ومبتكرة.",
  "benf-2":"فرص للنمو المهني والتطوير.  ",
  "benf-3":"بيئة عمل تعاونية وشاملة.  ",
  "benf-4":"ساعات العمل من العاشرة صباحا وحتى الثالثة ضهرا  ",
  "end-job-details":"إذا كنت متحمسًا لتكون جزءًا من فريقنا، يُرجى إرسال سيرتك الذاتية او السي في  إلى ايميل التقديم. ونتطلع إلى مراجعة طلبك والترحيب بك في فريقنا",
  "email-to-apply":"ايميل التقديم",

  "login-modal-title": "يجب عليك تسجيل الدخول او انشاء حساب اولا",

  "login-title": "تسجيل الدخول",
  "register-title": "انشاء حساب ",
  "login-btn-regester": "انشاء حساب ",
  "login-input-email": " البريد الالكتروني او رقم الهاتف",
  "register-input-email": " البريد الالكتروني",
  "login-input-fullname": "الاسم الكامل للطالب ",
  "login-input-phone": "رقم الهاتف",
  "login-input-password": "كلمة المرور",
  "login-btn-login": "تسجيل الدخول",
  "login-btn-forget-password": "نسيت كلمة المرور ",
  "login-btn-rigester": "انشأ حسابك الان ",
  "login-des": "ليس لديك حساب؟",
  "register-des": "هل تملك حساب؟",
  "first-name":"الاسم الاول",
  "last-name":"الاسم الاخير",

  "profile-nav-1": "نظرة عامة",
  "profile-nav-2": "دوراتي",
  "profile-nav-3": "اختبارات الذكاء",
  "profile-nav-4": "المكافئات",
  "profile-nav-5": "حسابي",
  "profile-nav-6": "تسجيل الخروج",
  "profile-nav-7": "مشاريعي",

  "overflow-text-1": " جميع الدورات",
  "overflow-text-2": "الدورات المكتملة",
  "overflow-text-3": " اختبارات الذكاء المجتازة",
  "overflow-text-4": " مجموع النقاط",
  "overflow-text-5": "خارطة الجوائز",
  "overflow-points": "نقطة",
  "overflow-btn-1": "الدعم",
  "overflow-btn-2": "الاستمرار بالتعلم",
  "overflow-btn-3": "اختبارات الذكاء",

  "my-courses-card-btn": "الذهاب الى الدورة",
  "my-courses-btn": " الاستمرار بالتعلم",
  "my-course-no-data": "لا توجد لديك دورات",
  "my-course-no-data-btn": "الذهاب الى الدورات",

  "my-projects-card-btn": "الذهاب الى المشروع",
  "my-projects-btn": " الاستمرار بالتعلم",
  "my-projects-no-data": "لا توجد لديك مشاريع",
  "my-projects-no-data-btn": "اضف مشروعك",
  "my-projects-new": "هل لديك مشاريع جديدة ؟",
  "my-projects-new-btn": "اضافة مشروع جديد",

  "basket-continue": "اتمام الشراء",
  "cart-title": "قائمة المشتريات",
  "cart-desc": "يرجى التأكد من العناصر المحددة والضغط على متابعة الشراء",
  "cart-total": "المجموع",
  "total-price":"المجموع",
  "cart-discount": "الخصم",
  "cart-delivery": "كلفةالتوصيل",
  "cart-final-price": "المجموع النهائي",
  "cart-checkout": "متابعة الشراء",

  "create-projects-title": "اضف تفاصيل مشروعك",
  "create-projects-input-title": "عنوان المشروع",
  "create-projects-input-title-hint": "اكتب عنوان للمشروع",
  "create-projects-input-desc": "وصف المشروع",
  "create-projects-input-desc-hint": "اكتب وصف لمشروعك",
  "create-projects-input-type": "القسم",
  "create-projects-input-type-hint": "حدد نوع المشروع",
  "create-projects-input-file": "اضف ملف فيديو",
  "create-projects-input-file-hint": "يرجي تحميل ملف فيديو للمشروع",
  "create-projects-save": "حفظ",
  "create-projects-cat-1": "تصميم الالعاب",
  "create-projects-cat-2": "التحكم بالروبوتات",
  "create-projects-cat-3": "الذكاء الاصطناعي",
  "create-projects-cat-4": "التعرف على الوجه",
  "create-projects-cat-5": "اردوينو",
  "create-projects-submit": "اتمام الرفع",
  "create-projects-edit": "حفظ التعديل",
  "create-projects-lang": "لغة المشروع",
  "create-projects-ar": "العربية",
  "create-projects-en": "الانكليزية",
  "create-projects-msg-success": "تمت العملية بنجاح",
  "create-projects-msg-error": "حدث خطأ في الارسال . يرجى المحاولة مجددا!",

  "projects-info-edit": "تعديل",
  "projects-info-delete": "حذف",
  "projects-info-created-by": "انشئ بواسطة",
  "projects-info-related": "هل تريد انشاء مشاريع مشابهة ؟",
  "projects-info-related-sub": " استكشف هذه الدورات",
  "projects-info-confirm-msg": "هل انت متاكد انك تريد حذف هذا المشروع ؟",
  "projects-info-confirm": "تأكيد",
  "projects-info-cancel": "تراجع",
  "projects-info-rate": "اضف تقييمك",
  "project-new": "جديد",
  "projects-info-rate-user": "تقييمك الحالي",
  "projects-featured": "المشاريع المميزة",

  "back-to-store": "الرجوع الى المتجر",
  "back-to-invoice": "عرض الفاتورة",

  "product-course": "اليك دورة مجانية لتعلم كيفية استخدام هذا المنتج",

  "rewards-btn": "الحصول على المزيد",

  "my-account-text-1": "الاسم الكامل",
  "my-account-text-2": "رقم الهاتف",
  "my-account-text-3": "البريد الالكتروني",
  "my-account-text-4": "كلمة المرور",
  "my-account-btn": "تحديث المعلومات",

  "logout-title": "تسجيل الخروج",
  "logout-des": "هل تريد بلفعل تسجيل الخروج؟ ",
  "logout-btn-1": "الغاء",
  "logout-btn-2": "تسجيل الخروج",

  "lock-title": "أختبار مقفل",
  "lock-des": "هذا الاختبار مقفل يجب اكمال المستوى السابق او الانتظار 24 ساعة من بعد اكمال المستوى السابق",
  "lock-btn-1": "اغلاق",

  "lessons-nav-btn-1": " الرجوع ",
  "lessons-btn-back": "للخلف",
  "lessons-btn-next": "اكملت الدرس",
  "next-lesson": "الدرس التالي",
  "back-lesson": "الدرس السابق",
  "lessons-des": "الشرح",
  "lessons": "الدروس",
  "lessons-certificate": "الشهادة",
  "lessons-btn-mid-test": "الذهاب الى الاختبار النصفي",
  "lessons-btn-final-test": "الذهاب الى الاختبار النهائي",
  "lessons-btn-certificate": "الحصول على الشهادة",
  "offers-no-title":"دورات",

  "schools-competition-second-title": "بطولة المدارس 2024",
  "schools-competition-second-description": "حان الوقت ليشارك أبناؤكم في البطولة ويصنعوا التغيير! ستشهد البطولة مشاركة أكثر من 300 طالب وطالبة من جميع المحافظات العراقية، حيث سيتنافسون على عدة مراحل لحل مجموعة من المشاكل التي تواجه التعليم في العراق.",
  "schools-competition-second-stages": "المراحل",
  "schools-competition-second-stage-1-title": "المرحلة الأولى: ",
  "schools-competition-second-stage-1-description": "الاشتراك يتم عبر الاستمارة المرفقة.",
  "schools-competition-second-register": "سجل الآن",
  "schools-competition-second-stage-2-title": "المرحلة الثانية: ",
  "schools-competition-second-stage-2-description": "مرحلة الاختبار.",
  "schools-competition-second-stage-3-title": "المرحلة الثالثة: ",
  "schools-competition-second-stage-3-description": "مرحلة الابتكار والأفكار: كل طالب يجب أن يقدم فكرة تحل مشكلة تواجه التعليم في العراق. الفكرة يمكن أن تكون (لعبة، أو باستخدام الأردوينو، أو باستخدام ماكينة CNC).",
  "schools-competition-second-stage-4-title": "المرحلة الرابعة: ",
  "schools-competition-second-stage-4-description": "مرحلة تنفيذ الفكرة: المتأهلون يبدأون بتنفيذ أفكارهم ويقدمون عرضًا أوليًا للمشروع. يتم تقييم العمل من قبل لجنة التحكيم. مرحلة ختام البطولة: المتأهلون يقدمون المشروع النهائي أمام لجنة التحكيم. يتم تقييم المشاريع واختيار الفائز الأول.",
  "schools-competition-second-prizes": "الجوائز 🎁🎁",
  "schools-competition-second-prizes-description": "في نهاية البطولة، سيفوز ثلاثة طلاب بجوائز كمكافأة على المشاريع التي قدموها. وسيحظى الفائز الأول بفرصة مميزة للسفر إلى دبي.",

  "btn-details": "التفاصيل",
  "btn-buynow": "تعرف على المزيد",
  "btn-go-course": "الذهاب الى الدورة",

  "c-title": "بطولة المدارس للبرمجة والابتكار",
  "c-title-2": "بطولة المدارس للبرمجة والابتكار",
  "c-des": "بطولة المدارس للبرمجة والابتكار حتى اطفالكم يشاركون بالبطولة ويصنعون التغيير!البطولة راح تتضمن مشاركة 1000 طفل وطفلة كلهم راح يتنافسون على عدة مراحل لحل مجموعة من المشاكل اللي تواجه الكرة الارضية والمتعلقة بمواضيع علمية متنوعة مثل التكنولوجيا، الرياضيات، و الهندسة وغيرها... وبنهاية البطولة راح يفوز 3 اطفال بجوائز  كمكافئة للحلول اللي قدموهة. البطولة راح تنطلق بعد العيد، وراح تستمر لمدة شهر. قدموا من هسة واستغلوا هذا الوقت بالتحضير للبطولة . واكييد منصتنا التعليمية راح تلكون بيهة كل الدورات اللي تحتاجوهة حتى تحضرون نفسكم للبطولة. القبول راح يكون حسب اسبقية التقديم. ملاحظة : البطولة مجانية وستكون online",
  "r-title": "الدورات التحضيرية للمرحلة الثانية",

  "iqtest-title": "تمارين اختبارات الذكاء",
  "iqtest-degree": "نتيجة الاختبار",
  "iqtest-des": "هي مجموعة من الاسئلة واللتي تقسم الى عدة مستويات يتم اجتيازها من اجل الحصول على شهادة اختبار الذكاء",
  "iqtest-pass-title": "تهانينا, لقد تجاوزت الاختبار و ربحت 5 نقاط",
  "iqtest-pass-des": "يرجى الانتضار 24 ساعة لفتح المستوى التالي",
  "iqtest-pass-btn": "الرجوع الى المستويات",
  "iqtest-faild-title": "اوه للاسف! لقد فشلت في الاختبار",
  "iqtest-faild-btn-1": "المحاولة مجددا",
  "iqtest-faild-btn-2": "الرجوع الى المستويات",
  "iqtest-price": "مجانا",
  "my-iqtest-no-data": "لم تتجاوز اي مستوى من اختبارات الذكاء",
  "my-iqtest-btn": "الذهاب الى اختبارات الذكاء",

  "text-content": " أهداف الدورة : ",

  "pass-title": "لقد تجاوزت الاختبار",
  "pass-des": "أحسنت ، لقد اجتزت هذا الاختبار بنجاح ، يمكنك الانتقال إلى الاختبار التالي",

  "password-h": "تغيير كلمة السر ",
  "password-input": "ادخل البريد الالكتروني",
  "password-btn": "ارسال رسالة",
  "password-h-sucsess": "تغيير كلمة السر",
  "password-b-sucsess": "تم إرسال رابط لتغيير كلمة المرور الخاصة بك إلى بريدك الإلكتروني.",
  "password-h-error": "لقد حدث خطأ",
  "password-b-error": "هذا البريد الالكتروني غير موجود",
  "password-b-btn": "تواصل معنا",
  "newpassword-input": "ادخل كلمة السر الجديدة",
  "newpassword-btn": " موافق",
  "newpassword-h-sucsess": "تغير كلمة السر",
  "newpassword-b-sucsess": "لقد تم تغيير كلمة السر بنجاح",
  "newpassword-h-error": "لقد حدث خطاء ما",
  "newpassword-b-error": "لقد حدث خطأ ما يرجى التواصل مع الدعم",
  "special-product":"منتجات مميزة",
  "courses-offers-product":"سوف تحصل على هذه الدورات المجانية عند شراء المنتج",
  "conti-shoping":"متابعة التسوق",

  "pay-coupon": "هل تملك كود خصم؟",
  "pay-coupon-Error": "كود الخصم غير فعال",
  "pay-coupon-Error-2": "كود الخصم هذا غير صالح لهذه الدورة",
  "pay-input": "ادخل كود الخصم",
  "pay-btn-apply": "موافق",
  "pay-now": "ادفع الان عبر",
  "pay-confirm":"ادفع الان",
  "pay-prodect": "المشتريات",
  "pay-title": "شراء الدورة",
  "pay-btn-back": "الرجوع الى الدورة",
  "pay-btn-active": "مفعل",
  "pay-havenot": "اذا كنت لا تملك طريقة دفع فيرجى التواصل معنا",
  "pay-contact": "تواصل معنا",
  "pay-btn-zain": "زين كاش",
  "pay-btn-zoodpay":"زود باي",
  "pay-head-zoodpay":"ادفع بواسطة زود باي",
  "pay-btn-card": "بطاقة ائتمان",
  "pay-delivery":"طلب مندوب",
  "pay-cancel-btn":"الغاء",
  "pay-confirm-btn":"متابعة",
  "pay-confirm-title":"!تأكيد عملية الشراء",
  "pay-confirm-desc":"اضغط متابعة لتأكيد عملية الشراء",

  "payment-form-name": "الاسم الكامل",
  "payment-form-name-hint": "ادخل اسمك الكامل",
  "payment-form-name-hint2":"ادخل اسمك الاول ",
  "payment-form-name-hint3":"ادخل اسمك الاخير",
  "payment-form-phone": "رقم الهاتف",
  "payment-form-phone-hint": "ادخل رقم الهاتف",
  "payment-form-province": "المحافظة",
  "payment-form-province-hint": "حدد محافظتك",
  "payment-form-city": "المنطقة",
  "payment-form-city-hint": "اكتب اسم المنطقة",
  "payment-money": "المبلغ المستحق",
  "payment-title": "متابعة عملية الشراء",

  "btn-add-basket": "اضافة الى السلة",
  "btn-remove-basket": "حذف من السلة",

  "points-level-1": "المستوى الأول",
  "points-level-2": "المستوى الثاني",
  "points-level-3": "المستوى الثالث",
  "points-level-4": "المستوى الرابع",
  "points-level-5": "المستوى الخامس",
  "points-level-6": "المستوى السادس",
  "points-point-1": "50 نقطة",
  "points-point-2": "150 نقطة",
  "points-point-3": "250 نقطة",
  "points-point-4": "400 نقطة",
  "points-point-5": "600 نقطة",
  "points-point-6": "1000 نقطة",
  "points-button-1": "التواصل معنا",
  "points-des-1": "سوف تحصل على كورس تعليمي مجانا ",
  "points-des-2": "سوف تحصل على بوكس هدايا مسارات مجانا ",
  "points-des-3": "سوف تحصل على منضار مميز مجانا ",
  "points-des-4": "سوف تحصل على Micro Bit مجانا ",
  "points-des-5": "سوف تحصل على Qnarqi Robot مجانا ",
  "points-des-6": "سوف تحصل على Robot (Codey Rocky) مجانا ",

  "paySuccess-title": "نجحت عملية الدفع",
  "paySuccess-Stateus": "لقد اكملت عملية الدفع بنجاح",
  "paySuccess-goto": "هل تريد الان",
  "paySuccess-goto-course": "الذهاب الى مساراتي",
  "paySuccess-goto-courses": "الرجوع الى الدورات",

  "orderSuccess-title":"تمت عملية الشراء",
  "orderSuccess-Stateus":"سيتواصل معك المندوب قريبا.",

  "payFail-title": " فشلت عملية الدفع",
  "payFail-Stateus": "لقد فشلت عمليه الدفع",
  "payFail-goto": "هل تريد ",
  "payFail-goto-course": "المحاولة مجددا",
  "payFail-goto-courses": "الرجوع للصفحة السابقة",

  "404-title": "!لقد حدث خطأ",
  "404-des": "لقد حدث خطأ غير متوقع ",
  "404-btn": "الرجوع الى الصفحة الرئيسية",

  "footer-s1-1": "التواصل الاجتماعي",

  "product-label": "وصف المنتج", 
  "product-youtub": "تفاصيل اكثر حول المنتج",

  "mid-test-title": "الاختبار النصفي",
  "mid-test-pass-title": "تهانينا, لقد تجاوزت الاختبار بنجاح",
  "mid-test-pass-btn": "الرجوع الى الدورة",
  "mid-test-faild-title": "اوه للاسف! لقد فشلت في الاختبار",
  "mid-test-faild-btn": "المحاولة مجددا",
  "mid-test-prev": "الرجوع",
  "mid-test-next": "التالي",
  "mid-test-complete": "وضع علامة اكتمال",

  "final-test-title": "الاختبار النهائي",
  "final-test-pass-title": "تهانينا, لقد تجاوزت الاختبار بنجاح وربحت 10 نقاط",
  "final-test-pass-btn": "الرجوع الى الدورة",
  "final-test-faild-title": "اوه للاسف! لقد فشلت في الاختبار",
  "final-test-faild-btn": "المحاولة مجددا",
  "final-test-prev": "الرجوع",
  "final-test-next": "التالي",
  "final-test-complete": "وضع علامة اكتمال",

  "certificate-title": "الشهادة",
  "certificate-btn": "تنزيل الشهادة",

  "card-currency": "د.ع",
  "card-is-sub": "تم الاشتراك",

  "learn-step-1-title": "اكتب الاسم الكامل للطالب هنا",
  "learn-step-2-title": "اكتب هنا بريدك الالكتروني *يجب ان يكون غير مستخدم مسبقا",
  "learn-step-3-title": "اكتب هنا رقم الهاتف الخاص بك ,  مثال: 07800000xxx ",
  "learn-step-4-title": "اكتب كلمة السر * يجب ان تكون بين 6-30 حرف او رقم",
  "learn-btn-next": "التالي",
  "learn-btn-skip": "تخطي",

  "msg-succsess": "لقد تم تحديث المعلومات بنجاح",
  "msg-fail": "لقد حدث خطأ في تحديث المعلومات",

  "overview-all-course-modal-title": "جميع الدورات",
  "overview-completed-course-modal-title": "الدورات المكتملة",
  "overview-iqtest-modal-title": "اختبارات الذكاء المجتازة",
  "overview-point-modal-title": "مجموع النقاط",
  "overview-notification-title": "الاشعارات",

  "open-competitions-modal-btn-1": " المتاهلون الى المرحلة الثانية",
  "open-competitions-modal-btn-2": "تنزيل",

  "coupon": "كود الخصم :",
  "coupon-title": "عيد اضحى مبارك ,  و كل عام و انتم بخير ",
  "coupon-des": "تهنئكم شركة IoT KIDS بعيد الاضحى المبارك و تقدم لكم هدية هي خصم على جميع دوراتنا التدريبية بنسبة 20% خلال فترة العيد .",
  "coupon-btn": "اغلاق",

  "erroe-msg-1": "البريد الالكتروني موجود بلفعل, يرجى ادخال بريد الكتروني آخر",
  "erroe-msg-2": "حدث خطأ ، يرجى المحاولة مرة أخرى",
  "erroe-msg-3": "يجب ملئ جميع الحقول",

  "footer-s2-text-1": "من نحن؟",
  "footer-s2-text-2": "حولنا",
  "footer-s2-text-3": "دوراتنا",
  "footer-s2-text-4": "العاملين",

  "footer-s3-text-1": "دوراتنا",
  "footer-s3-text-2": "تصميم الالعاب",
  "footer-s3-text-3": "الروبوتيكس",
  "footer-s3-text-4": "الالكترونيك",

  "footer-s4-text-1": "القصص",
  "footer-s4-text-2": "قصص الاطفال",
  "footer-s4-text-3": "الفائزين العالميين",
  "footer-s4-text-4": "مساحة العمل",

  "footer-s5-text-1": "تواصل معنا",
  "footer-s5-text-2": "009647838300650",
  "footer-s5-text-3": "info@iotkidsiq.com",

  "footer-copy": "حقوق النشر @ 2022 | صمم بواسطة IoT KIDS",
  "home-s1-btn-p": "اشترك الان",
  "pay-bulk-title-1": "سوف تشتري ",
  "pay-bulk-title-2": "من الدورات  ",
  "pay-bulkgame-title-2": "من الدورات  ",
  "home-s99-text":"دورات البرمجيات",
  "p-s99-text":"يمكنك الان الاشتراك في كافة الدورات الخاصة بفئة البرمجيات اشترك الان واحصل على خصم 40%",
  "home-s99-text-2":"دورات الروبتكس",
  "p-s99-text-2":" يمكنك الان الاشتراك في كافة الدورات الخاصة بفئة الروبتكس اشترك الان واحصل على خصم 40%",
  "home-s99-text-3":"دورات الالكترونيك",
  "p-s99-text-3":"يمكنك الان الاشتراك في كافة الدورات الخاصة بفئة الالكترونيك اشترك الان واحصل على خصم 40%",
  "home-s99-text-4":"دورات التصميم",
  "p-s99-text-4":"يمكنك الان الاشتراك في كافة الدورات الخاصة بفئة التصميم اشترك الان واحصل على خصم 40%",
  "offers-page-title":"العروض على الدورات",
  "offers-page-desc":"عروض خاصة لفترة محدودة عل مجموعة من الدورات",
  "notification-level-1-title": "لقد اجتزت المستوى الاول بنجاح",
  "notification-level-2-title": "لقد اجتزت المستوى الثاني بنجاح",
  "notification-level-3-title": "لقد اجتزت المستوى الثالث بنجاح",
  "notification-level-4-title": "لقد اجتزت المستوى الرابع بنجاح",

  "competition-1-des":"تركز البطولة على إظهار قدرات الأطفال عن طريق حلهم لمشاكل متعلقة بالكرة الارضية و مواضيع علمية مختلفة مثل التكنولوجيا، الهندسة والرياضيات تقام  بالتنسيق والتعاون مع وزارة التربية العراقية وشركة زين العراق للاتصالات و بمشاركة أكثر من 3000 طالب وطالبة  ومن جميع المحافظات العراقية  تنافسوا على عدة مراحل  وفي نهاية البطولة سيكون هناك 3  فائزين  بجوائز كمكافأة للحلول المقدمة .",
  "competition-1-objects":"الاهداف",
  "competition-1-objects-1":"تسليط الضوء على الأطفال المميزين في مجال البرمجة والابتكار  من خلال ابرازهم على الإعلام و وسائل التواصل الإجتماعي.",
  "competition-1-objects-2":"توفير تدريب برمجي للأطفال على نحو ينعكس بشكل ايجابي في تطوير مخيلتهم من خلال طرح المشاكل وايجاد الحلول.  ",
  "competition-1-objects-3":"توفير محتوى علمي مفيد في مجال البرمجة والمعلوماتية يؤمن وسيلة داعمة لتعليم علوم الحاسوب في المرحلة المدرسية.",
  "competition-1-stages":"مراحل البطولة",
  "competition-1-stages-1-1":"المرحلة الأولى: ",
  "competition-1-stages-1-2":"بعد الإعلان والتسجيل على المسابقة يتم اجراء اختبار اونلاين عن  الأساسيات الخاصة بالبرمجة ،  تأهل  منها 1000 طالب من جميع  المدارس الأهلية والحكومية في المحافظات العراقية",
  "competition-1-stages-2-1":"المرحلة الثانية: ",
  "competition-1-stages-2-2":"خلال عشر ايام يتم اقامة ورشة عمل مختصة في مجال الابتكار وظائف المستقبل، الاختبار الثاني تأهل منه 500 فريق للمرحلة الثالثة ",
  "competition-1-stages-3-1":"المرحلة الثالثة: ",
  "competition-1-stages-3-2":"ورشات عمل متنوعة  online  لمدة خمس ايام ثم يتم بدء الاختبار الثالث وهو برمجة مشاريع من خلال البرمجة الصورية لحل مشاكل معينة ويتم تقديم المشاريع للجان التحكيم لاختيار افضل 100 مشروع",
  "competition-1-stages-4-1":"المرحلة الرابعة: ",
  "competition-1-stages-4-2":"المرحلة الاخيرة كانت مخصصة لايجاد حلول من خلال البرمجة والذكاء الاصطناعي (االتصحر . تلوث المياه . صحة افضل ) وهي جزء من اهداف التنمية المستدامة تم تقديم المشاريع  للجنة التقييم",
  "competition-1-awards":"الجوائز",
  "competition-1-awards-1-1":"المركز الاول: ",
  "competition-1-awards-1-2":"لابتوب عدد 2",
  "competition-1-awards-2-1":"المركز الثاني: ",
  "competition-1-awards-2-2":" جهاز تابلت عدد 2",
  "competition-1-awards-3-1":"المركز الثالث: ",
  "competition-1-awards-3-2":"روبوت تعليمي عدد 2",
  "competition-1-awards-4-1":"المشاريع المميزة: ",
  "competition-1-awards-4-2":"روبوت تعليمي عدد 2",
  "competition-1-schools":"المدارس المتيمزة",
  "competition-1-schools-0":"مدرسه الراهبات التقدمة - بغداد",
  "competition-1-schools-1":"مدرسة مريم الابتدائية الاهلية - بابل",
  "competition-1-schools-2":"مدرسة الامل الاهلية - بغداد",
  "competition-1-schools-3":"مدرسة الموهوبين - بغداد",
  "competition-1-schools-4":"AL-Mubdioon Primary School - الموصل",
  "competition-1-schools-5":"مدرسة الموهوبين في النجف الاشرف - النجف",
  "competition-1-schools-6":"ثانوية القيروان للمتميزين - الرسمية - بغداد",
  "competition-1-schools-7":"متوسطة العابدات - النجف",
  "competition-1-schools-8":"مدرسة ماريوحنا الحبيب الاهلية - بغداد",
  "competition-1-schools-9":"ثانوية القادة الاهلية - الموصل",
  "competition-1-schools-10":"ثانوية المتفوقين للبنين - الكوت",
  "competition-1-schools-11":"ثانوية المناذرة للمتفوقات - النجف",
  "competition-1-schools-12":"مدرسة الغربية النموذجية للبنين - الكوت",  
  "competition-1-card":"لجنة التحكيم المشاركة",
  "competition-1-card-1-1":"أ.سجاد علي",
  "competition-1-card-1-2":"وزارة التربية العراقية",
  "competition-1-card-1-3":"مركز المعلومات و الاتصالات في المديرية العامة للتخطيط التربوي و حاصل على ماجستير في علوم الحاسوب و مدرب و معاون مدير مركز المعلومات و الاتصالات و يعمل في التدرب منذ عام 2009.",
  "competition-1-card-2-1":"أ.محمد عبدالمهدي طالب",
  "competition-1-card-2-2":"هيئة الاعلام والاتصالات",
  "competition-1-card-2-3":"مدير تنظيم تكنولوجيا المعلومات هيئة الاعلام و الاتصالات.",
  "competition-1-card-3-1":"د.سعد احمد ذياب",
  "competition-1-card-3-2":"وزارة التعليم العالي والبحث العلمي",
  "competition-1-card-3-3":"رئيس قسم هندسة تكنلوجيا الاعلام و الاتصالات في كلية الهندسة - جامعة تكنلوجيا المعلومات و الاتصالات و مطور برمجيات و اختصاصي تحليل بيانات.",
  "competition-1-card-4-1":"د.سعد حميد",
  "competition-1-card-4-2":"وزارة التعليم العالي والبحث العلمي",
  "competition-1-card-4-3":"رئيس قسم هندسة الحاسبات في جامعة المنصور , المؤسس و المدير التنفيذي لشركة IRAQ 3D و 10 سنوات من الخبرة في مجال ادارة شركات الاتمتة و الالات الخاصة .",
  "competition-1-card-5-1":"علي محمد",
  "competition-1-card-5-2":"شركة زين العراق للاتصالات",
  "competition-1-card-5-3":"10 سنوات من الخبرة في تقديم التدريب و خدمة العملاء و الادارة , جزء من الفريق الرائد لتطوير مواهب موظفي زين العراق على مدى السنوات السبع الماضية , رئيس دائرة الادارة و تطوير المواهب - شركة زين.",
  "competition-1-winners":"الفائزون - لفئة الصغار",
  "competition-1-winner-1-1":"رقية ليث",
  "competition-1-winner-1-2":"المركز الاول",
  "competition-1-winner-2-1":"علي حسام",
  "competition-1-winner-2-2":"المركز الثاني",
  "competition-1-winner-3-1":"ملك حيدر",
  "competition-1-winner-3-2":"المركز الثالث",
  "competition-1-winner-2":"الفائزون - لفئة الكبار",
  "competition-1-winner-4-1":"طاهر حيدر ",
  "competition-1-winner-4-2":"المركز الاول",
  "competition-1-winner-5-1":"ريان سلوان",
  "competition-1-winner-5-2":"المركز الثاني",
  "competition-1-winner-6-1":"كرار حاتم",
  "competition-1-winner-6-2":"المركز الثالث",
  "competition-1-sponsor":"الداعمون",
  "no-data-notification":"لاتوجد لديك اشعارات",
  "no-data-iq-test":"لم تجتاز اي اختبار بعد",
  "competition-cod2022-winner-1":"عز الدين احمد",
  "competition-cod2022-winner-2":" زينب محمد",
  "competition-cod2022-winner-3":"محمد اسعد",
  "competition-cod2022-winner-4":"ستيلا نور",
  "competition-cod2022-winner-5":"ادريان فادي",
  "competition-cod2022-winner-6":"ابراهيم احمد",
  "competition-cod2022-winner-7":"مصطفى خضير",
  "winners-cod2022-comp-title":"الفائزون",
  "competition-cod2022-project-1":"روبوت لمساعدة النباتات للنمو في بيئة جيدة",
  "competition-cod2022-project-2":"مشروع العين الذكية",
  "competition-cod2022-project-3":"البوابة الذكية",
  "competition-cod2022-project-4":"المنزل الذكي",
  "competition-cod2022-project-5":"الكراج الذكي",
  "competition-cod2022-project-6":"نضام الحرائق الذكي",
  "competition-cod2022-project-7":"الكامرة الناطقة الذكية لتعقب الاطفال",
  
  "competition-codeavour-2022-title":"2022 Codeavour",
  "competition-codeavour-2022-des":"البطولة العالمية للبرمجة والذكاء الاصطناعي وهي مسابقة دولية خاصة بالذكاء الاصطناعي والبرمجة بتنظيم من stempedia ولكوننا الشريك الاقليمي في هذه المسابقة وحرصا منا ومن منطلق بناء قدرات مهارات الاطفال منذ الصغر وتدريبهم على مهارات البرمجة بشكل عملي تطبيقي مباشر ، نطمح اشراك ابطالنا في هذه المسابقة والمنافسة مع جميع المشاركين من مختلف دول العالم .",
  "competition-codeavour-2022-objects":"الاهداف",
  "competition-codeavour-2022-objects-1":"تسليط الضوء على الأطفال المميزين في مجال البرمجة والابتكار من خلال ابرازهم على الإعلام و وسائل التواصل الإجتماعي.",
  "competition-codeavour-2022-objects-2":"توفير تدريب برمجي للأطفال علي نحو ينعكس بشكل ايجابي في تطوير مخيلتهم من خلال طرح المشاكل وايجاد الحلول.",
  "competition-codeavour-2022-objects-3":"توفير محتوى علمي مفيد في مجال البرمجة والمعلوماتية يؤمن وسيلة داعمة لتعليم علوم الحاسوب في المرحلة المدرسية.",
  "competition-codeavour-2022-awards":"الشروط",
  "competition-codeavour-2022-awards-1-2":"الاعمار من 8-15 سنة",
  "competition-codeavour-2022-awards-2-2":"امتلاك حاسوب او جهاز لوحي في المنزل",
  "competition-codeavour-2022-schools":"خطتنا للمشاركة",
  "competition-codeavour-2022-schools-0":"نطمح الى تدريب 500 طالب وطالبة من خلال منصتنا التعليمية ليتسنى ترشيح 50 فريق للمشاركة وتمثيل العراق في البطولة .",
  "competition-codeavour-2022-sub-title":"التقديم والاشتراك",
  "competition-codeavour-2022-sub-des":"يكون الاشتراك في البطولة بشكل online وذلك من خلال تنفيذ المشاريع وايجاد الحلول المبتكرة للمشاكل التي تطرح من قبل المنظمين للبطولة وهي مشاكل تعاني منها الكرة الارضية والبحث عن كيفية توظيف علم الذكاء الاصطناعي لحل المشاكل .",
  "competition-codeavour-2022-winer-title":"نبذة عن مشاركاتنا السابقة في البطولة",
  "competition-codeavour-2022-winer-des":"تم مشاركة مركزنا في البطولة للاعوام  2019- 2020 – 2021 على التوالي بتشكيل 50 فريق وكانت النتائج العراق يحصد ميداليتين في البطولة العالمية للذكاء الاصطناعي من بين اكثر من 68 الف فريق متنافس بالمسابقة، من اصل 65 دولة وتم تكريم الفائزين من قبل السيد رئيس الوزراء العراقي السيد مصطفى الكاظمي والسيد وزير التربية العراقي الاستاذ علي حميد مخلف وعدد من المسؤلين في الوزارة .",
  "competition-codeavour-2022-sponsor":"زيارة موقع المسابقة",
  "competition-header-1-title": "1805 طالب مشارك",
  "competition-header-2-title": " 96 فريق مرشح",
  "competition-header-3-title": "7 فائزين",
  "about-title":"نبذة عن  منصة مسارات",
  "about-ourteam-title":"تعرف على فريقنا",
  "partner-title":"شركائنا",
  "partner-1-title":"هيئة الاعلام والاتصالات",
  "partner-2-title":"وزارة التربية",
  "partner-3-title":"زين العراق",
  "partner-4-title":"ايرثلنك تيليكوم",
  "partner-5-title":"الوكالة الدولية للتعاون الالماني",
  "competition-codeavour-2021-img":"صور من المسابقة ",
  "text-course-bulk-title":"الدورات التحضيرية",
  "thems-title":"المواضيع المطلوبة في المسابقة",
  "thems-des":"اختر من بين هذه المواضيع الخمسة المهمة واطرح الافكار حول القضايا العالمية الناشئة وقم ببناء مشاريع موجهة نحو الحلول لحل مشاكل العالم الحقيقي",
  "thems-1-title":"اتمتة المناطق المحيطية",
  "thems-2-title":"احداث ثورة في الزراعة",
  "thems-3-title":"انقاذ البيئة",
  "thems-4-title":"تعزيز البنية التحتية",
  "thems-5-title":"صناعة انضمة النقل الذكية",
  "thems-dev-des":"يمكن للمتسابقين استخدام احد هذه الاجهزة في المشاريع بشرط ان تكون مربوطة مع برنامج pictoblox",
  "thems-dev-title-1":"Twatch",
  "thems-dev-title-2":"Quarky",
  "thems-dev-title-3":"Microbit",
  "thems-dev-title-4":"Makey Makey",
  "thems-dev-title-5":"Lego",
  "thems-dev-title-6":"Evive",
  "thems-dev-title-7":"ESP32",
  "thems-dev-title-8":"Boffin",
  "thems-dev-title-9":"Ardouino",
  "sponsors-title":"الداعمون",
  "contact-title":"موقعنا",
  "location-title":"بناية الميكر, شارع الصناعة, الكرادة, بغداد, العراق",
  "mobile-num":"9647838300650+",
  "join-our-team-title":"انضم لفريقنا",
  "no-jobs-des":"لا توجد وضائف متاحة في الوقت الحالي",
  "error-msg-1": " يرجى التأكد من إدخال جميع المعلومات المطلوبة بشكل صحيح",
  "error-msg-2": "عذراً، حدث خطأ في النظام. يرجى المحاولة مرة أخرى لاحقاً",
  "error-msg-3": "البريد الإلكتروني أو رقم الهاتف مسجل مسبقاً",

  
  "share-project-title-2": "شارك مشروعك عبر",
  "share-project-title": "عمل جيد!",
  "share-project-des": "الان قم بمشاركة مشروعك مع اصدقائك على مواقع التواصل الاجتماعي و اطلب منهم تقييم مشروعك.",
  "download-app-title-2": "قم بالتحميل عبر ",
  "download-app-title": "حمل التطبيق الان",
  "download-app-des": "الان اصبح تطبيقنا جاهز للتحميل حتى يوفرلك امكانية اكثر و سهولة اكثر لمشاهدة دوراتنا التعليمية.",
  "start-creating-title-1": "انا عمري:",
  "start-creating-title-2": "انا مهتم في:",
  "start-creating-title-3": "نقترح لك الانضمام الى هذه الدورات ",
  "go-to-course": "الذهاب الي الكورس",
  "home-s1-text-3":"Happy Halloween",
  "home-s1-text-p-3":"اشترك الان واحصل على خصم 40% على جميع الدورات التدريبية",
  "ourteam-title":"تعرف على فريقنا",
  "footer-company":"الشركة",
  "footer-company-about":"عن IoT KIDS",
  "footer-company-ourteam":"تعرف على فريقنا",
  "footer-company-ourpartner":"شركائنا",
  "footer-company-contactus":"تواصل معنا",
  "footer-company-joinourteam":"انضم لفريقنا",
  "footer-company-privcypolicy":"شروط الخصوصية",
  "footer-ourcourses":"دوراتنا",
  "footer-ourcourses-gamedesign":" مسار البرمجيات",
  "footer-ourcourses-robotic":"مسار الذكاء الاصطناعي",
  "footer-ourcourses-Electronic":"مسار الالكترونيك والتصميم",
  "footer-ourcourses-mindgrouth":" مسار اختبارات الذكاء",
  "pop-up-complete-text":"لقد حصلت على 1000 دينار في محفضتك يمكنك استخدامها في شؤاء المسارات الو المنتجات من متجرنا",
  "wallet-brn-title":"الذهاب الى المحفضة",
  "footer-kidsachivment":"الانجازات",
  "footer-kidsachivment-competition":"المسابقات",
  "footer-kidsachivment-projects":"المشاريع",
  "download-title":"حمل التطبيق الان عبر",
  "emailus-title":"راسلنا ",
  "callus-title":"اتصل بنا",
  "address-title":"العنوان",
  "our-team-m1-name":"علي محسن",
  "our-team-m1-position":"شريك مؤسس",
  "our-team-m2-name":"محمد خالد",
  "our-team-m2-position":"شريك مؤسس",
  "our-team-m3-name":"احمد داود",
  "our-team-m3-position":" شريك مؤسس",
  "our-team-m4-name":"احمد ماجد",
  "our-team-m4-position":"مسؤول تقني",
  "our-team-m5-name":"منتظر جبار",
  "our-team-m5-position":"مسؤول البرمجة والتطوير",
  "our-team-m6-name":"فاطمة جاسم",
  "our-team-m6-position":"مسؤولة المدربين",
  "our-team-m7-name":"علا احمد",
  "our-team-m7-position":"مسؤولة مواقع التواصل الاجتماعي",
  "our-team-m8-name":"اسلام احمد",
  "our-team-m8-position":"مدربة",
  "our-team-m9-name":"ايات عماد",
  "our-team-m9-position":"مدربة",
  "our-team-m10-name":"مينا ماجد",
  "our-team-m10-position":"مدربة",
  "our-team-m11-name":"مصطفى رمزي",
  "our-team-m11-position":"المدير المالي",
  "our-team-m12-name":"مصطفى احمد",
  "our-team-m12-position":"مبرمج",
  "our-team-m13-name":"حوراء لؤي",
  "our-team-m13-position":"مدربة",
  "btn-start-course":"ابدأ الدورة الان",
  "see-btn":"شاهد مشاريع أصدقائك",
  "projects-text-1":"ليس لديك اي مشروع ؟ قم بانشاء مشروعك الاول الان.",
  
  "start-now-btn-text":"اختر الدورة المناسبة لك",
  "Subscribe-course-learn":"كيفية شراء الدورات",
  "start-now-des-text":"ابدأ رحلتك إلى عالم البرمجة من خلال دورة تصميم الألعاب. من خلال هذه الدورة ، سيتعلم الأطفال أساسيات البرمجة بطريقة سهلة وبسيطة تصميم إبداعي ومنطقي  تصميم الألعاب بطريقة ممتعة للغاية  بعد الانتهاء من هذه الدورة. سيتمكن الأطفال من الانتقال إلى الدورات بشكل أكثر نشاطًا",
  "start-now-card-des":" لا تمتلك اي دورة الان لا مشكلة دعنا نساعدك في اختيار الدورة المناسبة لك",
  "popup-btn-text":"ابدا التعلم الان",
  "how-to-upload":"كيف ارفع مشروعي",
  "UN":"جائزة الاسكوا كأفضل محتوى رقمي عربي من أجل التنمية المستدامة 2023 ",
  "AUIS":"جائزة الابتكار الفضية من الجامعة الامريكية في السليمانية 2022",
  "Iraq-Pre":"جائزة رواد العراق فئة الارتقاء بالمشروع كأفضل مشروع ريادي من بين 3000 مشروع 2019",
  "Iraq-min":"درع الشباب مقدم من وزارة الشباب والرياضة في ختام فعاليات  Maker Camp 2019",
  "seed":"الفائزين المحلية لجائزة مسرعة الاعمال مقدمة من Seedstar 2019",
  "iq-comp-title":"مسابقة اختبارات الذكاء",
  
  "projects-text-2":"هل تريد ان تحصل على افكار, شاهد ماذا صنع اصدقائك.",
  "recomandded-courses":"دعنا نساعدك في اختيار الدورة المناسبة لك",
  "go-to-comp-title":"الذهاب الى المسابقة",

  "bg-home-title":"تعلم | تخيل | ابتكر ",
  "bg-home-des":"تقدم منصة مسارات مجموعة من الدورات اللتي ترشدك الى استخدام التكنلوجيا بشكل صحيح وباستخدام طرق تعلم سهلة وممتعة.",
  "bg-home-btn":"تعرف على المزيد",

  "iqcourses-title":"دورات التاهيل لاختبار الذكاء",
  "iqcourses-price": "50000 د.ع",
  "iqcourses-des":"مجموعة من الدورات مجهزة لك من اجل خوض اختبارات الذكاء ",
  "iq-course-modal-note" :"سوف تحصل على الشهادة في دورة (اختبارات الحساب العددي و المنطق).",
  "go-to-my-courses":"الرجوع الي دوراتي",
  "final-test-dwonload":"تنزيل الحلول",
  "chat-tooltip":"تحدث مع معلمك",
  "cvShow-title":"السيرة الذاتية",
  "cvShow-zoom":"تكبير"

}
export default ar
