import React from "react";
import CourseBuy from "../../components/CourseBuy/CourseBuy";
import "./IQCoursesDetails.scss";
import CourseSub from "../../components/CourseSub/CourseSub";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import CourseDes from "../../components/CourseDes/CourseDes";
import QuestionModal from "../../components/PreviousYearQuestionModal/QuestionModal.jsx";
import i18next from "i18next";
import Card from "../../components/Card/Card";
import { useRecoilState, useRecoilValue } from "recoil";
import { itemAllCourseState, userState } from "../../atom";
import { useState } from "react";
import { useEffect } from "react";
import { apiGetCourse, apiGetCourseById } from "../../services/api/generalApi";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

const IQCoursesDetails = () => {
  const userId = localStorage.id;
  const [data, setData] = useState([]);
  const [data2, setData2] = useRecoilState(itemAllCourseState);
  const user = useRecoilValue(userState);
  const langUpperCase = localStorage.i18nextLng.toUpperCase();

  // api from service
  const GetCourseById = async () => {
    const data = await apiGetCourseById(32, userId);
    setData(data);
  };

  const GetCourse = async () => {
    const data = await apiGetCourse(langUpperCase, userId);
    setData2(data);
  };

  useEffect(() => {
    GetCourseById();
    if (data2.length == 0) {
      GetCourse();
    }
  }, []);
  return (
    <div className="bg ">
    <div className="course-details">
      <div className=" container">
        <div className="col-12 mt-5 course-details-title">
          <h2>{data?.title}</h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 my-4 order-2 order-lg-2">
            <img
              src={data?.ref03}
              className="course-img-top"
              alt="Image Corse"
            />
              <div className="col-12 mt-3">
                <CourseDes des={i18next.t("iqcourses-des")} />
              </div>
          </div>
          <div className="col-12 col-lg-4 my-4 order-1 order-lg-1">
            <div className="row course-buy d-flex justify-content-center">
              <div className="col-12 mb-4">
                <VideoPlayer src={"https://vimeo.com/784959396"} />
              </div>
              </div>
              <div className="col-12 mb-4">
                {data.ref02 === 1 ? (
                  <CourseSub
                    price={1000}
                    title="التعرف على نمط اسئله اختبارات الذكاء<br/> التعرف على الاسئلة السابقة و حلها  <br/>تعلم مهارات التفكير المنطقي والإبداعي و حل المشاكل<br/>"
                    path={{
                      pathname: '/mycourses',
                    }}
                  />
                ) : (
                  <CourseBuy path={{
                    pathname: 'paybulk/7',
                  
                  }} user={user} title="التعرف على نمط اسئله اختبارات الذكاء<br/> التعرف على الاسئلة السابقة و حلها  <br/>تعلم مهارات التفكير المنطقي والإبداعي و حل المشاكل<br/>" oldPrice="200,000" price="150,000" />
                )}
              </div>
              </div>
        </div>
        <div>
        <div className="courses-des ">
                <h2 className="text-center">هذا الكورس يتضمن </h2>
              </div>
              <div className="col-12 my-2  row gap-md-5 gap-xl-0 justify-content-center">
              
                {data2.map((postDetails, index) => (
                  postDetails.id == 30 || postDetails.id == 31 || postDetails.id == 33 ?
                    <div key={index} className="col-12 col-md-6 col-lg-3 my-2">
                      <Card name={postDetails.title} image={postDetails.ref03} description={postDetails.description} iqCourse='true'
                        path={{
                          pathname: `/course/${postDetails.id}`,
                          state: {
                            id: postDetails.id,
                            ref02: 2,
                          },
                        }} />
                    </div>
                    : ""
                ))}
                <div className="col-12 col-md-6 col-lg-3 my-2">
                <a
                data-bs-toggle="modal"
                data-bs-target="#overview-iq-courseModal"
                href=""
              >
                <Card  name="الاسئلة الوزارية السابقة " description="الاسئلة الوزارية للسنوات السابقة لاختبارات الذكاء لسنة 2019-2020-2021-2022" iqCourse='true' image="https://messarat.com/images/course%20images/rsz_1iq_test.jpg" />
                </a>
                </div>
               
              </div>
              <QuestionModal/>
        </div>
      </div>
      <div className="footer-mobile-desktop">
      <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        </div>
    </div>
    </div>
  );
};

export default IQCoursesDetails;
