import service from "../../../services";

export const apiGetCourse = (lang , userId) => {
  return new Promise((resolve) => {
    service.getData(`/api/Course?Lang=${lang}&UserId=${userId}`).then((data) => {
      resolve(data);
    });
  });
};


export const apiGetCourseById = (id , userId) => {
  return new Promise((resolve) => {
    service.getData(`/api/Course/${id}?UserId=${userId}`).then((data) => {
      resolve(data);
    });
  });
};

export const apiGetUserData = () => {
  const idUser = localStorage.getItem("id");
  return new Promise((resolve) => {
    service.getData(`/api/users/GetUserbyId/${idUser}`).then((data) => {
      resolve(data);
    });
  });
}

export const apigetMessartDetails = (id) => {
  return new Promise((resolve) => {
      service.getData(`https://ahmeddawood88-003-site1.etempurl.com/api/Course/GetMessaratCourseDetails?UserId=${id}&Lang=ar`).then((data) => {
          resolve(data);
      });
  });
};
export const apigetFeaturedProjects = () => {
  return new Promise((resolve) => {
      service.getData(`api/UserProject/GetAllProjects/false/true?status=Approved`).then((data) => {
          resolve(data);
      });
  });
};
export const apiGetProjectsByCategory = (categoryID) => {
  return new Promise((resolve) => {
      service.getData(`api/UserProject/GetProjectsByCategory/${categoryID}`).then((data) => {
          resolve(data);
      });
  });
};

