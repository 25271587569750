import React, { useEffect, useState, useMemo } from "react";
import "./ProcessPayment.scss";
import Loading from "../../components/Loading/Loading";
import { useLocation } from "react-router-dom";
import PaySuccess from "./../PaySuccess/PaySuccess";
import PayFail from "./../PayFail/PayFail";

import axios from "axios";
import { useRecoilState } from "recoil";
import { basketState, shopTotalPriceState } from "../../atom";
import OrderSuccess from "../OrderSuccess/OrderSuccess";

const ProcessPayment = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [basket, seBasket] = useRecoilState(basketState);
  const [total, setTotal] = useRecoilState(shopTotalPriceState);
  const { search } = useLocation();
  // Get the query string from the url
  const query = useMemo(() => new URLSearchParams(search), [search]);
  // const checkoutId = query.get("checkoutId");
  const checkoutId = localStorage.getItem("checkoutId");
  const UserId = localStorage.getItem("id");
  const customerInfo = JSON.parse(localStorage.getItem("iot_customer")) || null;
  const couponId = query.get("couponId");
  const courseId = query?.get("courseId")?.split(",")?.map(Number);
  const refCode = query.get("refCode");
  const isShope = query.get("isShope");
  const paymentMethod = query.get("paymentMethod");
  const orderStatus = query.get("orderStatus");
  const orderId = UserId + courseId;
  // console.log("this is second checkout id", checkoutId);

  useEffect(() => {
    //  Checkout from the server
    // #Step 3

    if ((basket && basket.length > 0) || !isShope) {
      let urls = {
        Cash: "/api/StoreOrder",
        Switch: "/api/Switch/CompleteStorePayment",
        "Zain Cash": "/api/ZainCash/GenerateStoreZainCashUrl",
      };

      const data = {
        checkoutId: checkoutId,
        userId: UserId,
        orderId: orderId,
        coursesId: courseId,
        couponId:
          courseId?.length > 1
            ? 28
            : couponId !== 0
            ? parseInt(couponId) == "NaN"
              ? parseInt(couponId)
              : 0
            : 0,
        refCode: refCode == "null" || refCode == "undefined" ? null : refCode,
        isBulk: courseId?.length > 1 ? true : false,
      };

      let totalAmt = 0;

      basket.map((el) => {
        totalAmt += el.endprice;
      });

      const dataShope = {
        userId: UserId,
        custName: customerInfo?.name || "",
        custPhoneNo: customerInfo?.phone || "",
        custCity: customerInfo?.city || "",
        custArea: customerInfo?.area || "",
        totalAmt: totalAmt || 0,
        currency: "IQD",
        status: orderStatus,
        paymentMethod: paymentMethod?.toLocaleUpperCase(),
        details: basket.map((el) => [el.id, el.qty]),
        switchCheckOutId: paymentMethod !== "Cash" ? checkoutId : "",
      };

     
      axios
        .post(
          isShope ? urls[paymentMethod] : `/api/Switch/CompletePayment`,
          isShope ? dataShope : data
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            setStatus(() => "success");
            setTotal(0);
            seBasket([]);
            localStorage.removeItem("iot_customer");
            localStorage.removeItem("iot_basket");
          } else {
            setStatus(() => "fail");
          }
          setLoading(() => false);
        })
        .catch((err) => {
          setStatus(() => "fail");
          setLoading(() => false);
        });

      // const submit = () => {
      //   const entityId = "8ac7a4c87d804dff017d8997ff360b1b";
      //   const options = {
      //     port: 443,
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       Authorization:
      //         "Bearer OGFjN2E0Yzg3ZDgwNGRmZjAxN2Q4OTk2ZmUyMjBiMTd8NVI3RzdIalJKcg==",
      //     },
      //   };

      //   fetch(
      //     `https://cors-anywhere.herokuapp.com/https://www.oppwa.com/v1/checkouts?entityId=${entityId}`,
      //     options
      //   )
      //     .then((res) => {
      //       
      //       // res.json()
      //     })
      //     .then((data) => {
      //     
      //     })
      //     .catch((err) => console.log(err));
      // };

      // submit();
    }
  }, [basket]);

  return (
    <>
      {loading ? (
        <div className="center">
          <Loading width="160px" />
        </div>
      ) : status === "success" ? (
        paymentMethod === "Cash" || paymentMethod === "Switch" ? (
          <OrderSuccess />
        ) : (
          <PaySuccess />
        )
      ) : (
        <PayFail />
      )}
    </>
  );
};

export default ProcessPayment;
