import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import img_google from "../../assets/images/login/google-icon.png";
import { apiPostGoogleAuth } from "../../services/api/googleAuth";

const GoogleAuth = () => {
  // function post login
  const PostGoogleAuth = async (data) => {
    const dataUser = await apiPostGoogleAuth(data);
    if (dataUser?.token?.result?.jwtToken){
      localStorage.setItem("token", dataUser.token.result.jwtToken);
      localStorage.setItem("id", dataUser.token.result.id);
      window.location.reload();
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const data = {
        tokenId: codeResponse.access_token
      };
      PostGoogleAuth(data);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
      <button
          type="button"
          className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 hover:shadow-md text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="google-login-auth"
          onClick={() => login()}
      >
        <img src={img_google} className="w-5 h-5 mr-2" alt="Google Icon"/>
        <span className="mx-2">Continue with Google</span>
      </button>
  );
}

export default GoogleAuth;