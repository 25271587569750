import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Certificate from "../../../components/Certificate/Certificate";
import "./Certificate.scss";
import { userDataState, LoadingState } from "../../../atom";
import { apiGetUserData } from "../../../services/api/generalApi";
import i18next from "i18next";

const CertificatePage = (props) => {
    const [userData, setUserData] = useRecoilState(userDataState);
    const [loading, setLoading] = useRecoilState(LoadingState);

    const GetUserData = async () => {
        try {
            const data = await apiGetUserData();
            setUserData(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) {
                window.location.href = '/login';
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const idUser = localStorage.getItem("id");
        const token = localStorage.getItem("token");

        if (!idUser || !token) {
            window.location.href = '/login';
            return;
        }

        setLoading(true);
        if (!userData) {
            GetUserData();
        } else {
            setLoading(false);
        }
    }, []);

    const handleScroll = () => {
        window.scrollTo(0, 0);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="certificate">
            <div className="nav-item">
                <Link to="/mymessarat">
                    <button
                        className="navbar-btn"
                        onClick={handleScroll}
                    >
                        X
                    </button>
                </Link>
            </div>
            <div className="iqtest-item-title">
                <h2 className="my-4">{i18next.t('certificate-title')}</h2>
            </div>
            {userData && (
                <Certificate
                    fullName={userData.fullName}
                    courseName={props.location?.state?.title || ""}
                />
            )}
        </div>
    );
};

export default CertificatePage;