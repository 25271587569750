import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { basketState, qtyState } from '../../atom';
import QtyBtn from '../QtyBtn';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-web';
import anim_ref from '../../assets/images/overview/ref.json';
import i18next from 'i18next';

const CardShop = ({ id, name, price, endprice, description, image }) => {
  const [basket, setBasket] = useRecoilState(basketState);
  const history = useHistory();
  const [qty, setQty] = useRecoilState(qtyState(id));

  useEffect(() => {
    if (basket.length > 0) {
      let basketItem = basket.find((el) => el.id === id);
      basketItem && setQty(basketItem.qty);
    }
  }, [basket, id, setQty]);

  const handleBasket = (_qty) => {
    let item = { id, name, price, endprice, image };
    let basketItem = basket.find((el) => el.id === id);
    let basketItemIndex = basket.findIndex((el) => el.id === id);
    let newBasket = [...basket];

    if (basketItem) {
      if (_qty === 0) {
        newBasket = basket.filter((el) => el.id !== id);
      } else {
        newBasket[basketItemIndex] = { ...item, qty: _qty };
      }
    } else {
      item = { ...item, qty: _qty };
      newBasket.push(item);
    }

    setQty(_qty);
    setBasket(newBasket);
    localStorage.setItem('iot_basket', JSON.stringify(newBasket));
  };

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.getElementById(`card-img-icon-${id}`),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: anim_ref,
    });
  }, [id]);

  return (
      <div className="bg-white rounded-2xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div className="relative pb-2/3">

          <img
              src={image}
              alt={name}
              className="absolute h-full w-full object-cover object-center"
              onClick={() => history.push(`/store/${id}`)}
          />
        </div>
        <div className="p-4 mt-4">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">{name}</h3>
          <p className="text-sm text-gray-600 mb-4">{description}</p>
          <div className="flex justify-between items-center mb-4">
            <div>
              {price !== endprice && (
                  <span className="text-sm text-zinc-700 line-through mr-2">
                {Number(price).toLocaleString()}
              </span>
              )}
              <span className="text-lg font-bold text-zinc-700">
              {Number(endprice).toLocaleString()}
            </span>
            </div>
            {/*<div id={`card-img-icon-${id}`} className="w-10 h-10"></div>*/}
          </div>
          <div className="flex justify-between items-center">
            <button
                className={`px-5 py-3 rounded-full text-sm font-medium transition-colors duration-300 ${
                    qty === 0
                        ? 'bg-custom-yellow font-semibold text-zinc-700 hover:bg-custom-yellow-dark'
                        : 'bg-custom-blue font-semibold text-zinc-700 hover:bg-custom-blue'
                }`}
                onClick={() => qty === 0 ? handleBasket(1) : handleBasket(0)}
            >
              {qty === 0 ? i18next.t('btn-add-basket') : i18next.t('btn-remove-basket')}
            </button>
            {qty > 0 && (
                <div className="bg-gray-100 rounded-full p-1">
                  <QtyBtn qty={qty} onChange={(val) => handleBasket(val)} />
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default CardShop;