import React from 'react';
import './Dialoge.scss';
import LoadingLogo from '../../components/Loading/Loading'
//import ReactDOM from 'react-dom';
const Dialoge = (props)=> {

return(
<div className="body-dialoge">
  {/*
  <!-- Start-Register-50 --> */}
  <div className="dialoge">
    <div className="row">
      <div className="col-12">
        <h2 className="dialoge-title">
          {props.info.title}
        </h2>
      </div>
      <div className="d-flex justify-content-start col-6">
        <h4 className="dialoge-name">
          {props.info.payType}
        </h4>
      </div>
      <div className="col-12">
        <p className="dialoge-des">
         {
           props.isLoading?
           <LoadingLogo width='100px'/>
           :
           <p className={`${props.isSuccess?'success':'failed'}`}>
             {props.info.msg}
           </p>
           
         }
        </p>
      </div>
      <button disabled={props.isLoading} onClick={props.closeDialog} className="btn btn-dialoge">{props.info.btnTitle}</button>
    </div>
  </div>
  {/*
  <!-- End-Register-50 --> */}
</div>
);
}
export default Dialoge;