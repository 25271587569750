import React, { Component } from "react";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import "./AppFooterMobile.scss";
import usered from "/src/assets/images/header/usered.png";
import signin from "../../assets/images/header/sign-in.png";
import home from "/src/assets/images/header/home.png";
import courses from "/src/assets/images/header/course.png";
import about from "/src/assets/images/header/robot.png";
import competitions from "/src/assets/images/header/competitions.png";
import projects from "/src/assets/images/header/projects.png";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import shop from "/src/assets/images/header/shop.png";
import more from "../../assets/images/plus.png"

import i18next from "i18next";

class AppFooterMobile extends Component {
  activeSign() {
    const element = document.getElementById("sing-in-login");
    element.classList.add("active");
  }

  langChange(option) {
    localStorage.setItem("lang", option.target.value);
    window.location.reload();
  }
  render() {
    const lang = localStorage.getItem("lang") || "en";
    let SignIn;
    if (this.props.user) {
      SignIn = (
        <NavLink
          className="nav-link order-item-4"
          activeClassName="active"
          to="/overview"
        >
          <img src={usered} alt="icon" />
          <h4>{i18next.t("header-profile")}</h4>
        </NavLink>
      );
    } else {
      SignIn = (
        <NavLink
          className="nav-link order-item-4"
          id="sing-in-login"
          activeClassName="active"
          to="/login"
        >
          <img src={signin} alt="icon" />
          <h4>{i18next.t("header-signin")}</h4>
        </NavLink>
      );
    }
    return (
      <div className="d-block d-sm-none footer-mobile py-0 navbar-expand-lg navbar-light">
        <div className="nav-chat">
          <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
        </div>
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav justify-content-center w-100">
              <NavLink
                className="nav-link order-item-1"
                activeClassName="active"
                exact
                to="/"
              >
                <img src={home} alt="icon" />
                <h4>{i18next.t("header-home")}</h4>
              </NavLink>
              <NavLink
                className="nav-link order-item-2"
                activeClassName="active"
                to="/Messarat/1"
              >
                <img src={courses} alt="icon" />
                <h4>{i18next.t("header-courses")}</h4>
              </NavLink>
              <NavLink
                className="nav-link order-item-3"
                activeClassName="active"
                to="/store"
              >
                <img src={shop} alt="icon" />
                <h4>{i18next.t("header-shop")}</h4>
              </NavLink>
              {SignIn}
              <NavLink
                className="nav-link order-item-3"
                activeClassName="active"
                to="/UserList"
              >
                <img src={more} alt="icon" />
                <h4>{i18next.t("more-icon-text")}</h4>
              </NavLink>
              
              
             
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AppFooterMobile;
