import React, {Component, useEffect, useState} from "react";
import CourseBuy from "../../components/CourseBuy/CourseBuy";
import "./CourseDetails.scss";
import {Link} from 'react-router-dom';
import CourseSub from "../../components/CourseSub/CourseSub";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import CoursesList from "../../components/CoursesList/CoursesList";
import CourseDes from "../../components/CourseDes/CourseDes";
import axios from "axios";
import i18next from "i18next";
import {useRecoilState} from "recoil";
import {itemAllCourseState} from "../../atom/index"
import {apiGetCourse} from "../../services/api/generalApi";
import {apiGetCourseLessons} from "../../services/api/courseDetails";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

const CourseDetails = (props) => {
    const idCourse = location?.pathname.split("/")[2];
    const [activedetails, setActivedetails] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [lesson0, setLesson0] = useState([]);
    const [corses, setCourses] = useRecoilState(itemAllCourseState);

    const GetCourse = async () => {
        const langUpperCase = i18next.language.toUpperCase();
        const userId = localStorage.getItem("id");
        const data = await apiGetCourse(langUpperCase, userId);
        setCourses(data);
    }

    const GetCourseLessons = async () => {
        const data = await apiGetCourseLessons(idCourse);
        setLesson(data);
        setLesson0(data[0]);
    }

    const selectLesson = () => {
        for (var i = 0; i <= corses.length; i++) {
            if (corses[i]?.id == idCourse) {
                setActivedetails(corses[i])
            }
        }
    }

    useEffect(() => {
        if (corses.length == 0) {
            // get courses
            GetCourse();
            // get lessons
            GetCourseLessons();
            // select course
            selectLesson();
        } else {
            // select course
            selectLesson();
        }
    }, [corses]);
    useEffect(() => {
        // get lessons
        GetCourseLessons();
    }, [activedetails]);
    const details = activedetails;

    return (
        <div className="course-details bg" onClick={window.scrollTo(0, 0)}>
            <div className="course-details" onClick={window.scrollTo(0, 0)}>
                <nav className="navbar navbar-light bg-light">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center">
                            <h4 className="navbar-text ml-5 pl-5 mt-1">
                                {i18next.t("courses-details")}
                            </h4>
                        </div>
                        <a onClick={() => window.history.back()}>
                            <button className="navbar-btn">
                                {i18next.t("lessons-nav-btn-1")}
                            </button>
                        </a>

                    </div>
                </nav>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 mt-3 course-details-title">
                            <h2>{details.title}</h2>
                        </div>
                        <div className="col-12 col-lg-8 mt-lg-4 mb-4 order-video">
                            <div className="row">
                                <div className="col-12 mt-lg-4 mb-4">
                                    <img
                                        src={details.image}
                                        className="course-img-top"
                                        alt="Image Corse"
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <CourseDes des={details.description}/>
                                </div>
                                <div className="courses-list">
                                    <div
                                        className="courses-list-item accordion"
                                        id="accordionExample"
                                    >
                                        {/*
                 <   !-- accordion-item --> */}
                                        <div className="card m-0">
                                            <div className="card-header" id="headingOne">
                                                <a className="btn-collapse">
                                                    <h2 className="pl-5 mb-0">{i18next.t("lessons")}</h2>
                                                </a>
                                            </div>
                                            <div id="collapseOne" className="collapse show"></div>
                                            <div className="col-12 my-2">
                                                <div className="card-body">
                                                    <ul className="px-0">
                                                        {lesson?.map((postleson, index) => {
                                                            return (
                                                                <CoursesList
                                                                    key={index}
                                                                    title={postleson.title}
                                                                />
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mt-5 order-des">
                            <div className="row course-buy d-flex justify-content-center">
                                <div className="col-12 mb-4">

                                    <VideoPlayer
                                        src={activedetails?.introVideoLink == null ? lesson0.videoURL : activedetails?.introVideoLink}/>
                                </div>
                                {activedetails?.id === 30 || activedetails?.id === 31 || activedetails?.id === 33 ? "" :
                                    <div className="col-12 mb-4">
                                        {details.ref02 === 1 ? (
                                            <CourseSub
                                                price={details.priceIQD}
                                                title={details.descSummary}
                                                path={{
                                                    pathname: `/courselessons/${details.id}`,
                                                    state: {
                                                        pathCourse: `/course/${details.id}`,
                                                        id: details.id,
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <CourseBuy
                                                user={props.user}
                                                price={details.priceIQD}
                                                title={details.descSummary}
                                                path={{
                                                    pathname: `/pay`,
                                                    state: {
                                                        id: details.id
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-mobile-desktop">
                <div className="nav-chat">
                    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"
                       className="btn-whatsapp-19">
                        <img src={img_whatsapp} alt="icon"/>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default CourseDetails;
