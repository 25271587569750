import React, { useEffect, useState } from "react";
import "./OverviewPointsModel.scss";
import i18next from "i18next";
import axios from "axios";
import RewardsPoint from "../RewardsPoint/RewardsPoint";

const OverviewPointsModel = (props) => {
  const UserId = localStorage.id;
  const [item, setItem] = useState([]);
  const [profileSummary, setProfileSummary] = useState({});
  useEffect(() => {
    axios.get(`/api/UserReward/AllRewards/${UserId}`).then((res) => {
      const item = res.data;
      setItem(item);
    });
  }, []);

  return (
    <div
      className="modal overview-point-modal fade"
      id="overview-pointModal"
      aria-labelledby="overview-pointModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-body ">
        <div className="header-model">
        <h2 className="header-model-title">
            {i18next.t("overview-point-modal-title")}
          </h2>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-content">
          <div className="modal-body">
            <div className="body-model row">
              {item.map((point, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                    <RewardsPoint
                      title={point.activityName}
                      num={point.noOfPoints}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPointsModel;
