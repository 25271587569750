import React, { useEffect, useCallback } from "react";
import {
    Switch,
    Route,
    NavLink,
    useLocation,
    useHistory
} from "react-router-dom";
import Login from "../../components/Login/Login";
import Register from "../../components/Register/Register";
import ForgetPasswordEmail from "../ForgetPassword/ForgetPasswordEmail/ForgetPasswordEmail";
import i18next from "i18next";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import { useRecoilState, useSetRecoilState } from "recoil";
import { visitedstate, userState } from "../../atom";

const LoginPage = () => {
    const [visited, setVisited] = useRecoilState(visitedstate);
    const setUser = useSetRecoilState(userState);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (visited) {
            window.scrollTo(0, 0);
            setVisited(false);
        }
    }, [visited, setVisited]);

    const handleLoginSuccess = useCallback((userData) => {
        setUser(userData);
        const { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
    }, [setUser, location.state, history]);

    const btnGroup = (
        <div className="flex rounded-md mb-10">
            <NavLink
                to="/login"
                className={({ isActive }) =>
                    `w-1/2 py-2 px-4 text-sm font-medium text-center ${
                        isActive
                            ? 'text-blue-700 bg-white border border-blue-600'
                            : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-blue-700'
                    } rounded-r-lg focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`
                }
            >
                {i18next.t("login-btn-login")}
            </NavLink>
            <NavLink
                to="/register"
                className={({ isActive }) =>
                    `w-1/2 py-2 px-4 text-sm font-medium text-center ${
                        isActive
                            ? 'text-blue-700 bg-white border border-blue-600'
                            : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-blue-700'
                    } rounded-l-lg focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`
                }
            >
                {i18next.t("login-btn-regester")}
            </NavLink>
        </div>
    );

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="container mx-auto px-4 py-8">
                <Switch>
                    <Route path="/login">
                        <Login
                            className="max-w-md mx-auto"
                            btnGroup={btnGroup}
                            onLoginSuccess={handleLoginSuccess}
                        />
                    </Route>
                    <Route path="/register">
                        <Register
                            className="max-w-md mx-auto"
                            btnGroup={btnGroup}
                        />
                    </Route>
                    <Route path="/sendEmail" component={ForgetPasswordEmail} />
                </Switch>
            </div>
            <div className="fixed bottom-4 right-4">
                <a
                    href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"
                    className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
                >
                    <img src={img_whatsapp} alt="WhatsApp" className="w-6 h-6" />
                </a>
            </div>
        </div>
    );
};

export default LoginPage;