import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';

const Root = () => {
    return (
        <GoogleOAuthProvider clientId="827550428131-jub3sjoak4di1utq3676uu8sfknm06ce.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
    );
};

export default Root;