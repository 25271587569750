import React from "react";
import "./Award.scss";
import i18next from "i18next";

import img_12 from "../../assets/images/OurTeamImages/MustfaAhmed.png";
import Un from "../../assets/images/UN.png";
import AUIS from "../../assets/images/AUIS.png";
import Iraqpre from "../../assets/images/Iraqpre.svg";
import Iraqtoth from "../../assets/images/Iraqyoth.svg";
import seed from "../../assets/images/Seed.svg";

const Awards = () => {
  return (
    <div className="container">
      <h1 className="text-center mt-5 color-title fw-bold">
        {i18next.t("competition-1-awards")}
      </h1>
      <div className="container mt-5 ">
        <div className="row justify-content-center align-items-center mb-sm-5 mb-lg-0 ">
          <div className="image-un col-lg-4 col-md-6 col-sm-12">
            <img src={Un} alt="" />
            <div className="carsoul-un">
              <h1 className="text-center mt-4 ">{i18next.t("UN")}</h1>
            </div>
          </div>
          <div className="image-AUIS mt-5   mt-md-0 col-lg-4 col-md-6 col-sm-12 ">
            <img src={AUIS} alt="" />
            <div className="carsoul-AUIS">
              <h1 className="text-center  ">{i18next.t("AUIS")}</h1>
            </div>
          </div>
          <div className="image-pre mt-5 mt-md-0  col-lg-4 col-md-6 col-sm-12">
            <img src={Iraqpre} alt="" />
            <div className="carsoul-pre">
              <h1 className="text-center ">{i18next.t("Iraq-Pre")}</h1>
            </div>
          </div>
          <div className="image-un mt-lg-2 mt-xl-0 col-lg-4 col-md-6 col-sm-12">
            <img src={Iraqtoth} alt="" />
            <div className="carsoul-yoth">
              <h1 className="text-center ">{i18next.t("Iraq-min")}</h1>
            </div>
          </div>
          <div className="image-seed mt-md-0 mt-5 mb-5 mb-md-0 col-lg-4 col-md-6 col-sm-12">
            <img className="" src={seed} alt="" />
            <div className="carsoul-seed">
              <h1 className="text-center  ">{i18next.t("seed")}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Awards;
