import React, { useEffect, useState } from "react";
import "./style.scss";
import Button from "../Button/Button";
import cartImg from "../../assets/images/cart.png";
import { Link } from "react-router-dom";
import { basketState } from "../../atom";
import { useRecoilState } from "recoil";
import i18next from "i18next";

const BasketBtn = () => {
  const [basket] = useRecoilState(basketState);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (basket.length > 0) {
      let _count = 0;
      basket.map((el) => {
        _count += el.endprice * el.qty;
      });
      setCount(_count);
    }
  }, [basket]);



  return basket.length ? (
   
    <div className="basket-btn" >
      <Link to="/cart">
        <Button
          name={
            <div className="flex">
              <div className="img">
                <img src={cartImg} />
              </div>
              <div className="space" />
              <p>{`${i18next.t("basket-continue")} - ${Number(
                count
              ).toLocaleString("en")} ${i18next.t("card-currency")}`}</p>
            </div>
          }
        />
      </Link>
   
    </div>
  ) : "";
};

export default BasketBtn;
