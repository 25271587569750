import React from "react";
import "./ContactUs.scss";
import img_1 from "./images/email.png";
import img_2 from "./images/phone-call.png";
import img_3 from "./images/pin.png";
import i18next from "i18next";

const ContactUs = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center color-title fw-bold ">
        {i18next.t("contact-title")}
      </h1>
      <div className="row justify-content-center my-5 ">
        {/* first div */}
        <div className="col-lg-6 col-md-12">
          {/*<div className="email d-flex gap-3 ">*/}
          {/*  <img src={img_1} alt="" />*/}
          {/*  <div>*/}
          {/*    <h1>{i18next.t("emailus-title")}</h1>*/}
          {/*    <h2>info@iotkids.com</h2>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="email d-flex gap-3">*/}
            {/*<img src={img_2} alt="" />*/}
            {/*<div>*/}
            {/*  <h1>{i18next.t("callus-title")}</h1>*/}
            {/*  <h2>{i18next.t("mobile-num")}</h2>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div className="email d-flex gap-3">
            <img src={img_3} alt="" />
            <div>
              <h1>{i18next.t("address-title")}</h1>
              <h2 className="">{i18next.t("location-title")}</h2>
            </div>
          </div>
        </div >
        {/* end of first div */}
        <div className="col-lg-6 col-md-12 mb-5">
        <div className= "mapbox ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.408064513814!2d44.45126498532145!3d33.30814576402179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557814b5a38141f%3A0xdb75779ee5d7142f!2sIoT%20Kids!5e0!3m2!1sar!2siq!4v1665334815320!5m2!1sar!2siq"></iframe>
        </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
