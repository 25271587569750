import Button from "../../../components/Button/Button";
import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./MyCourses.scss";
import axios from "axios";
import img_sorry from '../../../assets/images/charecter/boycry.png';
import CardProgress from "../../../components/CardProgress/CardProgress";
import i18next from "i18next";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import OverviewIqCourseModel from "../../../components/OverviewIqCourseModel/OverviewIqCourseModel";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import { SlpCourses ,ProfilSummary } from "../../../atom";
import { useRecoilState } from "recoil";
const idUser = localStorage.id;

const MyCourses=()=> {

  // state to save data form api
  const [item,setitem]=useRecoilState(SlpCourses)
  const [profileSummary,setprofileSummary]=useRecoilState(ProfilSummary)
 
  // get data form api
  useEffect(()=>{
    axios
      .get(`/api/SLPCourse/${idUser}`)
      .then((res) => {
        const item = res.data;
        setitem(item)

        axios.get(`/api/SLPCourse/Profile/${idUser}`).then((res) => {
          const profileSummary = res.data;
          setprofileSummary(profileSummary)
        });
      });
  },[idUser]
  )


  
    

    return (
      <div className="bg">
      <div className="container">
        <MenuProfile />
        <div className="my-courses">
          {profileSummary.noOfCourses === 0?
          <div className="row">
          <div className="my-course-no-data">
            <img src={img_sorry} alt="no data" />
            <h4>{i18next.t('my-course-no-data')}</h4>
          </div>

          <div className="col-12">
            <div className="my-courses-btn">
              <Link to="/Messarat/1">
                <Button name={i18next.t("my-course-no-data-btn")} />
              </Link>
            </div>
          </div>
        </div>:profileSummary.noOfCourses > 0?
        <div>
          <div className="row">

{item.map((mycourses, index) => {
  return (
    mycourses.course.isMessar==true?"":
    <>
      {mycourses.course.id == 32 ? (
        <div className="col-12 col-md-6 col-lg-4" key={index}>
          <a
            data-bs-toggle="modal"
            data-bs-target="#overview-iq-courseModal"
            href=""
          >
            <CardProgress
              name={mycourses.course.title}
              percentage={200}
              image={mycourses.course.ref03}
              id={mycourses.course.id}
            />
          </a>
        </div>
      ) : (
        <>
          {
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <CardProgress
                name={mycourses.course.title}
                percentage={mycourses.completedPercent}
                image={mycourses.course.ref03}
                id={mycourses.course.id}
                path={{
                  // pathname: `/course/${postDetails.id}`,
                  pathname: `courselessons/${mycourses.course.id}`,
                  state: {
                    courseId: mycourses.course.id,
                    corseLang: mycourses.course.lang,
                  }
                }}
              />
            </div>
          }
        </>
      )}
    </>
  );
})}

{/* <div className="col-12">
  <div className="my-courses-btn">
    <Link to="/courses">
    <Button name={i18next.t("my-courses-btn")} />
    </Link>
  </div>
</div> */}
</div>
        </div>
        :""}
        </div>
        <OverviewIqCourseModel />
      </div>
      <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
      </div>
    );
  }



export default MyCourses;