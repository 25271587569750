import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./About.scss";
import img_3 from "/src/assets/images/about/19.jpg";
import img_4 from "/src/assets/images/about/kids6.jpg";
import img_5 from "/src/assets/images/about/5.jpg";
import img_6 from "/src/assets/images/about/kids5.jpg";
import img_7 from "/src/assets/images/about/kids8.jpg";
import img_8 from "/src/assets/images/about/9.jpg";
import img_9 from "/src/assets/images/about/6.jpg";
import OurPartner from "../OurPartner/Ourpartner"
import ContactUs from "../ContactUs/ContactUs"
import OurTeam from "../Ourteam/OurTeam";
import Awards from "../Awards/Awards";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

import i18next from "i18next";
import { useEffect } from "react";


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
    );
    if (window.location.href.lastIndexOf('#') > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
   });
  return (
    <div className="bg">
    <div className="container ">
      <div>
        <div className="mt-5  text-center">
          <h1 className="title-1 fw-bold fs-1">{i18next.t("about-title")}</h1>
        </div>
        <div className="about">
          <div className="container">
            <div className="row justify-content-center my-lg-5 my-sm-0">
              <div className="col-12 col-xl-6  order-xl-1">
                <div className="image-1 mt-4">
                  <img src={img_4} alt="image" />
                </div>
              </div>
              <div className="col-12 col-xl-6 order-xl-2 container  ">
                <h2 className="title-1 pad container">{i18next.t("about-s1-title")}</h2>
                <p className="paragraph-1 container">{i18next.t("about-s1-des")}</p>
              </div>
              {/* 2 */}
            </div>

            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide "
              data-bs-ride="carousel"
              data-bs-interval="3000"
            >
              <div className="carousel-inner img-slides ">
                <div className="carousel-item active  ">
                  <img className="d-block w-100  " src={img_7} alt="iamge" />
                </div>
                <div className="carousel-item  ">
                  <img src={img_8} className="d-block w-100 " alt="..." />
                </div>
                <div className="carousel-item  ">
                  <img src={img_3} className="d-block w-100 " alt="..." />
                </div>
                <div className="carousel-item  ">
                  <img src={img_5} className="d-block w-100 " alt="..." />
                </div>
                <div className="carousel-item  ">
                  <img src={img_6} className="d-block w-100 " alt="..." />
                </div>
                <div className="carousel-item  ">
                  <img src={img_9} className="d-block w-100 " alt="..." />
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="d-md-flex row-xl-6 gaps  order-xl-1 justify-content-between">
              <div className="mr-5  container">
                <h2 className="title-1 container ">{i18next.t("about-s2-title-1")}</h2>
                <p className="paragraph-1 container ">{i18next.t("about-s2-des-1")}</p>
              </div>
              <div className="gap-3 container">
                <h2 className="title-1 container ">{i18next.t("about-s2-title-2")}</h2>
                <p className="paragraph-1 container">{i18next.t("about-s2-des-2")}</p>
              </div>
            </div>
            {/* 4 */}
          </div>
          <div className="mt-between-sections mt-5" id="ourpartner">
            <hr />
            <OurPartner/>
          </div>

          <div  id="ourteam" className="">
            <hr />
          <OurTeam/>
          </div>
          <div id="awards" className="pb-5">
            <hr />
          <Awards/>
          </div>
           <hr className="mt-5" />
            <div id="contactus">
            <ContactUs/>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile-desktop">
      {/*<div className="nav-chat">*/}
      {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">*/}
      {/*      <img src={img_whatsapp} alt="icon" />*/}
      {/*    </a>*/}
      {/*  </div>*/}
        </div>
      </div>

  );
};

export default About;
