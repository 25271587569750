import React ,{Component} from "react";
import "./CardSub.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";

//import ReactDOM from 'react-dom';
const CardSub =(props)=> {
    return (
      <div className="row">
        <div className="col-12" data-aos="flip-down" data-aos-delay="200">
          <div className="card">
            <Link to={props.path}>
              <img
                src={props.image}
                className="card-img-top"
                alt="Image Corse"
              />
              <div className="card-body">
                <p className="card-text">{props.name}</p>
                <p className="card-price mx-2">
                  <span className="mx-2">{i18next.t("card-is-sub")}</span>
                </p>
                <div className="m-2"></div>
                <div className="content py-3 px-2">
                  <p className="card-text my-3">{props.name}</p>
                  <p className="card-des my-3">{props.description}</p>

                  <Link
                    to={{
                      pathname: `/courselessons/${props.pathId}`,
                      state: {
                        id: props.pathId,
                      },
                    }}
                    className="card-price-btn"
                  >
                    <p className="card-price my-3 mx-2">
                      {i18next.t("btn-go-course")}
                    </p>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
export default CardSub;