import React, { useState } from "react";
import './ZoodPay.scss'
import Button from "../Button/Button"
import IotKidsLogo from "../../assets/images/favicon.ico"
import ZodpayLogo from "../../assets/images/zoodpay.png"
import i18next from "i18next";
import CryptoJS from "crypto-js";
import { shopTotalPriceState, basketState } from "../../atom";
import { useRecoilState } from "recoil";
import axios from "axios";
let UserID = localStorage.getItem("id");



const ZoodPayModal = () => {

  const [FirstName, setFirstName] = useState("Muntather")
  const [LastName, setLasttName] = useState("Jabbar")
  const [Email, setEmail] = useState("montadr9988@gmail.com")
  const [PhoneNumber, setPhoneNumber] = useState("9645653562616")
  const [TotalPrice] = useRecoilState(shopTotalPriceState)
  const [basketnumber] = useRecoilState(basketState)
  const [URLdata, setURLData] = useState(null)
  const totalQty = basketnumber.reduce((acc, cur) => acc + cur.qty, 0);
  const String = `${TotalPrice}|${totalQty}|IQD|wow`
  let Payid = `${UserID}${Math.floor(Math.random() * 1000) * TotalPrice + totalQty}`



  const S = `.uPL)@nSW2xu4zk|${Payid}|${TotalPrice}|IQD|IQ|H7[asxDa`
  const H = CryptoJS.enc.Hex.stringify(CryptoJS.SHA512(S))


  const handleButtonClick = () => {
    const username = '.uPL)@nSW2xu4zk';
    const password = 'jW4wB)pX*HxrdE}*';

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Basic ' + btoa(username + ':' + password),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      url: 'https://sandbox-api.zoodpay.com/v0/transactions',
      data: JSON.stringify({
        billing: {
          address_line1: "string",
          address_line2: "string",
          city: "string",
          country_code: "964",
          name: "string",
          phone_number: "string",
          state: "string",
          zipcode: "string"
        },
        callbacks: {
          error_url: "https://iotkidsiq.com/404",
          success_url: "https://iotkidsiq.com/paysuccess"
        },
        customer: {
          customer_email: Email,
          customer_phone: PhoneNumber,
          first_name: FirstName,
          last_name: LastName
        },
        items: [
          {
            categories: [
              [
                "Electronic"
              ]
            ],
            currency_code: "IQD",
            discount_amount: "0",
            name: "codey",
            price: TotalPrice,
            quantity: totalQty,
            sku: "string",
            tax_amount: "0"

          }
        ],
        order: {
          amount: TotalPrice,
          currency: "IQD",
          discount_amount: "0",
          lang: "ar",
          market_code: "IQ",
          merchant_reference_no: Payid,
          service_code: "ZPI",
          shipping_amount: "0",
          signature: H,
          tax_amount: "0"

        },
        shipping: {
          address_line1: "string",
          address_line2: "string",
          city: "string",
          country_code: "964",
          name: "string",
          phone_number: "string",
          state: "string",
          zipcode: "string"
        },
        shipping_service: {
          name: "string",
          priority: "string",
          shipped_at: "string",
          tracking: "string"
        }
      })
    };

    axios(requestOptions)
      .then((res) => {
        const dataTrans = res.data
        window.open(dataTrans.payment_url);
      })
      .catch(err => console.log("error", err))

    
  };
  return (
    <div>
      <div class="modal fade" id="ZoodpayModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header row justify-content-center">
              <h1 class="modal-title col-12 fs-5" id="exampleModalLabel"><img className="img-zood" src={ZodpayLogo} alt="" /></h1>
              <h3 className="col-12">{i18next.t("pay-head-zoodpay")}</h3>
            </div>
            <div class="modal-body">
              <div className="container">
                <div className="row justify-content-center ">
                  <div className="col-6">
                    <label className="d-flex justify-content-start">
                      {i18next.t("first-name")}
                    </label>
                    <input
                      className="form-control"

                      placeholder={i18next.t("payment-form-name-hint2")}

                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <label className="d-flex justify-content-start">
                      {i18next.t("last-name")}
                    </label>
                    <input
                      className="form-control"

                      placeholder={i18next.t("payment-form-name-hint3")}

                      onChange={(e) => setLasttName(e.target.value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <label className="d-flex justify-content-start">
                      {i18next.t("my-account-text-3")}
                    </label>
                    <input
                      className="form-control"

                      placeholder={i18next.t("password-input")}

                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <label className="d-flex justify-content-start">
                      {i18next.t("my-account-text-2")}
                    </label>
                    <input
                      type="number"
                      className="form-control"

                      placeholder={i18next.t("payment-form-phone-hint")}

                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <h3 className="mt-3">{i18next.t("total-price")}  : {Number(TotalPrice).toLocaleString("en")}{i18next.t("card-currency")}</h3>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a onClick={handleButtonClick}>
                <Button name={i18next.t("pay-confirm")} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default ZoodPayModal