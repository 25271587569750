import Button from "../../../components/Button/Button";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Rewards.scss";
import axios from "axios";
import RewardsPoint from "../../../components/RewardsPoint/RewardsPoint";
import Certificate  from "../../../components/Certificate/Certificate";
import i18next from "i18next";
import MenuProfile from "../../../components/MenuProfile/MenuProfile";
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";
import Ques from "../../../assets/images/Ques.png"

class Rewards extends Component {
  // state to save data form api
  state = {
    item: [],
    sumNum: [],
  };
  // get data form api
  componentDidMount() {
    const UserId = localStorage.id;
    axios.get(`/api/UserReward/AllRewards/${UserId}`).then((res) => {
      const item = res.data;
      this.setState({ item });
      const SumNum = res.data;
      this.setState({ SumNum });
    });
  }

  render() {
    return (
      <div className="bg bg-login">
      <div className="container">
       <MenuProfile />
        <div className="rewards">
          <div className="row">
            {this.state.item.map((rewaed, index) => {
              return (
                <div className="col-6 col-md-4 col-lg-3" key={index}>
                  <RewardsPoint
                    title={rewaed.activityName}
                    num={rewaed.noOfPoints}
                  />
                </div>
              );
            })}

            <div className="rewards-btn col-12">
              <div className="sum-rewaed">
                <p>{}</p>
              </div>
              {/* <Button name={i18next.t("rewards-btn")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile-points footer-mobile2">
  <div className="nav-chat ">
    <div className="tooltip2">
      <abbr className="tooltip2-abbr" data-title={i18next.t("bg-home-btn")}>
        <a  data-bs-toggle="modal"
            data-bs-target="#videoModal"
            href="#"  onClick={() => setSelectedVideo(813950365) + setCloseVideo(false)} target="_blank" className="btn-whatsapp-19">
          <img className="" src={Ques} alt="icon" />
        </a>
      </abbr>
    </div>
  </div>
</div>

<div className="footer-mobile-desktop">
  <div className="nav-chat ">
      <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول" className="btn-whatsapp-19">
            <img src={img_whatsapp} alt="icon" />
          </a>
  </div>
</div>
      </div>
    );
  }
}

export default Rewards;
