import React, { Component } from "react";
import "./CardCompetitions.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18next from "i18next";
import LoginModel from "../LoginModel/LoginModel";
import icon_1 from "../../assets/images/competitions/icon-1.png"
import icon_2 from "../../assets/images/competitions/icon-2.png"
import icon_3 from "../../assets/images/competitions/icon-3.png"

//import ReactDOM from 'react-dom';
const Card = (props) => {
  return (
    <div className="row">
      <div className="col-12" data-aos="flip-down" data-aos-delay="200">
        <div className="card-competition">
          <Link to={props.path}>
            <img src={props.image} className="card-img-top" alt="Image Corse" />
            <div className="card-body">
              <p className="card-text">{props.name}</p>
              <p className="card-price mx-2">
                {props.price}
              </p>
              <div className="m-2"></div>
            </div>
            <div className="content py-3 px-2">
              <p className="card-text my-3">{props.name}</p>
              <p className="card-des my-3">{props.description}</p>
              {props.numIcon == 1 || props.numIcon == 2 || props.numIcon == 3 ?
                <img src={props.numIcon == 1 ? icon_1 : props.numIcon == 2 ? icon_2 : props.numIcon == 3 ? icon_3 : ""} alt="icon" />
                : ""}
             
            </div>
          </Link>
        </div>
      </div>
      <LoginModel />
    </div>
  );
};
export default Card;
