import React, { useEffect, useState } from "react";
import './Wallet.scss'
import Lottie from "lottie-web";
import anim from "../../assets/images/Money.json"
import anima2 from "../../assets/images/Money2.json"
import anima3 from "../../assets/images/Money3.json"
import Apple from "../../assets/images/Apple.png"
import PlayStore from "../../assets/images/PlayStore.png"
import axios from "axios";


const Wallet =()=>{
  const userid=localStorage.getItem("id")
  const [balance,setbalance]=useState(0)
  useEffect(()=>{
    axios.get(`/api/UserReward/${userid}`).then((res)=>{
      setbalance(res.data*100)
    })
  },[])
  useEffect(()=>{
   const anim1= Lottie.loadAnimation({
      container: document.getElementById("anim1"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim,
    });
    const anim2= Lottie.loadAnimation({
      container: document.getElementById("anim2"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: anima2 ,
    });
    const anim3= Lottie.loadAnimation({
      container: document.getElementById("anim3"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anima3 ,
    });
    return(()=>{
      anim1.destroy();
      anim2.destroy();
      anim3.destroy();
    })
  })
    return(

      <div className="bg" onLoad={window.scrollTo(0,0)}>
        <div className="container wallet-all">
            <div className="wallet-card row justify-content-center align-items-center">
               <div className="wallet-text col-12 col-lg-4">
               <h1>
                  الرصيد النقدي
                </h1>
               </div>
               <div className="wallet-text text-center col-12 col-lg-4">
               <h1>
                   {balance}د,ع
                </h1>
               </div>
               <div className="wallet-anim col-12 col-lg-4">
                  <div className="anim-money" id="anim1"></div>
               </div>
            </div>

            <div className=" wallet-desc row justify-content-center ">
                <div className=" wallet-desc-text col-12 col-lg-6 mb-5 pb-5 mb-lg-0 pb-lg-0 ">
                     <h3>يمكنك استخدام الرصيد النقدي في المحفضة لشراء  المسارات او المنتجات من متجرنا او الحصول على خصم من السعر الكلي للحزمة االمنتج</h3>
                </div>
                <div className=" wallet-desc-anim d-flex justify-content-end col-12 col-lg-6">
                   <div className="anim-money-2" id="anim2"></div>
                </div>
            </div>
            <div className=" wallet-desc mt-5 pt-5 row justify-content-center ">
                <div className=" wallet-desc-anim d-flex justify-content-end col-12 col-lg-5">
                   <div className="anim-money-2" id="anim3"></div>
                </div>
                <div className=" wallet-desc-text col-12 col-lg-6 ">
                     <h3>يمكنك الحصول على الرصيد عن طريق اكمال المسارات المتوفرة في المنصة عند اكمال احد المسارات سوف تحصل على رصيد نقدي بقيمة 25,0000 د,ع</h3>
                </div>
            </div>
             {/*  <div className="wallet-download">
                <h2 className="text-center my-5">قم بتحميل التطبيق الان واحصل على 25,000 رصيد مجاني في محفضتك</h2>
                <div className="wallet-button">
                  <a href=""><button className="d-flex align-items-center gap-4"><h1 className="mt-2">App Store</h1><img src={Apple} alt="" /></button></a>
                  <a href=""><button className="d-flex align-items-center gap-4"><h1 className="mt-2">Google Play</h1><img src={PlayStore} alt="" /></button></a>

                </div>
                <div>

                </div>
              </div> */}
        </div>
      </div>
    )
}
export default Wallet;