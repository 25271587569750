// src/components/AuthenticationWrapper/AuthenticationWrapper.jsx
import React, { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../../atom';

const CURRENT_VERSION = '1.0'; // Update this when you want to force a reset

const AuthenticationWrapper = ({ children }) => {
    const setUser = useSetRecoilState(userState);
    const currentUser = useRecoilValue(userState);

    useEffect(() => {
        const checkAuthStatus = () => {
            const storedVersion = localStorage.getItem('app_version');

            // Clear local storage if the version doesn't match
            if (storedVersion !== CURRENT_VERSION) {
                localStorage.clear();
                localStorage.setItem('app_version', CURRENT_VERSION);
                console.log('Local storage cleared due to version mismatch');
            }

            const token = localStorage.getItem('token');
            const storedUser = localStorage.getItem('iot_user');

            if (token && storedUser) {
                try {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    console.log('User authenticated:', parsedUser);
                } catch (error) {
                    console.error('Error parsing stored user data:', error);
                    localStorage.removeItem('iot_user');
                    localStorage.removeItem('token');
                    setUser(null);
                    console.log('Authentication failed, user data cleared');
                }
            } else {
                setUser(null);
                console.log('No authentication data found');
            }
        };

        checkAuthStatus();
        window.addEventListener('storage', checkAuthStatus);

        return () => {
            window.removeEventListener('storage', checkAuthStatus);
        };
    }, [setUser]);

    // Log current user state (for debugging)
    useEffect(() => {
        console.log('Current user state:', currentUser);
    }, [currentUser]);

    return <>{children}</>;
};

export default AuthenticationWrapper;