import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import "./Pay.scss";
import img_zaincash from "../../assets/images/zaincash.png";
import img_delivery from "../../assets/images/pay/delivery-man.png";
import img_card from "../../assets/images/visa-master.png";
import img_security from "../../assets/images/security.png";
import img_active from "../../assets/images/courses/check-mark.png";
import axios from "axios";
import CardProdect from "../../components/CardProdect/CardProdect";
import i18next from "i18next";
import Dialog from "../../components/Dialoge/Dialoge";
import Switch from "./../../components/Switch/Switch";
import img_courses from "../../assets/images/h-video.png";
import CardHorizantal from "../../components/CardHorizantal/CardHorizantal";
import CardHorizantalOffers from "../../components/CardHorizantalOffers/CardHorizentalOffers";


const ZC_POOLING_INTERVAL = 15000;
const ZC_POOLING_MAX_TRY = 20;

const ZCModalInfo = {
  title: "اعادة توجيه",
  payType: "زين كاش",
  msg: "جاري تحويلك الى الموقع الرسمي لاكمال عملية الدفع",
  btnTitle: "اغلاق",
  isSuccess: false,
};

const PayBulk = () => {
  const history =useHistory();
  const [activePay, setActivePay] = useState([]);
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [priceAfterCoupon, setPriceAfterCoupon] = useState(0);
  const [price, setPrice] = useState("");
  const [delprice, setDelprice] = useState("");
  const [itdiscount, setItDiscount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [Realprice, setRealPrice] = useState(150000);
  const [couponID, setCouponID] = useState(-1);
  const [zainCashURL, setZainCashURL] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [RefCode, setRefCode] = useState(false);
  const [item, setItem] = useState([]);
  const [coursesId, setCoursesId] = useState([]);
  const [idItem, setIdItem] = useState([]);
  const [allItem, setAllItem] = useState([0]);
  const [coursesItem, setCoursesItem] = useState([]);
  const [usercoursesid, setUserCoursesId] = useState([]);
  const [allcourses, setAllCourses] = useState([]);
  const [messardata,setmessardata]=useState([])
  const [idoffer, setIdOffer] = useState(0);
  const [allcoursesid, setAllCoursesId] = useState([]);
  const [couponCodeID, setCouponCodeID] = useState(null);

  const AiPackageDetails = [
    {
      id: 1,
      name: "تصميم الالعاب",
    },
    {
      id: 2,
      name: "تصميم الالعاب -المرحلة الثانية",
    },
    {
      id: 23,
      name: "تصميم الالعاب -المرحلة الثالثة",
    },
    {
      id: 11,
      name: "الذكاء الاصطناعي",
    },
    {
      id: 12,
      name: "الذكاء الاصطناعي-متقدم",
    },
    {
      id: 6,
      name: "التعلم الالي",
    },
    {
      id: 7,
      name: "التعلم الالي-متقدم",
    },
  ];

  const lang1 = localStorage.getItem("i18nextLng");
  const { pathname } = location;

  useEffect(() => {
    const idoffer = pathname.split("/")[2];
    setIdOffer(idoffer);
    const userId = localStorage.id;

    axios
      .get(`https://ahmeddawood88-003-site1.etempurl.com/api/Course/GetMessaratCourseDetails?UserId=${userId}&Lang=ar`)
      .then(async (res) => {
        const items = res.data;
        let filtereditems = items.find((item) => item.id==idoffer );
        setCoursesItem(filtereditems.messaratCourse);
        setmessardata(filtereditems)

      /*   axios.get(`/api/SLPCourse/${userId}`).then((res) => {
          const item = res.data.length !== 0 ? res.data : [0];
          setItem(item);
          const usercoursesid = item.map((obj) => obj.course && obj.course.id);
          setUserCoursesId(usercoursesid);

          const allcourses = coursesItem
            .filter((course) => !usercoursesid.includes(course.id))
            .map((course) => course);
          setAllCourses(allcourses);

          const allcoursesid = coursesItem
            .filter((course) => !usercoursesid.includes(course.id))
            .map((course) => course.id);
          setAllCoursesId(allcoursesid);
        }); */
      });

    const pathid = 15;

    axios.get(`/api/Course/${pathid}`).then((res) => {
      setActivePay(res.data);
    });

    
    const lang = localStorage.i18nextLng.toUpperCase();
  }, []);

  const handleCuopon = (e) => {
    e.preventDefault();
    var userId = localStorage.getItem("id");
    
    if (code == "105cashiot" || code.startsWith("ac-")) {
      const UserId = userId;
      const pathid = 1;
      const data = {
        userId: UserId,
        orderId: UserId + pathid,
        messaratId: messardata.id,
        couponId: code.startsWith("ac-") ? 0 : code.id,
        activationCode: code.startsWith("ac-") ? code : null,
        paymentMethod: "Cash",
        refCode: code.startsWith("ac-") ? null : code,
      };

      setCouponError(false);
      
      axios.post(`/api/UserEnroll/CreateUserMasarat`, data).then((res) => {
        history.push("/mymessarat");
      });
    } else {
      axios.get(`/api/Coupon/GetCouponByCode/${code}`).then((res) => {
        setRealPrice(150000 - res.data.discountAmt);
        setCouponCodeID(res.data.id);
      });
    }
  };

  const initZCSession = (e) => {
    e.preventDefault();

    setLoading(true);
    setShowModal(true);
    setModalInfo(ZCModalInfo);

    const activeprodect = activePay;
    let userID = localStorage.getItem("id");
    let orderID = userID + idoffer;

    const datazaincash = {
      orderId: orderID,
      userId: userID,
      messaratId: messardata.id,
      couponId: couponCodeID,
      refCode: null,
      activationCode: code.startsWith("ac-") ? code : null,
      currency: "IQD",
      bulkAmount: Realprice,
    };

    axios.post(`https://ahmeddawood88-003-site1.etempurl.com/api/ZainCash/GenerateZainCashURL`, datazaincash).then((res) => {
      let redirectedURL = res.data;

      setZainCashURL(redirectedURL);
      let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
      if (safariAgent) {
        window.open(redirectedURL, "_blank").focus();
      } else {
        window
          .open(
            redirectedURL,
            "zc_popup_window",
            `left=100,top=100,width=570,height=${window.screen.height - "10%"}`
          )
          .focus();
      }

      setModalInfo({
        ...modalInfo,
        title: "",
        msg: "",
      });
    }).catch((err) => {
      console.log(err);
      setModalInfo({
        ...modalInfo,
        title: "فشل",
        msg: "هناك خلل ما, يرجى اعادة المحاولة",
        isSuccess: false,
      });
    });
  };

  const closeDialog = (e) => {
    setLoading(false);
    setShowModal(false);
  };

  let isPrice;
  if (itdiscount) {
    isPrice = (
      <div className="price my-2">
        <del>
          <span className="des">{200000}</span>
        </del>
        <ins>
          <span className="amount-pay">
            {Realprice} <span>IQD</span>
          </span>
        </ins>
      </div>
    );
  } else {
    isPrice = (
      <div className="price">
        {/*<del className=''>*/}
        {/*  <span className="des px-1">{200000}{i18next.t("card-currency")} </span>*/}
        {/*</del>*/}
        <ins>

          <span className="amount-pay"> {Realprice} {i18next.t("card-currency")}</span>
        </ins>
      </div>
    );
  }

  return (
    <div className="pay m-0 p-0">
      <div className="nav-item">
        <Link to={`/`} onLoad={window.scrollTo(0, 0)}>
          <button className="navbar-btn">X</button>
        </Link>
      </div>
      <div className="iqtest-item-title">
        <h2 className="my-4 ">{messardata.title}</h2>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-9">
            <div className="paynow">
              <div className="pay-with">
                <div className="paynow-top">
                  <img src={img_security} alt="card image" />
                  <h4>{i18next.t("pay-bulk-title-1")} {coursesItem?.length == 0 ? (<div className="spinner-border text-loadding" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>) : idoffer == 4 ? AiPackageDetails.length : coursesItem?.filter((item)=>item.messaratDetailsDto.isMessar!=true).length} {i18next.t("pay-bulk-title-2")}</h4>
                  <form className="cuopon">
                    <input
                      type="text"
                      className="cuopon-input mx-5"
                      id="cuopon"
                      aria-describedby="cuopon"
                      onChange={(e) => setCode(e.target.value)}
                      placeHolder={i18next.t("pay-input")}
                    />
                    <button
                      className={`cuopon-btn ${itdiscount ? "disabled" : ""}`}
                      onClick={handleCuopon}
                    >
                      {itdiscount ? (
                        <img
                          className="active-coupon"
                          src={img_active}
                          alt="icon"
                        />
                      ) : (
                        `${i18next.t("pay-btn-apply")}`
                      )}
                    </button>
                  </form>
                  <h6 className="coupon-msg">
                    {couponError
                      ? `${i18next.t("pay-coupon-Error")}`
                      : ""}
                  </h6>
                </div>
               {/*  <div className="d-flex gap-5 justify-content-center">
                  <h3>استخدم الرصيد في المحفضة</h3>
                <label class="switch">
  <input type="checkbox"/>
  <span class="slider round"></span>
</label>
</div> */}
                <hr className="my-4" />
                {isPrice}
                
                <h4>{i18next.t("pay-now")}</h4>
                <div className="row paynow-center">
                  <div className="col-12 col-md-6 col-xl-4">
                    <a className="paynow-btn" id="switch-pay-btn">
                      <img src={img_card} alt="card image" />
                      <div className="switch-btn">
                        <Switch
                          courseId={allcoursesid}
                          orderNo={activePay.order}
                          couponId={null}
                          paymentMethod={"switch"}
                          couponID={couponID}
                          priceIQD={Realprice}
                        />
                      </div>
                      {i18next.t("pay-btn-card")}
                    </a>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <a
                      id="zaincash-pay-btn"
                      onClick={initZCSession}
                      className={`paynow-btn ${loading ? "disable-a" : ""}`}
                    >
                      <img src={img_zaincash} alt="card image" />
                      {i18next.t("pay-btn-zain")}
                    </a>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <a
                      href="//api.whatsapp.com/send?phone=+9647838300650&text=اريد الاشتراك في هذه الدورة و هذا اسمي وو عنواني و رقم الهاتف "
                      className="paynow-btn"
                    >
                      <img className="img-delivery" src={img_delivery} alt="card image" />
                      {i18next.t("pay-delivery")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 col-xl-3">
            <div
              className="courses-list-item accordion"
              id="accordionExample"
            >
              <div className="card-img-courses card-img-courses-mob row justify-content-center align-items-center ">
                <div className=" text-center my-3">
                  <h2 className="fs-5">يحتوي هذا المسار على</h2>
                  <h2 className="fs-5"> { coursesItem.filter((x)=>x.messaratDetailsDto.isMessar!=true).length} دورات</h2>
                </div>
                <hr />
                {
                  coursesItem?.filter((item)=>item.messaratDetailsDto.isMessar !=true).map((item, index) => (
                    <div className="col-6 col-lg-12 text-center" key={index}>
                      <CardHorizantalOffers
                        name={item.messaratDetailsDto.title}
                        price={150000}
                        path={`/course/${item.messaratDetailsDto.id}`}
                      />
                    </div>
                  ))
}
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayBulk;
