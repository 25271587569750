import React from "react";
import "./JobCard.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const JobCard = (props) => {
  return (
    <Link to="/jobdetails">
    <div className="pt-5 container d-flex justify-content-center ">
      <div className="  shadow-lg  radius div-width-hover">
        <img className="img-width img-radius" src={props.img} alt="" />
        <div className="pb-3 pt-4">
        <h2 className="  text-center px-2 fs-5 fw-bold color-title">
          {props.jobname}
        </h2>
        <p className="text-center fs-6 fw-bold">{props.jobtype}</p>
        </div>
      </div>
    </div>
    </Link>
  );
};
export default JobCard;
