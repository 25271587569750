import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  NavLink,
} from "react-router-dom";
import "./MenuProjects.scss";
import i18next from "i18next";
import axios from "axios";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MenuProjects = ({ onChange }) => {
  const [categories, setCategories] = useState([]);
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    axios.get(`/api/ProjectCategory`).then((res) => {
      setCategories(res.data);
    });
  }, []);

  let query = useQuery();

  useEffect(() => {
    if (query.get("category") && categories) {
      setActiveLink(query.get("category"));
    } else {
      setActiveLink("IsFeatured");
    }
  }, [query, categories]);

  useEffect(() => {
    if (activeLink) {
      onChange && onChange(activeLink);
    }
  }, [activeLink]);

  var element = document.getElementById(`nav-project`);
  element?.classList?.add("active");

  return (
    <div className="menu-project d-flex justify-content-center">
      <div className="menu-scroll">
        <ul className="nav nav-tabs">
          <li className="nav-item order-item-3">
            <NavLink
              className={
                activeLink == "IsFeatured" ? "nav-link active-link" : "nav-link"
              }
              activeClassName="active"
              to="?category=IsFeatured"
            >
              {i18next.t("projects-featured")}
            </NavLink>
          </li>
          {categories.map((el, index) => (
            <li key={index} className="nav-item">
              <NavLink
                activeClassName="active"
                exact
                to={`?category=${el.id}`}
                className={
                  activeLink == el.id ? "nav-link active-link" : "nav-link "
                }
              >
                {i18next.language === "ar"
                  ? el.categoryNameAR
                  : el.categoryNameEN}
              </NavLink>
            </li>
          ))}
          {/* <li className="nav-item order-item-2">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="?category=two"
            >
              {i18next.t("create-projects-cat-2")}
            </NavLink>
          </li>
          <li className="nav-item order-item-3">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="?category=three"
            >
              {i18next.t("create-projects-cat-3")}
            </NavLink>
          </li>
          <li className="nav-item order-item-4">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="?category=four"
            >
              {i18next.t("create-projects-cat-4")}
            </NavLink>
          </li>
          <li className="nav-item order-item-5">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="?category=five"
            >
              {i18next.t("create-projects-cat-5")}
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};
export default MenuProjects;
