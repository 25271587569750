import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import './UserList.scss'
import i18next from "i18next";
import Login from "/src/assets/images/Login.png";
import PRP from "../../assets/images/PRP.png"
import info from "../../assets/images/info.png"
import phone from "../../assets/images/phone.png"
import projects from "/src/assets/images/header/projects.png";
import shop from "/src/assets/images/header/shop.png";
import competitions from "/src/assets/images/header/competitions.png";
import { useRecoilState } from "recoil";
import { visitedstate } from "../../atom";


const id =localStorage.getItem("id")
const UserList =()=>{
  const [visited, setVisited] = useRecoilState(visitedstate);
  const handleOnLoad = () => visited ? (window.scrollTo(0, 0), setVisited(false)) : setVisited(true);
    return(
       <div className="container " onLoad={handleOnLoad}>
        <div className="row gap-3 pt-5 mb-3 justify-content-center text-center ">
        <Link
              className=" horz-all  col-3 order-item-3"
              activeClassName="active"
              id="nav-project"
             
              to="/competitions"
            >
              <img className="image-user-list-icons mb-3" src={competitions} alt="icon" />
              <h4 className="fs-6 text-center">{i18next.t("header-competitions")}</h4>
            </Link>
            <Link
              className=" horz-all  col-3"
              activeClassName="active"
              id="nav-project"
             
              to="/projects"
            >
              <img className="image-user-list-icons-store mb-3" src={projects} alt="icon" />
              <h4 className="fs-6 text-center">{i18next.t("header-projects")}</h4>
            </Link>
          
            
           <hr className="mt-4" />
           

        </div>
        <div className=" ">
            <div className="row mt-3 justify-content-center">
              
                <div className="col-12 mt-3">
                <Link to="/privacy-policy">
                    <div className="d-flex align-items-center gap-3">                    
                        <img className="image-user-list" src={PRP} alt="" />
                        <h1 className="fs-5 mt-1">{i18next.t("footer-company-privcypolicy")}</h1>                    
                    </div>
                    </Link>

                </div>
                
                <div className="col-12 mt-3">
                <Link to="/about">
                    <div className="d-flex align-items-center gap-3">                    
                        <img className="image-user-list" src={info} alt="" />
                        <h1 className="fs-5 mt-1"> {i18next.t("header-about")}</h1>                    
                    </div>
                    </Link>

                </div>
                
                <div className="col-12 mt-3">
                <Link to="/about#contactus">
                    <div className="d-flex align-items-center gap-3">                    
                        <img className="image-user-list" src={phone} alt="" />
                        <h1 className="fs-5 mt-1">{i18next.t("password-b-btn")}</h1>                    
                    </div>
                    </Link>

                </div>
                
                

            </div>

        </div>
       </div>
    )
}

export default UserList